import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { reduxForm, change } from 'redux-form'

import { CKEditorField } from '../form/elements'

import {saveFrontpage, enableUrlChange, disableUrlChange, clearContentData, getFullHistory} from "../../actions/common"


import UrlListener from '../utils/UrlChangeListener'
import { EditTitle, BoxContainerEditItem } from "../utils/common";
import {renderHistory} from "./layoutHelpers";

const FORM_ID = "editFrontpage"


class EditFrontpage extends Component {

    state = {
        recommendations: null
    }

    componentDidUpdate = prevProps => {

        const { pristine, recommendations } = this.props


        if (! pristine && prevProps.pristine) {
            this.props.dispatch(disableUrlChange())
        } else if (pristine && ! prevProps.pristine) {
            this.props.dispatch(enableUrlChange(false))
        }

        if (Array.isArray(recommendations) && this.state.recommendations === null) {
            this.setState({ recommendations: recommendations })
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    onAttemptUrlChange = () => {
        if (window.confirm("unsaved form, leave anyway?")) {
            this.props.dispatch(enableUrlChange(true))
        }
    }

    onSubmit = values => {

        this.props.dispatch(enableUrlChange((false)))
        this.props.dispatch(saveFrontpage(values)).then(
            () => {
                this.props.dispatch(push("/"))
            },
            e => {}
        )
    }

    onBoxOrderClick = (currIdx, idxOffset) => () => {
        const recommendations = this.state.recommendations
        const newIdx = idxOffset < 0
            ? Math.max(0, currIdx + idxOffset)
            : Math.min(recommendations.length - 1, currIdx + idxOffset)

        const newRecommendations = recommendations.map((item, idx, currArray) => {
            if (idx === currIdx) {
                return {...currArray[newIdx], order: idx}
            } else if (idx === newIdx) {
                return {...currArray[currIdx], order: idx}
            } else {
                return {...item, order: idx}
            }
        })

        this.setState({ recommendations: newRecommendations })
        this.props.dispatch(change(FORM_ID, "recommendations", newRecommendations))

    }

    renderBoxItem = (item, idx, currArray) => {
        return (
            <BoxContainerEditItem
                key={idx}
                isFirst={idx === 0}
                isLast={idx >= currArray.length - 1}
                onUpClick={this.onBoxOrderClick(idx, -1)}
                onDownClick={this.onBoxOrderClick(idx, 1)}
                title={item.name || ""} />
        )
    }

    render = () => {

        const { handleSubmit } = this.props

        const history = _.get(this.props, 'history', [])
        const historyLink = _.get(this.props, 'actions.history.href', null)

        const prequel = _.get(this.props, 'initialValues.prequel', '<p></p>')
        const sequel = _.get(this.props, 'initialValues.sequel', null)
        const midquel = _.get(this.props, 'initialValues.midquel', null)

        const recommendations = _.get(this.state, 'recommendations', []) || []

        return (
            <div className="edit frontpage">
                <UrlListener onAttemptUrlChange={this.onAttemptUrlChange} />

                <EditTitle>Címlap szerkesztő</EditTitle>

                <form onSubmit={handleSubmit(this.onSubmit)}>

                    <div className={"label"}>
                        felső html:
                    </div>
                    <div className={"editor"}>
                        <CKEditorField
                            data={prequel}
                            formId={FORM_ID}
                            name="prequel" />
                    </div>

                    <div className={"label"}>
                        hírek
                    </div>
                    <div className={"fake-news"}>
                        <div><img src={"https://musorfuzet-thumbnail.s3-eu-west-1.amazonaws.com/default_328x184.jpg"} alt={""}/></div>
                        <div><img src={"https://musorfuzet-thumbnail.s3-eu-west-1.amazonaws.com/default_328x184.jpg"} alt={""}/></div>
                        <div><img src={"https://musorfuzet-thumbnail.s3-eu-west-1.amazonaws.com/default_328x184.jpg"} alt={""}/></div>
                    </div>

                    <div className={"label"}>
                        <label>középső html:</label>
                    </div>
                    <div className={"editor"}>
                        <CKEditorField
                            data={midquel}
                            formId={FORM_ID}
                            name="midquel" />
                    </div>

                    <div className={"label"}>
                        ajánló box-ok sorrendje:
                    </div>
                    <div className={"box-edit"}>
                        {recommendations.map(this.renderBoxItem)}
                    </div>

                    <div className={"label"}>
                        <label>alsó html:</label>
                    </div>
                    <div className={"editor"}>
                        <CKEditorField
                            data={sequel}
                            formId={FORM_ID}
                            name="sequel" />
                    </div>
                </form>

                <div className="control-buttons">
                    <button className="button green" onClick={handleSubmit(this.onSubmit)}>Mentés</button>
                    <button className="button red" onClick={() => {
                        this.props.dispatch(enableUrlChange(false))
                        const location = this.props.isNew ? "/" : _.get(this.props, 'initialValues.canonical', "/")
                        this.props.dispatch(push(location.replace(/^[/]?(.*)$/, "/$1")))
                    }}>Elvetés</button>
                </div>


                { history.length > 0 &&
                <div>
                    {renderHistory(history)}
                    { historyLink !== null && ! this.state.historyMoreClicked &&
                    <div onClick={() => {
                        this.setState({ historyMoreClicked: true })
                        this.props.dispatch(getFullHistory(historyLink))
                    }}>Bővebben</div>
                    }
                </div>
                }
            </div>

        )
    }
}


export default connect(store => {
    const data = {
        ...store.base.data,
        recommendations: (store.base.data.recommendations || []).sort((a, b) => a.order - b.order)
    }
    return {
        recommendations: data.recommendations || [],
        initialValues: data || {},
        history: _.get(store.base, 'history', []),
        actions: _.get(store.base, 'actions', [])
    }
})(reduxForm({
    form: FORM_ID,
    enableReinitialize: true
})(EditFrontpage))
