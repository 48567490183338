import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { clearContentData } from "../../actions/common";
import InnerHtml from '../utils/InnerHtmlWrapper'





class NotFound extends Component {

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    render = () => {

        const { content } = this.props

        const getContent = () => (
            content !== null && content.length
                ? (<InnerHtml html={content} />)
                : (<>A keresett oldal nem található!</>)

        )

        return (
            <div className="404-content static-content read">
                {getContent()}
            </div>
        )
    }
}


export default connect(store => ({content: _.get(store, 'base.data.content', null)}))(NotFound)
