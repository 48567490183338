import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { isBrowser } from "react-device-detect"
import { push } from 'connected-react-router'
import {
    addImageClickEventListener,
    removeImageClickEventListener
} from '../../utils/common'
import InnerHtml from '../utils/InnerHtmlWrapper'
import BoxContainer from '../BoxContainer'
import '../../styles/news-archive.scss'
import Lightbox from 'react-image-lightbox'


class ReadNewsArchive extends React.Component {

    state = {
        lightboxOpen: false,
        imageIndex: 0
    }

    componentDidUpdate = prevProps => {
        const newImageList = _.get(this.props, 'lightbox', [])
        const prevImageList = _.get(prevProps, 'lightbox', [])

        if (! _.isEqual(newImageList, prevImageList)) {
            removeImageClickEventListener('.news-archive', prevImageList, this.imageClick)
            addImageClickEventListener('.news-archive', newImageList, this.imageClick)
        }
    }

    imageClick = event => {
        const imageList = _.get(this.props, 'lightbox', [])
        const index = imageList.findIndex(p => p === event.target.src)
        this.setState({
            lightboxOpen: true,
            imageIndex: index !== -1 ? index : 0
        })
    }

    componentDidMount = () => {
        const lightboxList = _.get(this.props, 'lightbox', [])

        addImageClickEventListener('.news-archive', lightboxList, this.imageClick)
    }

    componentWillUnmount = () => {
        const lightboxList = _.get(this.props, 'lightbox', [])
        removeImageClickEventListener('.news-archive', lightboxList, this.imageClick)
    }

    mapBoxContainerData = item => {

        const maybeThumbnail = isBrowser
            ? _.get(item, 'thumbnail.web', null)
            : _.get(item, 'thumbnail.mobile', null)

        return {...item, image: maybeThumbnail}
    }


    render = () => {

        const { prequel, sequel, news } = this.props.data
        const nextPage = _.get(this.props, 'actions.next-page.href', null)
        const prevPage = _.get(this.props, 'actions.prev-page.href', null)

        const images = _.get(this.props, 'lightbox', [])
        const { imageIndex } = this.state

        const prevBtn = (
            <div className={`icon prev pointer ${prevPage === null ? "disabled" : ""}`} onClick={() => {
                if (prevPage !== null)
                    this.props.dispatch(push(prevPage))
            }} />
        )
        const nextBtn = (
            <div className={`icon next pointer ${nextPage === null ? "disabled" : ""}`} onClick={() => {
                if (nextPage !== null)
                    this.props.dispatch(push(nextPage))
            }} />
        )

        return (
            <>
                <div className={"read news-archive"}>
                    <h2>Hírarchívum</h2>

                    <div className={"prequel"}>
                        <InnerHtml html={prequel} />
                    </div>

                    <div className={"strip"} />

                    <div className={"news-boxes"}>
                        <BoxContainer items={news.map(this.mapBoxContainerData)}  />
                    </div>
                    <div className={"paginator"}>
                        <div>
                            { prevPage
                                ? <a href={prevPage} onClick={e => e.preventDefault()}>{prevBtn}</a>
                                : <>{prevBtn}</>
                            }
                        </div>
                        <div>
                            { nextPage
                                ? <a href={nextPage} onClick={e => e.preventDefault()}>{nextBtn}</a>
                                : <>{nextBtn}</>
                            }
                        </div>
                    </div>

                    <div className={"strip"} />

                    <div className={"sequel"}>
                        <InnerHtml html={sequel} />
                    </div>
                </div>
                { this.state.lightboxOpen && images.length > 0 &&
                <Lightbox
                    mainSrc={images[imageIndex]}
                    nextSrc={images[(imageIndex + 1) % images.length]}
                    prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => {
                        this.setState({ lightboxOpen: false })
                    }}
                    onMovePrevRequest={() =>
                        this.setState({
                            imageIndex: (imageIndex + images.length - 1) % images.length,
                        })
                    }
                    onMoveNextRequest={() => {
                        this.setState({
                            imageIndex: (imageIndex + 1) % images.length,
                        })
                    }} />
                }
            </>
        )
    }
}

export default connect(store => {
    return {
        actions: store.base.actions,
        data: store.base.data,
        lightbox: store.base.lightbox
    }
})(ReadNewsArchive)