import { createStore, applyMiddleware } from 'redux'
import reduxThunk from "redux-thunk"
import promise from 'redux-promise-middleware'
import { routerMiddleware } from 'connected-react-router'
import responseDataProcessor from './middlewares/responseDataProcessor'
import urlChangePreventer from './middlewares/urlChangeListener'
import preloader from './middlewares/preloaderMiddleware'

import { createBrowserHistory } from 'history'

import createRootReducer from './reducers/root'



export const history = createBrowserHistory({
    basename: '/'
})


const initStore = (initState) => {
    return createStore(
        createRootReducer(history),
        initState,
        applyMiddleware(
            reduxThunk,
            promise,
            urlChangePreventer,
            routerMiddleware(history),
            responseDataProcessor,
            preloader
        )
    )
}

export default initStore