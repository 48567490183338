import { defaultState } from '../middlewares/responseDataProcessor'

export default (state = defaultState, action) => {

    switch (action.type) {
        case "content/EDIT_FULFILLED":
            return Object.assign(state, action.processed)

        case "content/READ_FULFILLED":
            return Object.assign(state, action.processed)

        case "content/SAVE_FULFILLED":
            return {...state}

        case "content/CLEAR_DATA":
            return {...defaultState}



        default:
            return {...state}
    }

}