import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { isBrowser } from "react-device-detect"
import { push } from 'connected-react-router'

import { clearContentData } from "../../actions/common"
import InnerHtml from '../utils/InnerHtmlWrapper'
import { ColoredLabel } from '../utils/common'
import Lightbox from 'react-image-lightbox'

import statusCanceled from '../../assets/icons/prod-canceled.svg'
import statusChanged from '../../assets/icons/prod-changed.svg'
import statusTicketed from '../../assets/icons/prod-ticketed.svg'
import '../../styles/production.scss'
import {addImageClickEventListener, removeImageClickEventListener} from "../../utils/common";


class ReadProduction extends Component {

    state = {
        lightboxOpen: false,
        imageIndex: 0
    }

    componentDidUpdate = prevProps => {
        const newImageList = _.get(this.props, 'lightbox', [])
        const prevImageList = _.get(prevProps, 'lightbox', [])

        if (! _.isEqual(newImageList, prevImageList)) {
            removeImageClickEventListener('.production', prevImageList, this.imageClick)
            debugger
            addImageClickEventListener('.production', newImageList, this.imageClick)
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
        const lightboxList = _.get(this.props, 'lightbox', [])
        removeImageClickEventListener('.production', lightboxList, this.imageClick)
    }

    imageClick = event => {
        const imageList = _.get(this.props, 'lightbox', [])
        const index = imageList.findIndex(p => p === event.target.src)
        this.setState({
            lightboxOpen: true,
            imageIndex: index !== -1 ? index : 0
        })
    }

    componentDidMount = () => {
        const lightboxList = _.get(this.props, 'lightbox', [])
        console.log(this.props)
        addImageClickEventListener('.production', lightboxList, this.imageClick)
    }

    renderLabel = (item, index) => {
        const backgroundColor = item.featuredColor && item.featuredColor.background ? item.featuredColor.background : undefined
        const foregroundColor = item.featuredColor && item.featuredColor.foreground ? item.featuredColor.foreground : undefined
        return (
            <ColoredLabel key={index} onClick={() => this.props.dispatch(push(item.href))} foregroundColor={foregroundColor} backgroundColor={backgroundColor}>{item.name}</ColoredLabel>
        )
    }


    navigateTo = href => e =>  {
        e.preventDefault()
        if (href && href.length)
            this.props.dispatch(push(href))
    }

    renderInstance = (item, index) => {

        const dayName = item.day && (item.day.name || "")

        const matches = (item.time || "").match(new RegExp(`^([^(]+)([(]${dayName}[)])(.+)$`))

        const maybeDuration = _.get(item, 'duration', null)
        
        const durationText = maybeDuration !== null
            ? ` (kb. ${maybeDuration} perc)`
            : ""
        
        console.log(durationText)
            
        const maybeEndTime = _.get(item, 'endTime', null)
        
        const endTimeText = maybeEndTime !== null
            ? ` - ${maybeEndTime}`
            : ""
        
        console.log(endTimeText)

        const { isEditor } = this.props

        const time = (matches !== null && matches.length === 4)
            ? <>
                {matches[1]}
                <a href={item.day.href} className={"pointer"} onClick={this.navigateTo(item.day.href)}>{matches[2]}</a>
                {matches[3]}
                {durationText === '' ? endTimeText : durationText}
              </>
            : <>{item.time || ""} {durationText}</>

        const renderEmptyField = label => {
            if (isEditor) {
                return <span className="empty">{label}</span>
            } else {
                return <span></span>
            }
        }

        const separatorStageAndTime = (((item.village || item.stage) && (item.time && item.time.length > 0)) || isEditor)
            ? (<>&nbsp;-&nbsp;</>) : null

        return (

            <div className={"instance"} key={index}>
                <div>
                    { item.village
                        ? (<a href={item.village.href} onClick={this.navigateTo(item.village.href)} className={"pointer"}><b>{item.village.name}</b></a>)
                        : renderEmptyField('város')
                    }
                    { ((item.village && item.stage) || isEditor) && (<>&nbsp;-&nbsp;</>) }
                    { item.stage
                        ? (<a href={item.stage.href}><span onClick={this.navigateTo(item.stage.href)} className={"pointer"}>{item.stage.name}</span></a>)
                        : renderEmptyField('helyszín')
                    }
                    {! isBrowser ? <div style={{minHeight: ".2em"}}/> : separatorStageAndTime}
                    { (item.time && item.time.length > 0) || item.duration
                        ? <span className={"time"}>{time}</span>
                        : renderEmptyField('időpont')
                    }
                </div>
                <div className={"status"}>
                    {item.canceled && <a href={item.canceled.href} className={"prod-status pointer"} onClick={this.navigateTo(item.canceled.href)}>
                        <img className={"status-img"} src={statusCanceled} alt={""} />
                    </a>}
                    {item.ticketed && <a href={item.ticketed.href} className={"prod-status pointer"} onClick={this.navigateTo(item.ticketed.href)}>
                        <img className={"status-img"} src={statusTicketed} alt={""} />
                    </a>}
                    {item.changed && <a href={item.changed.href} className={"prod-status pointer"} onClick={this.navigateTo(item.changed.href)}>
                        <img className={"status-img"} src={statusChanged} alt={""} />
                    </a>}
                </div>
                {item.shortDescription && <div className={"short-description"}>
                    <InnerHtml html={_.get(item, 'shortDescription', "")} />
                </div>}
            </div>
        )
    }

    renderEmpty = title => {
        return this.props.isEditor || false
            ? (
                <div className={"empty-content"}>
                    <div className={"alert"}>! HIÁNYZÓ TARTALOM !</div>
                    <div className={"title"}>{title}</div>
                </div>
            )
            : (<></>)
    }

    renderCreator = (item, index) => {
        return (
            <div key={index} className={`${item.href ? "pointer underline no-underline-hover" : ""}`} onClick={this.navigateTo(item.href)}>
                <a href={item.href} onClick={e => e.preventDefault()}>{item.name}</a>
            </div>
        )
    }


    render = () => {


        const title = _.get(this.props, 'data.title', "")
        const genre = _.get(this.props, 'data.genre', [])
        const creators = _.get(this.props, 'data.creators', [])
        const labels = _.get(this.props, 'data.labels', [])
        const instances = _.get(this.props, 'data.instances', [])
        const description = _.get(this.props, 'data.description', "")
        const shortDescription = _.get(this.props, 'data.shortDescription', "")
        const cover = isBrowser
            ? _.get(this.props, 'data.cover.web', "")
            : _.get(this.props, 'data.cover.mobile', "")

        const notes = _.get(this.props, 'data.notes', "")
        const documents = _.get(this.props, 'data.documents', [])

        // isEmptyInstance
        const isEmptyInstance = ! instances.length// || instances.filter(i => (! i.village || ! i.stage || ! i.time)).length

        const images = _.get(this.props, 'lightbox', [])
        const { imageIndex } = this.state

        return (
            <>
                <div className="production static-content read">
                    <h1>{title}</h1>

                    <div className={"labels"}>
                        {labels.map(this.renderLabel)}
                    </div>

                    { isEmptyInstance
                        ? <div className="instances">{this.renderEmpty("Hol? Mikor?")}</div>
                        : (
                            <div className="instances read">
                                <h2>Hol? Mikor?</h2>
                                {instances.map(this.renderInstance)}
                            </div>
                        )
                    }

                    { (shortDescription.length > 0) &&
                        <div className={"small-description"}><InnerHtml html={shortDescription} /></div>
                    }

                    { cover.length > 0
                        ? <div className={"cover"}><img src={cover} alt={""} /></div>
                        : <div className={"cover"}>{this.renderEmpty("Kép")}</div>
                    }

                    { description.length > 0
                        ? <div className={"description"}><InnerHtml html={description} /></div>
                        : <div className={"description"}>{this.renderEmpty("Leírás")}</div>
                    }

                    { creators.length > 0
                        ? <div className={"creators-container"}>
                            <h3>Lásd:</h3>
                            <div style={{marginLeft: "1.1em"}} className={"creators"}>
                                {creators.map(this.renderCreator)}
                            </div>
                          </div>
                        : <div className={"creators"}>{this.renderEmpty("Alkotók")}</div>
                    }

                    { genre.length > 0
                        ? <div className={"labels creators"}>{genre.map(this.renderLabel)}</div>
                        : <div className={"labels creators"}>{this.renderEmpty("Műfaj")}</div>
                    }

                    { notes.length > 0 &&
                    <div className={"notes"}>
                        <div className={"label"}><b>Jegyzetek:</b></div>
                        <div>
                            <InnerHtml html={notes} />
                        </div>

                    </div>
                    }

                    { documents.length > 0 &&
                    <div className={"documents"}>
                        <div><b>Dokumentumok:</b></div>
                        {documents.map((item, idx) => {

                            return (
                                <div className={"item"} key={idx}>
                                    <div className={"icon paperclip"} />
                                    <div className={""}><a download={true} href={item.path}>{item.name}</a></div>
                                </div>
                            )
                        })}

                    </div>
                    }
                </div>
                { this.state.lightboxOpen && images.length > 0 &&
                <Lightbox
                    mainSrc={images[imageIndex]}
                    nextSrc={images[(imageIndex + 1) % images.length]}
                    prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => {
                        this.setState({ lightboxOpen: false })
                    }}
                    onMovePrevRequest={() =>
                        this.setState({
                            imageIndex: (imageIndex + images.length - 1) % images.length,
                        })
                    }
                    onMoveNextRequest={() => {
                        this.setState({
                            imageIndex: (imageIndex + 1) % images.length,
                        })
                    }} />
                }
            </>
        )
    }
}


export default connect(store => {
    return {
        data: store.base.data,
        lightbox: store.base.lightbox
    }
})(ReadProduction)

