export default store => next => action => {
    const pattern = /^(.+)_/

    if (/.+_PENDING$/.test(action.type)) {
        next({ ...action,
            preloader: {
                enablePreloader: true,
                eventName: action.type.match(pattern)[1]
            }})
    } else if (/.+_REJECTED$|_FULFILLED$/.test(action.type)) {
        next({ ...action,
            preloader: {
                enablePreloader: false,
                eventName: action.type.match(pattern)[1]
            }})
    } else {
        next(action)
    }
}
