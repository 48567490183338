import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import {clearContentData, editSheets, updateSheet, deleteSheet, switchRefresh} from "../../actions/common"

import '../../styles/sheets.scss'

import { EditTitle } from "../utils/common"
import copyToClipboard from "../../assets/icons/copy-to-clipboard.svg";
import redirectImg from '../../assets/icons/redirect.svg'
import {CopyToClipboard} from "react-copy-to-clipboard"



class EditSheets extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cloud: this.initCloud(props.cloud)
        }
    }

    initCloud = cloud => {
        const mapSelect = t => ({...t, selected: false})

        return cloud.map(section => {
            if (section.title === "fesztiválév" && section.tags.length > 0) {
                return {
                    ...section,
                    tags: [
                        ...section.tags.slice(0, section.tags.length - 1).map(mapSelect),
                        {
                            ...section.tags[section.tags.length - 1],
                            selected: true
                        }
                    ]
                }
            } else {
                return {
                    ...section,
                    tags: section.tags.map(mapSelect)
                }
            }
        })
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    addSheet = () => {
        const tagStr = this.state.cloud.map(section => {
            return section.tags.filter(t => t.selected)
        }).flat().map((tag, idx) => {
            return tag.href.replace(/^\/(.+)/, "$1")
        }).join("/")

        this.refreshSheet(tagStr)
    }

    refreshSheet = id => {
        const andThen = () => {
            this.props.dispatch(editSheets())
            this.setState({ cloud: this.initCloud(this.state.cloud) })
        }
        this.props.dispatch(updateSheet(id)).then(andThen, andThen)
    }

    switchSheetRefresh = id => {
        this.props.dispatch(switchRefresh(id)).then(
            () => this.props.dispatch(editSheets()),
            () => this.props.dispatch(editSheets())
        )
    }

    removeSheet = id => {
        if (window.confirm("Biztosan törölni akarod?")) {
            this.props.dispatch(deleteSheet(id)).then(
                () => this.props.dispatch(editSheets()),
                () => this.props.dispatch(editSheets())
            )
        }
    }

    renderSheet = (item, idx) => {
        const autoRefresh = !! item.refreshing
        return (
            <div key={idx} className={"sheet-item"}>
                <div className={"left"}>
                    <div>
                        <b>cím: </b>{item.title}
                    </div>
                    <div>
                        <b>legutóbb frissítve: </b>{item.lastModified}
                    </div>
                    <div>
                        <b>url: </b>
                        <input disabled={true} defaultValue={item.url} />
                        <CopyToClipboard text={item.url} onCopy={() => alert("Vágólapra másolva!")}>
                            <img className="copy-to-clipboard" src={copyToClipboard} alt="" />
                        </CopyToClipboard>

                        <a target={"_blank"} href={item.url}>
                            <img className="copy-to-clipboard" src={redirectImg} alt="" />
                        </a>
                    </div>
                </div>
                <div className={"right"}>
                    <button title={`Az automatikus frissítés jelenleg ${autoRefresh ? `bekapcsolva` : 'kikapcsolva'}`} onClick={() => {
                        this.switchSheetRefresh(item.updateId)
                    }} className={`button ${autoRefresh ? 'red' : 'green'}`}>automatikus frissités {autoRefresh ? 'kikapcsolása' : 'bekapcsolása'}</button>
                    <button onClick={() => {
                        this.refreshSheet(item.updateId)
                    }} className={"button blue"}>frissítés</button>
                    <button onClick={() => {
                        this.removeSheet(item.updateId)
                    }} className={"button red"}>törlés</button>
                </div>
            </div>
        )
    }

    renderTag = (section, sectionIdx) => (item, idx) => {
        return (
            <div
                key={idx}
                className={`tag-item ${item.selected ? "selected" : ""}`}
                onClick={() => {

                    const newTags = section.title === "fesztiválév"
                        ? [
                            ...section.tags.slice(0, idx).map(t => ({...t, selected: false})),
                            {...item, selected: true},
                            ...section.tags.slice(idx + 1).map(t => ({...t, selected: false}))
                        ]
                        : [
                            ...section.tags.slice(0, idx),
                            {...item, selected: !!! item.selected},
                            ...section.tags.slice(idx + 1)
                        ]

                    const newCloud = [
                        ...this.state.cloud.slice(0, sectionIdx),
                        {...section, tags: newTags},
                        ...this.state.cloud.slice(sectionIdx + 1)
                    ]
                    this.setState({ cloud: newCloud })
                }}>{item.name}</div>
        )
    }

    renderCloudSection = (item, idx) => {
        return (
            <div key={idx} className={"cloud-section"}>
                <div className={"section-title"}>
                    <span className={item.open ? "open" : ""} onClick={() => {
                        const cloud = [
                            ...this.state.cloud.slice(0, idx),
                            {...item, open: ! item.open},
                            ...this.state.cloud.slice(idx + 1)
                        ]
                        this.setState({ cloud: cloud })
                    }}>{item.title}</span>
                </div>
                { item.open &&
                <div className={"tag-list"}>
                    {item.tags.map(this.renderTag(item, idx))}
                </div>
                }
            </div>
        )
    }


    render = () => {

        const { sheets } = this.props
        const { cloud } = this.state

        return (
            <div className="edit sheets">

                <EditTitle>Google spreadsheetek</EditTitle>

                <div className={"margin"} />

                <div className={"label"}>spreadsheet lista:</div>
                <div className={"sheets-list"}>
                    {sheets.map(this.renderSheet)}
                </div>

                <div className={"label"}>spreadsheet létrehozás:</div>
                <div className={"cloud-list"}>
                    {cloud.map(this.renderCloudSection)}
                </div>

                <div className="control-buttons">
                    <button className="button green" onClick={this.addSheet}>Létrehozás</button>
                </div>
            </div>

        )
    }
}

export default connect(store => {
    return {
        cloud: _.get(store.base, 'data.cloud', []),
        sheets: _.get(store.base, 'data.sheets', []),
        actions: _.get(store.base, 'actions', [])
    }
})(EditSheets)
