import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ReactGA from 'react-ga'
import { isBrowser } from "react-device-detect"
import { withCookies, Cookies } from 'react-cookie'
import { instanceOf } from 'prop-types'
import { push } from 'connected-react-router'
import AutoSuggest from 'react-autosuggest'
import axios from 'axios'
import MetaTags from 'react-meta-tags'

import './styles/app.scss'
import './styles/footer.scss'
import './styles/content.scss'
import './styles/buttons.scss'
import './styles/main-menu.scss'
import './styles/box.scss'
import './styles/autosuggest.scss'
import 'react-image-lightbox/style.css'


import { clientId } from './index'
import Toolbar from './components/Toolbar'
import FooterMenu from './components/FooterMenu'
import MainMenuMobile from './components/MainMenuMobile'
import MainMenuBrowser from './components/MainMenuBrowser'
import Preloader from './components/Preloader'

import mainLogo from './assets/logo-top.svg'

import {
  checkUser,
  resetUserInit,
  readSiteGlobals,
  resetSiteGlobalsInit,
  updateSearchText, closeMobileMenu, setGoogleAccountApi
} from './actions/common'

// import bottomLogo from './assets/icons/logo_bottom.svg'
import {loadScript} from "./components/utils/common";


class App extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    constructor(props) {
        super(props)
        this.ref = React.createRef()

        this.state = {
            googleInitialized: false,
            suggestions: [],
            userInitialized: false,
            siteInitialized: false,
            location: props.location,
            isScrollTop: true
        }
    }

    componentDidMount = () => {
        this.props.dispatch(checkUser())
        this.props.dispatch(readSiteGlobals())
        const elem = document.querySelector("#root")
        if (elem) {
            elem.addEventListener('scroll', e => {
                const isScrollTop = elem.scrollTop <= 0
                if (this.state.isScrollTop !== isScrollTop) {
                    this.setState({ isScrollTop: isScrollTop })
                }
            })
        }
        const path = this.props.location.search
            ? `${this.props.location.pathname}?${this.props.location.search}`
            : this.props.location.pathname

      ReactGA.pageview(path)

      loadScript('https://accounts.google.com/gsi/client',
        () => {
            this.props.dispatch(setGoogleAccountApi(window.google.accounts.id))
        },
        () => {
            console.error("failed to load google accounts")
            this.props.dispatch(setGoogleAccountApi(null))
        }
      )
    }

    componentDidUpdate = prevProps => {
        if (this.props.userChecked && ! prevProps.userChecked) {
            this.setState({ userInitialized: true })
            this.props.dispatch(resetUserInit())
        }

        if (this.props.siteLoaded && ! prevProps.siteLoaded) {
            this.setState({ siteInitialized: true })
            this.props.dispatch(resetSiteGlobalsInit())
        }

        if (this.props.location.pathname !== prevProps.location.pathname || this.props.location.search !== prevProps.location.search) {
            const path = this.props.location.search
                ? `${this.props.location.pathname}?${this.props.location.search}`
                : this.props.location.pathname

            ReactGA.pageview(path)
        }
    }

    onSuggestionsFetchRequested = async ({ value }) => {
        const result = value.length >= 3 ? await getSuggestions(value) : []
        this.setState({
            suggestions: result
        })
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        })
    }

    onSuggestionSelected = (event, { suggestion }) => {
        if (suggestion.href && suggestion.href.length) {
            this.props.dispatch(push(suggestion.href))
            this.props.dispatch(closeMobileMenu())
        }
    }

    search = () => {
        const { dispatch, searchText, location } = this.props
        const pastParam = new URLSearchParams(location.search || "").get("past")
        const q = encodeURIComponent(searchText)
        dispatch(closeMobileMenu())
        dispatch(push(`/x/search?q=${q}${pastParam !== null ? '&past=' + pastParam : ""}`))
        dispatch(updateSearchText(""))
    }

    render = () => {

        const { siteData, seo } = this.props
        const { userInitialized, siteInitialized } = this.state

        const copyrightYear = _.get(this, 'props.siteData.copyrightYear', "")

        if (! userInitialized || ! siteInitialized)
            return null

        const inputProps = {
            value: this.props.searchText || "",
            placeholder: "Keresés",
            onChange: e => this.props.dispatch(updateSearchText(e.target.value)),
            onKeyUp: e => {
                const { searchText } = this.props
                if (e.keyCode === 13 && searchText && searchText.length) {
                    this.search()
                }
            },
            maxLength: 50,
            type: "text"
        }

        const inputElem = (
            <AutoSuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderSuggestionsContainer={renderSuggestionsContainer(this.props.searchText, this.search)}
                alwaysRenderSuggestions={false}
                shouldRenderSuggestions={(value) => {
                    return value.trim().length >= 3
                }}
                inputProps={inputProps} />
        )

        const pageTitle = _.get(seo, 'title', null)
        const meta = _.get(seo, 'meta', [])
        const link = _.get(seo, 'link', [])
        
        return (
            <div className={`app ${isBrowser ? "browser" : "mobile"}`} ref={this.ref}>

                <Preloader />

                <MetaTags>
                    { pageTitle !== null && <title>{pageTitle}</title>}
                    { meta.map((item, idx) => {
                        if (item.hasOwnProperty("content")) {
                            if (item.hasOwnProperty("property")) {
                                return [(<meta key={idx} property={item.property} content={item.content} />)]
                            } else if (item.hasOwnProperty("name")) {
                                return [(<meta key={idx} name={item.name} content={item.content} />)]
                            }
                        }
                        return []
                    }).flat()}
                    { link.map((item, idx) => {
                        if (item.hasOwnProperty("rel") && item.hasOwnProperty("href")) {
                            return [(<link key={idx} rel={item.rel} href={item.href} />)]
                        } else {
                            return []
                        }
                    }).flat()}
                </MetaTags>

                <Toolbar />


                {/* mobile menu, info */}
                <div className={"row-menu mobile"}>
                    <div className={"main-menu"}>
                        <MainMenuMobile
                            searchElement={(<div className={"search-wrapper mobile-search"}>{inputElem}</div>)}
                        />
                    </div>
                </div>
                <div className={"row-info"}>
                    <div className={"logo"}>
                        <img src={mainLogo} alt={""} onClick={() => this.props.dispatch(push("/"))} />
                    </div>
                    <div className={"title"}>
                        <h2>Ördögkatlan fesztivál</h2>
                        <p>{_.get(this, 'props.siteData.festival', "")}</p>
                    </div>
                    <div/>
                </div>
                {/*</MobileView>*/}

                {/* browser kereso / fomenu */}
                <div className={`row-menu browser  ${! this.state.isScrollTop ? "small" : ""}`}>
                    <div className={"search"}>
                        <div className={"logo"}>
                            <img
                                src={mainLogo}
                                className={"pointer"}
                                alt={"logo"}
                                onClick={() => this.props.dispatch(push("/"))} />
                        </div>
                        <div className={"info"}>
                            <div className={"title-and-date"}>
                                <h2>Ördögkatlan fesztivál</h2>
                                <p>{_.get(this, 'props.siteData.festival', "")}</p>
                            </div>
                            <div className={"search-wrapper browser-search"}>
                                {inputElem}
                            </div>
                        </div>
                    </div>
                    <div className={`main-menu`}>
                        <MainMenuBrowser />
                    </div>
                </div>


                {/*
                <div className="header">
                    header<br/>
                    copyright year: {_.get(siteData, 'copyrightYear', "")}<br/>
                    festival: {_.get(siteData, 'festival', "")}<br/>
                    {user !== null ? "Belépve: " + user.fullName : "Kilépve"}
                </div>
                */}

                {/* content */}
                <div className="row-content">
                    {this.props.children}
                </div>

                {/* footer */}
                <div className="row-footer">
                    <div className="footer-bottom">
                        <div className="footer-logo">
                            <table>
                                <tbody><tr>
                                    <td className="logo"><img src={mainLogo} alt={"logo"} /></td>
                                    <td className="copyright">
                                        &copy;&nbsp;{copyrightYear}&nbsp;Ördögkatlan&nbsp;fesztivál
                                        <br/>
                                        minden jog fenntartva
                                    </td>
                                </tr></tbody>
                            </table>
                        </div>
                        <div className="footer-menu">
                            <FooterMenu />
                        </div>
                    </div>
                </div>

                {/* sponsors */}
                <div className={"row-sponsors"}>
                    <div className="footer-content" dangerouslySetInnerHTML={{__html: _.get(siteData, 'footer', '')}} />
                </div>
            </div>
        )
    }
}


const getSuggestions = (value) => {
    return axios.get(`/api/v1.0/ac.search`, { params: { q: value }}).then(r => r.data, () => [])
}

const getSuggestionValue = suggestion => suggestion.title

const renderSuggestion = suggestion => {
    const thumbnail = isBrowser
        ? _.get(suggestion, 'thumbnail.web', null)
        : _.get(suggestion, 'thumbnail.mobile', null)

    return (
        <div className={`item ${thumbnail !== null ? "with-image" : ""}`}>
            { thumbnail !== null &&
            <div className={"image"}><img src={thumbnail} alt={suggestion.title} /></div>
            }
            <div className={"suggestion-title"}>
                <div>{suggestion.title}</div>
            </div>

        </div>
    )
}

const renderSuggestionsContainer = (searchText = "", searchFn = () => {}) => ({ containerProps, children, query }) => {
    return (
        <>
        <div {...containerProps}>
            <div className={"drop-shadow"}>
                {children}
                { searchText.length >= 3 &&
                <div className={"show-all"}>
                    <span onClick={() => {
                        searchFn()
                    }}>Összes találat</span>
                </div>
                }
            </div>
        </div>
        </>
    );
}


export default withCookies(connect(store => {
    return {
        user: store.user.user,
        userChecked: store.user.initialized,
        siteData: _.get(store, 'site.data.data', {}),
        siteLoaded: store.site.initializedRead,
        location: store.router.location,
        searchText: store.search.searchText,
        seo: store.base.seo,
        googleAccountApi: store.common.googleAccountApi
    }
})(App))
