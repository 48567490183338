import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'

import base from './baseReducer'
import user from './userReducer'
import content from './contentReducer'
import common from './commonReducer'
import site from './siteGlobalReducer'
import editors from './editorsReducer'
import search from './searchReducer'
import preloader from './preloderReducer'


export default (history) => combineReducers({
    base,
    common,
    user,
    content,
    site,
    editors,
    search,
    preloader,
    router: connectRouter(history),
    form: formReducer
})