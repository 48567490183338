import axios from "axios"

export const Constraints = {

    required: value => (value || typeof value === 'number' ? undefined : 'Kötelező mező'),

    maxLength: max => value =>
        value && value.length > max ? `Must be ${max} characters or less` : undefined,

    minLength: min => value =>
        value && value.length < min ? `Must be at least ${min} characters` : undefined,

    number: value =>
        value && isNaN(Number(value)) ? 'Must be a number' : undefined,

    maxValue: max => value =>
        value && value < max ? `Must be at least ${max}` : undefined,

    minValue: min => value =>
        value && value < min ? `Must be at least ${min}` : undefined,

    canonical: value =>
        value && ! /^[\d\-a-zA-Z/]*$/.test(value) ? "Hibás url" : undefined

}



export const validateField = (value, constraints = []) => (
    constraints.reduce((acc, curr) => (
        typeof acc === "undefined" ? curr(value) : acc
    ), undefined)
)


export const asyncValidateCanonicalId = values => {

    if ((values.origCanonical !== null && values.origCanonical === values.canonical) || values.canonical.length <= 0) {
        return new Promise(resolve => resolve())
    } else {
        return axios.get(`/api/v1.0/id.available/${encodeURI(values.canonical)}`).then(
            r => {
                if (! r.data) {
                    throw { canonical: "Foglalt azonosító" }
                }
            },
            () => {
                throw { canonical: "Váratlan hiba" }
            }
        )
    }
}