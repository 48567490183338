import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { clientId } from '../index'
import {checkUser, loginUser, setUserJWT} from '../actions/common'


class Login extends Component {

    state = {
      initialized: false
    }

    ref = React.createRef()

    handleInit = () => {
      const { api, dispatch } = this.props
      if (! this.state.initialized && api) {
        api.initialize({
          client_id: clientId,
          callback: (res) => {
            dispatch(loginUser(res.credential)).then(
              () => {
                dispatch(setUserJWT(res))
                dispatch(checkUser())
                dispatch(push("/"))
              },
              () => {}
            )
          }
        })
        api.prompt()
        api.renderButton(this.ref.current, { type: 'standard' })
        this.setState({ initialized: true })
      }
    }

    componentDidMount = () => {
      this.handleInit()
    }

    componentDidUpdate = () => {
        this.handleInit()
    }

    render = () => {
      return (
        <div ref={this.ref} />
      )
    }
}

export default connect(store => ({
  api: store.common.googleAccountApi
}))(Login)