

export const defaultState = {
    data: {},
    links: [],
    display: [],
    //toolbar: [],
    history: []
}


export default store => next => action => {
/*
    if (action.type === "editors/EDIT_FULFILLED") {

        const processed = action.payload.reduce((acc, curr) => {
            if (/access\-levels/.test(curr.config.url)) {
                return {...acc, accessLevels: curr.data}
            } else {
                return {...acc, data: curr.data}
            }
        }, {})

        return next({
            ...action, ...processed
        })
    } else if (action.type === "editors/FETCH_EDITORS_FULFILLED") {
        return next({
            ...action,
            data: action.payload.data
        })
    } else */if (action.type === "content/EDIT_FULFILLED" || action.type === "content/READ_FULFILLED") {
        // @TODO ez sem fog kelleni

        const processed = Object.assign(defaultState, action.payload.data)

        return next({
            ...action,
            processed: processed
        })
    } else {
        return next(action)
    }


}