import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import '../../styles/edit-editors.scss'

import {
    saveEditorAccessLevel,
    resetEditors,
    editEditors,
    fetchEditorAccessLevels,
    createEditors,
    refreshEditors
} from "../../actions/common"
import {EditTitle} from "../utils/common";
import InnerHtml from '../utils/InnerHtmlWrapper'
import toast, { Toaster } from 'react-hot-toast';

import urlValidIcon from "../../assets/icons/url-valid.svg";


class EditEditors extends Component {

    constructor(props) {
        super(props)

        props.dispatch(fetchEditorAccessLevels())

        this.state = {
            data: null,
            accessLevels: [],
            passInputVer: [],
            addEditor: false,
            newEditorName: "",
            newEditorPass: ""
        }
    }

    componentDidUpdate = prevProps => {
        const { data, accessLevels } = this.props
        const stateData = this.state.data

        if ((stateData === null && data.length) || (! _.isEqual(data, prevProps.data))) {
            this.setState({
                data: data.map(user => ({...user, selectValue: user.accessLevel}))
            })
        }

        if (! _.isEqual(accessLevels, this.state.accessLevels)) {
            this.setState({
                accessLevels: accessLevels
            })
        }

    }

    componentWillUnmount = () => {
        this.props.dispatch(resetEditors())
    }

    refreshSelectChange = (editorId, level) => {
        const data = this.state.data.map(u => {
            if (editorId === u.editorId) {
                return { ...u, selectValue: level }
            } else {
                return u
            }
        })
        this.setState({ data: data })
    }

    saveAccessLevel = user => {
        const { dispatch } = this.props

        dispatch(saveEditorAccessLevel(user.editorId, user.selectValue, user.newPassword)).then(
            () => {
                dispatch(editEditors()).then(() => {
                    this.refreshSelectChange(user.editorId, "-")
                    window.location.href = window.location.href // quickfix. nem dispatch -> new order??? -> inputs stay in place
                })
            },
            () => {}
        )

    }

    accessLevelSelectOnChange = editorId => e => {
        this.refreshSelectChange(editorId, e.target.value)
    }

    infopultPassOnChange = editorId => e => {
        this.refreshInputChange(editorId, e.target.value)
    }
    
    refreshInputChange = (editorId, pass) => {
        const data = this.state.data.map(u => {
            if (editorId === u.editorId) {
                return { ...u, newPassword: pass }
            } else {
                return u
            }
        })
        this.setState({ data: data })
    }

    renderAccessLevelSelect = user => {
        const { accessLevels } = this.state
        
        return (
            <select disabled={! user.enabled} defaultValue={user.selectValue} onChange={this.accessLevelSelectOnChange(user.editorId)}>
                {accessLevels.map((o, idx) => (
                    <option key={idx} value={o}>{o}</option>
                ))}
            </select>
        )
    }
    
    onAddEditor = () => {
        this.setState({ addEditor: true})
    }

    createNewEditor = () => {
        const { newEditorName, newEditorPass } = this.state
        if(newEditorName === "") {
            toast(
                'Minimum a nevet töltsd ki', {
                    duration: 2000,
                    position: 'center-right',
                }
            )
            return null
        }
        this.props.dispatch(createEditors(newEditorName, newEditorPass)).then(() => {
            this.setState({ addEditor: false, newEditorName: "", newEditorPass: ""},
            () => {
                this.props.dispatch(refreshEditors()).then((resp) => {
                    this.setState({ data: resp.value.data.data })
                })
            })
        })
    }

    renderInfopultPassInput = user => {
        return (
            <input
                type="text"
                placeholder={user.infopultAccess ? "új infopult jelszó" : ""}
                onChange={
                    this.infopultPassOnChange(user.editorId)
                }
            />
        )
    }

    renderNewEditor = () => {
        return (
            <tr className={"user-row"} key={"c"}>
                <td colSpan={5}>
                    <Toaster
                    toastOptions={{
                        className: '',
                        style: {
                            border: '1px solid #713200',
                            color: '#713200',
                            boxShadow: 'none',
                        },
                    }} />
                    <input
                    type="text"
                    placeholder="teljes név"
                    onChange={(e) => {
                        this.setState({ newEditorName: e.target.value })
                    }}
                />
                </td>
                <td>
                    <input
                        type="text"
                        placeholder="infopult jelszó"
                        onChange={(e) => {
                            this.setState({ newEditorPass: e.target.value })
                        }}
                    />
                </td>
                <td><button
                    onClick={() => this.createNewEditor()}
                    className={"button green"}
                >Mentés</button></td>
            </tr>
        )
    }


    renderUser = (user, index) => {

        const btnDisabled = (user.selectValue === user.accessLevel || ! user.enabled) && (user.newPassword == undefined )
        const btnClass = btnDisabled ? "disabled" : "green"
        const firstName = _.get(user, 'firstName', "")
        const lastName = _.get(user, 'lastName', "")
        const email = _.get(user, 'email', "")

        return [
            (
                <tr className={firstName && lastName ? "user-row" : "fullname-row"} key={index}>
                    {firstName && lastName ? <td><div>{_.get(user, 'firstName', "")}</div></td> : null}

                    {firstName && lastName ? <td><div>{_.get(user, 'lastName', "")}</div></td> : <td colSpan="2"><div>{_.get(user, 'fullName', "")}</div></td>}

                    <td><div>{email ? _.get(user, 'email', "") : <InnerHtml html={"&nbsp;"} />}</div></td>
                    <td><img src={user.avatar} alt="" /></td>
                    <td>{this.renderAccessLevelSelect(user)}</td>
                    <td>
                        {this.renderInfopultPassInput(user)}
                        <img className={user.infopultAccess ? 'okayIcon' : 'okayIcon hidden' } src={urlValidIcon} alt="Infopult hozzáférés" />
                    </td>
                    <td>
                        <button
                            className={`button ${btnClass}`}
                            disabled={btnDisabled}
                            onClick={() => this.saveAccessLevel(user)}
                        >Mentés</button>
                    </td>
                </tr>
            ),
            (
                <tr className={"empty-row"} key={index + "a"}>
                    <td colSpan="6" />
                </tr>
            )
        ]
    }

    render = () => {

        const users = _.get(this.state, 'data', [])

        if (users === null) return null

        return (
            <div className="edit edit-editors">
                <EditTitle>Szerkesztők menedzselése</EditTitle>
                <br />
                <table className={"user-list"}><tbody>
                    {users.map(this.renderUser)}
                    {this.state.addEditor ? this.renderNewEditor() : null}
                    <tr className={"empty-row"} key={"b"}>
                        <td colSpan="6">
                            <div className={"icon add"} onClick={this.onAddEditor} />
                        </td>
                    </tr>
                </tbody></table>
            </div>

        )
    }
}



export default connect(store => {
    return {
        data: store.base.data || [],
        accessLevels: store.editors.accessLevels
    }
})(EditEditors)
