import _ from 'lodash'

export default store => next => action => {

    const state = store.getState()

    if (action.type === "@@router/CALL_HISTORY_METHOD" && ! _.get(state, 'common.urlChangeEnabled', true)) {
        // prevent navigate action
        console.warn("prevent navigation")
        return (next({type: "common/STORE_URL_CHANGE_REQUEST", payload: _.clone(action)}))
    } else if (
        action.type === "common/ENABLE_URL_CHANGE" &&
        _.get(state,'common.storedUrlChangeRequest', null) !== null &&
        _.get(action, 'sendCachedRequest', false)
    ) {
        // resend stored navigation action
        console.warn("resend stored navigation action")
        return next(_.clone(state.common.storedUrlChangeRequest))
    } else if (action.type === "@@router/CALL_HISTORY_METHOD") {
        document.querySelector("#root").scrollTo(0,0)
        return next(action)
    } else {
        return next(action)
    }
}