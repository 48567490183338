import { defaultState } from '../middlewares/responseDataProcessor'

export default (state = {
    data: defaultState,
    initializedRead: false
}, action) => {

    switch (action.type) {

        case "site-globals/READ_FULFILLED":
            return { ...state, data: action.payload.data, initializedRead: true }

        case "site-globals/RESET_INIT":
            return { ...state, initializedRead: false }

        default:
            return { ...state }
    }
}