import { Component } from 'react'
import { connect } from 'react-redux'

import { enableUrlChange, resetAttemptUrlChange } from '../../actions/common'


class UrlChangeListener extends Component {

    componentDidUpdate = prevProps => {
        if (! this.props.urlChangeEnabled && this.props.urlChangeAttempt) {
            if (typeof this.props.onAttemptUrlChange === 'function') {
                this.props.onAttemptUrlChange()
            }
            this.props.dispatch(resetAttemptUrlChange())
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(enableUrlChange(false))
    }

    render = () => null
}

export default connect(store => ({
    urlChangeAttempt: store.common.urlChangeAttempt,
    urlChangeEnabled: store.common.urlChangeEnabled
}))(UrlChangeListener)