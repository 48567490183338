import moment from 'moment'
import _ from "lodash";

export const convertToCanonical = str => {
    if (typeof str === 'string' && str.length) {
        const ret = str
            .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .replace(/[^\w\sÁÍŰŐÜÖÚÓÉáíűőüöúóé]/, "")

        if (ret.length) {
            return ret
                .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
                .map(x => x.toLowerCase())
                .join('-')
        } else {
            return ret
        }
    } else {
        return ""
    }
}

export const dateTimeFormat = "YYYY-MM-DDTHH:mm:ss"
export const toDatetimeString = date => moment(date).format(dateTimeFormat)

export const dateTimeHumanReadableFormat = "YYYY.MM.DD HH:mm"
export const dateTimeFormatToHumanReadable = date => moment(date).format(dateTimeHumanReadableFormat)

export const baseUrlCopyBoardRegex = str => str.replace(/^(.+[^/])[/]*$/, "$1/")

export const safeParseJSON = (message, retVal = null, errorCallback = () => { }) => {
    try {
        return JSON.parse(message)
    } catch (e) {
        errorCallback(e)
        return retVal
    }
}

export const mapTreeNode = (mapFunc, tree) => (
    tree.map((item, index, array) => {
        const nodes = _.get(item, 'nodes', []).length
            ? mapTreeNode(mapFunc, item.nodes)
            : []
        return Object.assign({}, mapFunc(item, index, array), { nodes })
    })
)

export const addImageClickEventListener = (parentSelector = "", urlList = [], callback = () => {}) => {
    urlList.forEach(url => {
        const images = document.querySelectorAll(`${parentSelector} img[src="${url}"]`)
        images.forEach(img => {
            img.addEventListener("click", callback)
        })
    })
}

export const removeImageClickEventListener = (parentSelector = "", urlList = [], callback = () => {}) => {
    urlList.forEach(url => {
        const images = document.querySelectorAll(`${parentSelector} img[src="${url}"]`)
        images.forEach(img => {
            img.removeEventListener("click", callback)
        })
    })
}