import React, { Component } from 'react'

import CKEditor from 'ckeditor4-react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { ckeditorSourcePath } from '../../index'



class CKEditorWrapper extends Component {

    constructor(props) {
        super(props)

        CKEditor.editorUrl = ckeditorSourcePath
    }


    render = () => {

        const data = _.get(this.props, 'data', "<p></p>")

        return (
            <CKEditor
                type={"classic"}
                data={data}
                onChange={ ( event ) => {
                    if (typeof this.props.onChange === 'function') {
                        this.props.onChange(event, event.editor)
                    }
                } }
                onBlur={ ( event ) => {
                    if (typeof this.props.onBlur === 'function') {
                        this.props.onBlur(event, event.editor)
                    }
                } }
                />
        )
    }
}
CKEditorWrapper.propTypes = {
    data: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
}


export default CKEditorWrapper