import React from 'react'
import { SimpleBox } from "./SimpleBox"

export default (
    {
        items = []
    }) => {

    return (
        <div className={"box-container"}>
            {items.map((i, idx) => (
                <SimpleBox key={idx} {...i} />
            ))}
        </div>
    )
}