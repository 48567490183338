import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import axios from 'axios'
import _ from 'lodash'
import ReactGA from 'react-ga'
import { CookiesProvider } from 'react-cookie'

import createStore, { history } from './store.js'

import Login from './pages/Login'
import DynamicContent from './pages/DynamicContent'

import hu from 'date-fns/locale/hu'
import { registerLocale, setDefaultLocale } from  "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"



// datepicker localization
registerLocale('hu', hu)
setDefaultLocale('hu')

// api url
export const baseUrl = _.get(process.env, 'REACT_APP_API_BASEPATH', "")
axios.defaults.baseURL = baseUrl

export const clientId = "66847378523-6pj6hm6j9s2gcq46ig7cu3nricrrobp5.apps.googleusercontent.com"

export const loaderMinDuration = _.get(process.env, "REACT_APP_LOADER_MIN_DURATION", 500)

export const ckeditorSourcePath = _.get(process.env, "REACT_APP_CKEDITOR_SOURCE", "https://staging.ordogkatlan.hu/ckfinder/ckeditor/ckeditor.js")

export const connectorPath = _.get(process.env, 'REACT_APP_CKFINDER_CONNECTOR_PATH', "https://staging.ordogkatlan.hu/ckfinder/connector.php")
export const analyticsId = _.get(process.env, 'REACT_APP_ANALYTICS_ID', "UA-22624515-3")

ReactGA.initialize(analyticsId)

export const store = createStore()


ReactDOM.render((
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <CookiesProvider>
                <App>
                    <Switch>
                        <Route exact path="/x/editor/login" component={Login} />
                        <Route component={DynamicContent} />
                    </Switch>
                </App>
            </CookiesProvider>
        </ConnectedRouter>
    </Provider>
), document.getElementById('root'))

serviceWorker.register()