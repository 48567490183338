import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import '../../styles/edit-visitors.scss'

import {
    clearContentData,
    searchVisitors,
    unprint,
    sendPushText
} from "../../actions/common"
import {EditTitle} from "../utils/common";
import InnerHtml from '../utils/InnerHtmlWrapper'
import toast, { Toaster } from 'react-hot-toast';
import addIcon from "../../assets/icons/add.svg";


class EditVisitorsDebug extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null,
            searchCode: "",
            pushText: "",
            unprinted: [],
            opened: []         
        }
    }
    
    componentDidUpdate = prevProps => {
        const { data } = this.props
        const stateData = this.state.data

        if ((stateData === null && data.length) || (! _.isEqual(data, prevProps.data))) {
            this.setState({
                data: data
            })
        }

    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }
    
    refreshContent = (visitorId) => {
        this.props.dispatch(searchVisitors(visitorId)).then((resp) => {
            if(resp.value.data.data === null) {
                {
                    toast(
                        'Nincs ilyen felhasználó', {
                            duration: 2000,
                            position: 'center-right',
                        }
                    )
                }
            }
            this.setState({ data: resp.value.data.data })
        })
    }

    sendPush = (pushText, uuid) => {
        this.props.dispatch(sendPushText(pushText, uuid)).then((resp) => {
            this.setState({ pushText: "" })
            toast(
                'Üzenet elkülve', {
                    duration: 2000,
                    position: 'center-right',
                }
            )
        })
    }

    renderSearchInput = () => {
        return (
            <input
                type="text"
                onChange={
                    (e) => {
                        this.setState({ searchCode: e.target.value })
                    }
                }
            />
        )
    }
    renderPushInput = () => {
        return (
            <input
                type="text"
                value={(this.state.pushText === null ? "" : this.state.pushText)}
                onChange={
                    (e) => {
                        this.setState({ pushText: e.target.value })
                    }
                }
            />
        )
    }

    renderSearchbox = () => {
        return (
            <div className={"searchbox"}>
                <div className={"searchbox-labels"}>
                    {this.renderSearchInput()}
                    <button
                        onClick={() => this.refreshContent(this.state.searchCode)}
                        className={"button green"}
                    >Keresés</button>
                </div>
            </div>
        )
    }

    renderPushbox = (uuid) => {
        return (
            <div className={"pushbox"}>
                <div className={"pushbox-labels"}>
                    {this.renderPushInput()}
                    <button
                        onClick={() => this.sendPush(this.state.pushText, uuid)}
                        className={"button green"}
                    >Küldés</button>
                </div>
            </div>
        )
    }

    renderContent = () => {

        if(this.state.data !== null) {
            return (
                <>
                    <div className={"content"}>
                        <Toaster
                        toastOptions={{
                            className: '',
                            style: {
                                border: '1px solid #713200',
                                color: '#713200',
                                boxShadow: 'none',
                            },
                        }} />
                    </div>
                    <div className={"visitor"}>
                        <div className={"visitor-id"}>
                            <ul class="visitorBaseElements">
                                <li><label>id:</label> {this.state.data.visitor.visitorId}</li>
                                <li><label>verzió:</label> {this.state.data.visitor.version}</li>
                                <li><label>diszkvalfikált:</label> {this.state.data.visitor.isDisqualified ? 'igen' : 'nem'}</li>
                                <li><label>utoljára használta:</label> {this.state.data.visitor.lastSeen}</li>
                                <li><label>push token:</label> {this.state.data.visitor.pushArn}</li>
                            </ul>
                        </div>
                        
                    </div>
                    <div className={"visitor"}>
                        <b>
                            Push notification küldés:
                        </b>
                        <br />
                        {this.renderPushbox(this.state.data.visitor.visitorId)}
                        <hr></hr>
                    </div>
                    <div className={"visitor"}>
                        <div class="table-responsive">
                            <table class="table visitorCodes">
                                <thead>
                                <tr>
                                    <th>rendszám</th>
                                    <th>használt</th>
                                    <th>létrehozva</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.data.codes.map((code, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{code.code}</td>
                                            <td>{code.isUsed ? 'igen' : 'nem'}</td>
                                            <td>{code.created}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            <hr></hr>
                            <h3>Aktuális kívánságlista:</h3>
                            {
                                
                                this.state.data.wishes.length === 0
                                    ? <tr><td colSpan="4">Nincs aktuális kívánság</td></tr>
                                    : <div class="table-responsive">
                                        <table class="table visitorWishes">
                                            <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>prioritás</th>
                                                <th>székek</th>
                                                <th>állapot</th>
                                                <th>előadás</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.data.wishes.map((wish, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {
                                                                wish.state === "retrieved" && this.state.unprinted.indexOf(index) === -1
                                                                    ? <button
                                                                            onClick={
                                                                                () => {                    
                                                                                    this.props.dispatch(unprint(wish.wishId)).then((resp) => {
                                                                                        this.setState({unprinted: [...this.state.unprinted, index]})
                                                                                    })
                                                                                }
                                                                            }
                                                                            className={"button green"}
                                                                        >Lerontás</button>
                                                
                                                                    : <InnerHtml html={"&nbsp;"} />
                                                            }
                                                        </td>
                                                        <td>{wish.priority}</td>
                                                        <td>{wish.seats}</td>
                                                        <td>{wish.state}</td>
                                                        <td>
                                                            {wish.play.isCanceled ? <InnerHtml html={"<del>"} /> : ''}<span>{wish.play.title}</span>{wish.play.isCanceled ? <InnerHtml html={"<del>"} /> : ''}
                                                            {wish.play.isCanceled ? <InnerHtml html={"<del>"} /> : ''}<span>{wish.play.location} - {wish.play.start}</span>{wish.play.isCanceled ? <InnerHtml html={"<del>"} /> : ''}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                            }
                            
                            <hr></hr>
                            <h3>Lejárt kívánságok:</h3>
                            {
                                this.state.data.rest.length === 0
                                    ? <tr><td colSpan="4">Nincs lejárt kívánság</td></tr>
                                    : <div class="table-responsive">
                                        <table class="table visitorPastWishes">
                                            <thead>
                                            <tr>
                                                <th>prioritás</th>
                                                <th>székek</th>
                                                <th>állapot</th>
                                                <th>előadás</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.data.rest.map((wish, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{wish.priority}</td>
                                                        <td>{wish.seats}</td>
                                                        <td>{wish.state}</td>
                                                        <td>
                                                            {wish.play.isCanceled ? <InnerHtml html={"<del>"} /> : ''}<span>{wish.play.title}</span>{wish.play.isCanceled ? <InnerHtml html={"<del>"} /> : ''}
                                                            {wish.play.isCanceled ? <InnerHtml html={"<del>"} /> : ''}<span>{wish.play.location} - {wish.play.start}</span>{wish.play.isCanceled ? <InnerHtml html={"<del>"} /> : ''}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                            }
                            <hr></hr>
                            <h3>Előzmények:</h3>
                            {
                                this.state.data.history.length === 0
                                    ? <tr><td colSpan="4">Nincs előzmény</td></tr>
                                    : <div class="table visitorHistory">
                                        {this.state.data.history.map((history, index) => {
                                            return (
                                                <div key={index}>
                                                    <hr></hr>
                                                    {history.at} - {history.action} {history.details ? `- (${history.details})` : ''}
                                                    <img
                                                        className={this.state.opened.indexOf(index) === -1 ? 'addIcon' : 'addIcon hidden' }
                                                        src={addIcon}
                                                        alt="json"
                                                        onClick={() => this.setState({opened: [...this.state.opened, index]})} />
                                                    <br />
                                                    {this.state.opened.indexOf(index) === -1 ? '' : history.data}
                                                </div>
                                            )
                                        })}
                                    </div>
                            }

                        </div>
                        
                    </div>
                </>
            )
        }

        return (
            <div className={"content"}>
                <Toaster
                toastOptions={{
                    className: '',
                    style: {
                        border: '1px solid #713200',
                        color: '#713200',
                        boxShadow: 'none',
                    },
                }} />
            </div>
        )
    }

    render = () => {
        
        return (
            <>
                <div className="search read">
                    <EditTitle>Látogató keresése</EditTitle>
                    <br />
                    {this.renderSearchbox()}
                    <hr></hr>
                </div>
                <div className={"content"}>
                    { this.renderContent() }
                </div>
            </>

        )
    }
}



export default connect(store => {
    return {
        data: store.base.data
    }
})(EditVisitorsDebug)
