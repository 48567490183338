import React, { Component, useState } from 'react'
import TreeMenu from 'react-simple-tree-menu'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { mapTreeNode, safeParseJSON } from "../utils/common"
import AutoSuggest from 'react-autosuggest'
import axios from "axios";
import { isBrowser } from "react-device-detect"





const defaultNode = (key, name = 'no name') => ({
    key: key,
    label: name,
    url: null,
    nodes: [],
    openNodeId: null
})



const menuKeyToSelector = key => (
    key.split("/").reduce((acc, curr, index) => index <= 0 ? `[${curr}]` : `${acc}['nodes'][${curr}]`, "")
)

const getParentSelector = selector => selector.replace(/^(.+)\['nodes'\]\[\d+\]$/, "$1")


class MenuEditor extends Component {


    constructor(props) {
        super(props)

        this.state = {
            maxLevel: Number.isInteger(props.maxLevel) ? props.maxLevel : 0,
            data: this.postProcess(safeParseJSON(props.data || '[]', [])),
            openNodeId: null
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { onChange } = this.props
        const { openNodeId } = this.state
        const data = _.get(this, 'state.data', [])

        if (openNodeId !== null && prevState.openNodes === null) {
            this.setState({ openNodeId: null })
        }

        if (onChange) {
            onChange(JSON.stringify(data))
        }
    }


    postProcess = list => {
        return mapTreeNode(
            (item, idx, currArray) => {
                const origLabel = item.label
                return {
                    ...item,
                    origLabel: origLabel,
                    key: `${idx}`,
                    realIndex: idx,
                    isFirstNode: idx === 0,
                    isLastNode: idx + 1 >= currArray.length
                }
            },
            list
        )
    }


    createMenu = (parentNodeKey = null) => e => {

        const data = _.clone(_.get(this, 'state.data', []))

        if (parentNodeKey === null) {
            this.setState({
                data: this.postProcess([...data, defaultNode(`${data.length}`)])
            })
        } else {
            const selector = menuKeyToSelector(parentNodeKey) + ['nodes']
            const nodes = _.get(data, selector, [])
            const newNodes = [...nodes, defaultNode(`${nodes.length}`)]

            this.setState({
                data: this.postProcess(_.set(data, selector, newNodes)),
                openNodeId: parentNodeKey
            })

        }
    }


    changeMenuUrl = (nodeKey, url) => {
        const data = _.clone(this.state.data)
        const selector = menuKeyToSelector(nodeKey)
        const node = _.get(data, selector, null)
        if (node !== null) {
            this.setState({
                data: _.set(data, selector, {...node, url: url})
            })
        }
    }


    changeMenuLabel = nodeKey => e => {
        e.preventDefault()
        const data = _.clone(this.state.data)
        const selector = menuKeyToSelector(nodeKey)
        const node = _.get(data, selector, null)
        if (node !== null) {
            this.setState({
                data: _.set(data, selector, {...node, label: e.target.value})
            })
        }
    }


    deleteMenu = nodeKey => e => {
        e.preventDefault()
        if (window.confirm("Biztos törlöd?")) {

            const data = _.clone(this.state.data)
            const selector = menuKeyToSelector(nodeKey)
            const parentSelector = getParentSelector(selector)
            const nodeIndex = parseInt(selector.replace(/.*\[(\d+)\]$/, "$1"), 10)

            if (selector !== parentSelector) {
                const parentNode = _.get(data, parentSelector, null)
                if (parentNode !== null) {
                    const newNodes = [...parentNode.nodes.slice(0, nodeIndex), ...parentNode.nodes.slice(nodeIndex + 1)]
                    this.setState({
                        data: this.postProcess(_.set(data, parentSelector + "['nodes']", newNodes))
                    })
                } else {
                    console.warn("MenuEditor::deleteMenu parentNode is null")
                }
            } else {
                this.setState({
                    data: this.postProcess([...data.slice(0, nodeIndex), ...data.slice(nodeIndex + 1)])
                })
            }

        }
    }

    moveMenu = (nodeKey, toIndex) => e => {
        e.preventDefault()

        const data = _.clone(this.state.data)
        const selector = menuKeyToSelector(nodeKey)
        const fromIndex = parseInt(selector.replace(/.*\[(\d+)\]$/, "$1"), 10)
        const parentSelector = getParentSelector(selector)

        const switchItems = (minIndex, maxIndex) => (acc, curr, index, currArray) => {
            if (index === minIndex) {
                return [...acc, { ..._.get(currArray, [maxIndex], {}), key: curr.key }]
            } else if (index === maxIndex) {
                return [...acc, { ..._.get(currArray, [minIndex], {}), key: curr.key }]
            } else {
                return [...acc, curr]
            }
        }

        if (selector !== parentSelector) {
            const parentNode = _.get(data, parentSelector, null)
            if (parentNode !== null) {
                const newNodes = this.postProcess(parentNode.nodes.reduce(switchItems(Math.min(fromIndex, toIndex), Math.max(fromIndex, toIndex)), []))
                this.setState({
                    data: this.postProcess(_.set(data, parentSelector + "['nodes']", newNodes))
                })
            } else {
                console.warn("MenuEditor::moveMenu parentNode is null")
            }
        } else {
            this.setState({
                data: this.postProcess(data.reduce(switchItems(Math.min(fromIndex, toIndex), Math.max(fromIndex, toIndex)), []))
            })
        }
    }


    render = () => {

        const data = _.get(this, 'state.data', [])
        const maxLevel = _.get(this, 'state.maxLevel', 0)
        const label = _.get(this, 'props.label', "")

        return (
            <div>
                { label && <label>{label}</label> }
                <TreeMenu
                    hasSearch={false}
                    data={data}
                    disableKeyboard={true}
                    initialOpenNodes={[]}
                    debounceTime={125}>
                    {({ items }) => {
                        return (
                            <>
                            <div className={"add-submenu-container"}>
                                <div>{_.get(this, 'props.addMainMenuLabel', '')}</div>
                                <div onClick={this.createMenu(null)} className={"icon add-submenu"} />
                            </div>
                            <ul className={"edit-menu-container"}>
                                {items.map(({key, ...props}, index) => {
                                    const forceOpen = this.state.openNodeId === key && ! props.openNodes.includes(key)
                                    return (
                                        <MenuItem
                                            {...props}
                                            forceOpen={forceOpen}
                                            key={key}
                                            index={index}
                                            nodeKey={key}
                                            createMenuClick={this.createMenu}
                                            onLabelChange={this.changeMenuLabel}
                                            onUrlChange={this.changeMenuUrl}
                                            onMenuDelete={this.deleteMenu}
                                            onMenuMove={this.moveMenu}
                                            maxLevel={maxLevel}
                                        />
                                    )
                                })}
                            </ul>
                            </>
                        )
                    }}
                </TreeMenu>
            </div>
        )
    }
}

const MenuItem = ({
    hasNodes = false,
    isOpen = false,
    level = 0,
    onClick,
    forceOpen,
    toggleNode,
    active,
    focused,
    maxLevel,
    isFirstNode,
    isLastNode,
    realIndex,
    openedIcon = '-',
    closedIcon = '+',
    label = '',
    index = 0,
    nodeKey = "",
    url = "",
    createMenuClick = () => {},
    onLabelChange = () => {},
    onUrlChange = () => {},
    onMenuDelete = () => {},
    onMenuMove = () => {}}) => {

    if (forceOpen && ! isOpen && hasNodes && toggleNode) {
        toggleNode()
    }

    const openClass = hasNodes ?
        (isOpen ? 'icon opened' : 'icon closed')
        : ""

    const [ suggestions, setSuggestions ] = useState([])

    const onSuggestionsFetchRequested = async ({ value }) => {
        const result = value.length >= 3 ? await getUrlSuggestions(value) : []
        setSuggestions(result)
    }

    return (
        <li style={{zIndex: 10000 - index, position: 'relative'}} onClick={onClick} key={index} className={`menu-item level-${level}`}>

            <div className={"container"}>

                <div className={`left level-${level}`} />
                <div className={`right level-${level}`}>
                    <table><tbody>
                    <tr>
                        <td
                            className={`icon move-up ${!isFirstNode ? "" : "disabled"}`}
                            onClick={e => {
                                e.preventDefault()
                                if (! isFirstNode)
                                    onMenuMove(nodeKey, realIndex - 1)(e)
                            }}
                        >
                        </td>
                        <td className={`label`} colSpan={2}>menüpont</td>
                        <td className={"auto-width"}>
                            <input type="text" value={label} onChange={onLabelChange(nodeKey)} />
                        </td>
                        <td
                            className={`icon remove`}
                            onClick={onMenuDelete(nodeKey)}
                        >
                        </td>
                    </tr>
                    <tr>
                        <td
                            className={`icon move-down ${!isLastNode ? "" : "disabled"}`}
                            onClick={e => {
                                e.preventDefault()
                                if (! isLastNode)
                                    onMenuMove(nodeKey, realIndex + 1)(e)
                            }}
                            >
                        </td>
                        <td className={'label'} colSpan={2}>url</td>
                        <td className={"auto-width"}>
                            <AutoSuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={() => setSuggestions([])}
                                onSuggestionSelected={(event, { suggestion }) => {
                                    event.preventDefault()
                                    onUrlChange(nodeKey, suggestion.href || "")
                                }}
                                getSuggestionValue={suggestion => suggestion.href}
                                renderSuggestion={renderSuggestion}
                                renderSuggestionsContainer={renderSuggestionsContainer}
                                alwaysRenderSuggestions={false}
                                shouldRenderSuggestions={(value) => {
                                    return value.trim().length >= 3
                                }}
                                inputProps={{
                                    type: "text",
                                    disabled: hasNodes,
                                    value: url ? url : "",
                                    onChange: e => {
                                        e.preventDefault()
                                        onUrlChange(nodeKey, e.target.value)
                                    }
                                }} />

                            {/*
                            <input type="text" disabled={hasNodes} value={url ? url : ""} onChange={onUrlChange(nodeKey)} />
                            */}
                        </td>
                        <td />
                    </tr>
                    {maxLevel > level &&
                    <tr>
                        <td />
                        <td
                            className={openClass}
                            onClick={e => {
                                e.preventDefault()
                                if (hasNodes && toggleNode)
                                    toggleNode(e)
                            }}>
                        </td>
                        <td
                            className={`icon add-submenu ${maxLevel > level ? "" : "hidden"}`}
                            onClick={e => {
                                e.preventDefault()
                                if (maxLevel > level)
                                    createMenuClick(nodeKey)(e)
                            }}
                        >
                        </td>
                        <td colSpan="2" />
                    </tr>
                    }
                    </tbody></table>
                </div>

            </div>
        </li>
    )
}

const getUrlSuggestions = (value) => {
    return axios.get(`/api/v1.0/ac.search`, { params: { class: "menu", q: value }}).then(r => r.data, () => [])
}

const renderSuggestion = suggestion => {
    const thumbnail = isBrowser
        ? _.get(suggestion, 'thumbnail.web', null)
        : _.get(suggestion, 'thumbnail.mobile', null)

    return (
        <div className={`item ${thumbnail !== null ? "with-image" : ""}`}>
            { thumbnail !== null &&
            <div className={"image"}><img src={thumbnail} alt={suggestion.title} /></div>
            }
            <div className={"suggestion-title"}>
                <div>{suggestion.title || ""} <b>({suggestion.href || ""})</b></div>
            </div>

        </div>
    )
}

const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
        <>
            <div {...containerProps}>
                <div className={"drop-shadow"}>
                    {children}
                </div>
            </div>
        </>
    );
}


MenuEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    data: PropTypes.string.isRequired
}


export default MenuEditor
