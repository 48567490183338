import React, { Component } from 'react'
import { connect } from 'react-redux'
import { safeParseJSON } from "../utils/common"
import _ from 'lodash'

import { MenuLink } from './utils/common'

class FooterMenu extends Component {


    render = () => {
        const data = _.get(this, 'props.data', [])
        // console.log('data', data)
        return (
            <div>
                {data.map((m, idx) => {
                    return (
                        <ul className="footerMenuList">
                            <li>
                                <MenuLink
                                    key={((idx + 1) * 100)}
                                    dispatch={this.props.dispatch}
                                    href={m.url || ""}
                                >{m.label || ""}</MenuLink>
                            </li>
                            {m.nodes.map((m2, idx2) => {
                                return (
                                    <li>
                                        <MenuLink
                                            key={parseInt(((idx + 1) * 100) + 1 + idx2)}
                                            dispatch={this.props.dispatch}
                                            href={m2.url || ""}
                                        >{m2.label || ""}</MenuLink>
                                    </li>
                                )
                            })}
                        </ul>
                    )
                })}
            </div>
        )
    }
}

export default connect(store => {
    return {
        data: safeParseJSON(_.get(store, 'site.data.data.bottomMenu', '[]'), [])
    }
})(FooterMenu)
