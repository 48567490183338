import { defaultState } from '../middlewares/responseDataProcessor'

export default (state = defaultState, action) => {

    switch (action.type) {
        case "content/EDIT_FULFILLED":
        case "content/READ_FULFILLED":
        case "editors/EDIT_FULFILLED":
        case "site-globals/EDIT_FULFILLED":
            return Object.assign({}, action.payload.data)

        case "content/GET_HISTORY_FULFILLED":
            return { ...state, history: action.payload.data }

        case "base/CLEAR_DATA":
            return {...defaultState}

        default:
            return {...state}
    }

}