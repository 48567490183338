export default function reducer (state = {
    visible: false,
    pending: {}
}, action = {}) {
    if (action.hasOwnProperty('preloader')) {
        const prevPending = state.pending
        let pending = {...prevPending}
        pending[action.preloader.eventName] = action.preloader.enablePreloader
        return {...state, visible: action.preloader.enablePreloader, pending: pending}
    } else {
        return {...state}
    }
}
