import React, { Component } from 'react'
import { connect } from 'react-redux'
import TreeMenu from 'react-simple-tree-menu'
import { push } from 'connected-react-router'

import {mapTreeNode, safeParseJSON} from "../utils/common"
import _ from "lodash"
import {resetCloseMobileMenu} from "../actions/common";

class MainMenuMobile extends Component {


    constructor(props) {
        super(props)

        this.state = {
            rawData: [],
            data: [],
            opened: false
        }
    }

    postProcessNodes = nodes => (
        mapTreeNode(
            (item, idx, currArray) => ({
                ...item,
                isFirstNode: idx === 0,
                isLastNode: idx + 1 >= currArray.length
            }),
            nodes
        )
    )

    componentDidUpdate = (prevProps) => {

        const { data } = this.props

        if (! _.isEqual(this.state.rawData, data)) {
            this.setState({
                rawData: data,
                data: this.postProcessNodes(data)
            })
        }

        if (this.props.closeMobileMenu && ! prevProps.closeMobileMenu) {
            this.setState({ opened: false })
            this.props.dispatch(resetCloseMobileMenu())
        }
    }

    menuClick = resetCallback => url => {
        if (/^https?:/.test(url)) {
            document.location = url
        } else {
            this.props.dispatch(push(url))
        }
        if (resetCallback) resetCallback()
        this.toggleMenu()
    }

    toggleMenu = () => {
        this.setState({ opened: ! this.state.opened })
    }

    render = () => {

        const { opened } = this.state

        const openedClass = opened ? "opened" : ""

        return (
            <TreeMenu
                data={this.state.data}
                hasSearch={false}
                disableKeyboard={true} >
                {({ items, resetOpenNodes }) => {

                    return (
                        <>
                            <div onClick={this.toggleMenu} className={`hamburger pointer ${openedClass}`}>
                                <span />
                                <span />
                                <span />
                            </div>

                            <div className={`overlay ${openedClass}`}>
                                { this.props.searchElement && this.props.searchElement }
                                <div className={"container"}>
                                    <ul className={""}>
                                        { items.map((props, index) => {
                                            return (
                                                <MenuItem
                                                    {...props}
                                                    onClick={this.menuClick(resetOpenNodes)}
                                                    index={index} />
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>

                        </>
                    )
                }}
            </TreeMenu>
        )
    }
}

export default connect(store => {
    return {
        closeMobileMenu: store.common.closeMobileMenu,
        data: safeParseJSON(_.get(store, 'site.data.data.topMenu', '[]'), [])
    }
})(MainMenuMobile)

const MenuItem = ({
    index,
    hasNodes = false,
    isOpen = false,
    level = 0,
    toggleNode,
    label = "",
    url = null,
    onClick = () => {}
}) => {

    const nodeClassName = hasNodes
        ? (isOpen ? "opened arrow" : "closed arrow")
        : ""

    return (
        <li
            className={`level-${level} ${nodeClassName}`}
            key={index}
            onClick={() => {
                if (hasNodes && toggleNode) toggleNode()
                else if (url) onClick(url)
            }}
        >{ url
            ? (<a href={url}>{label}</a>)
            : (<>{label}</>)
        }</li>
    )
}