import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"

import { clearContentData, updateSearchText } from "../../actions/common"
import InnerHtml from "../utils/InnerHtmlWrapper"
import { isBrowser } from "react-device-detect"
import { ColoredLabel } from "../utils/common"
import { replace, push } from "connected-react-router"

import "../../styles/adv-search.scss"
import "../../styles/tag.scss"
import statusCanceled from "../../assets/icons/prod-canceled.svg"
import statusTicketed from "../../assets/icons/prod-ticketed.svg"
import statusChanged from "../../assets/icons/prod-changed.svg"

import { loaderMinDuration } from "../../index"
import LoadingBar from "react-top-loading-bar"

class DetailedProgramming extends Component {
  state = {
    progress: 0,
  }

  componentWillUnmount = () => {
    this.props.dispatch(clearContentData())
  }

  processProductions = (prods = []) => {
    return prods
  }

  renderLabels = (item, idx) => {
    return (
      <ColoredLabel
        key={idx}
        onClick={this.navigateTo(item.href)}
        foregroundColor={item.featuredColor.foreground}
        backgroundColor={item.featuredColor.background}
      >
        <a href={item.href}>{item.name}</a>
      </ColoredLabel>
    )
  }

  navigateTo = (href) => (e) => {
    e.preventDefault()
    if (href && href.length) this.props.dispatch(push(href))
  }

  renderSearchboxLabel = (idx, item, selected = true) => {
    const color = selected
      ? item.featuredColor
        ? item.featuredColor
        : { foreground: "rgb(44, 22, 8)", background: "rgb(255, 153, 0)" }
      : { foreground: "#000000", background: "#ffffff" }
    return (
      <ColoredLabel
        key={idx}
        onClick={this.navigateSearchTo(item.id, "tag")}
        foregroundColor={color.foreground}
        backgroundColor={color.background}
      >
        {item.name}
      </ColoredLabel>
    )
  }

  renderTimeSelector = () => {
    return (
      <select
        className={"vertical-middle"}
        defaultValue={this.props.data.controls.time.selected}
        onChange={(e) => {
          this.navigateSearchTo(e.target.value, "time")()
        }}
      >
        {this.props.data.controls.time.values.map((item, idx) => (
          <option key={idx} value={item}>
            {item}
          </option>
        ))}
      </select>
    )
  }

  navigateSearchTo = (itemId, type) => (e) => {
    this.setState({
      progress: 50,
    })
    const daysUrl = this.props.data.controls.days
      .filter(
        (item) =>
          (item.selected && item.id != itemId) ||
          (!item.selected && item.id === itemId)
      )
      .map((item) => item.id)
    const genresUrl = this.props.data.controls.genres
      .filter(
        (item) =>
          (item.selected && item.id != itemId) ||
          (!item.selected && item.id === itemId)
      )
      .map((item) => item.id)
    const villagesUrl = this.props.data.controls.villages
      .filter(
        (item) =>
          (item.selected && item.id != itemId) ||
          (!item.selected && item.id === itemId)
      )
      .map((item) => item.id)
    const stagesUrl = this.props.data.controls.stages
      .filter(
        (item) =>
          (item.selected && item.id != itemId) ||
          (!item.selected && item.id === itemId)
      )
      .map((item) => item.id)

    const url = `${daysUrl.length ? "/" : ""}${daysUrl.join("/")}${
      genresUrl.length ? "/" : ""
    }${genresUrl.join("/")}${villagesUrl.length ? "/" : ""}${villagesUrl.join(
      "/"
    )}${stagesUrl.length ? "/" : ""}${stagesUrl.join("/")}`

    if (type === "time") {
      this.props.dispatch(
        push(
          "/x/detailed-programming" +
            url +
            "?from=" +
            itemId
        )
      )
    }
    if (type === "tag") {
      this.props.dispatch(
        push(
          "/x/detailed-programming" +
            url +
            "?from=" +
            this.props.data.controls.time.selected
        )
      )
    }

    setTimeout(() => {
      this.state.progress < 100 &&
        this.state.progress !== 0 &&
        this.setState({ progress: 100 })
    }, loaderMinDuration)
  }

  renderSearchbox = () => {
    return (
      <div className={"searchbox"}>
        <div className={"searchbox-labels"}>
          <div className={"row"}>
            <div className={"half left"}>
              <h2>Napok</h2>
              <div className={"labels"}>
                {this.props.data.controls.days.map((item, idx) =>
                  this.renderSearchboxLabel(idx, item, item.selected)
                )}
              </div>
              <h2 className={"inline"}>Időpont</h2>
              {this.renderTimeSelector()}
              <br />
              <h2>Műfajok</h2>
              <div className={"labels"}>
                {this.props.data.controls.genres.map((item, idx) =>
                  this.renderSearchboxLabel(idx, item, item.selected)
                )}
              </div>
            </div>
            <div className={"half right"}>
              <h2>Falvak</h2>
              <div className={"labels"}>
                {this.props.data.controls.villages.map((item, idx) =>
                  this.renderSearchboxLabel(idx, item, item.selected)
                )}
              </div>
              {this.props.data.controls.stages.length > 0 && (
                <>
                  <h2>Helyszínek</h2>
                  <div className={"labels"}>
                    {this.props.data.controls.stages.map((item, idx) =>
                      this.renderSearchboxLabel(idx, item, item.selected)
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderItem = (item, idx) => {
    const thumbnail = isBrowser
      ? _.get(item, "thumbnail.web", null)
      : _.get(item, "thumbnail.mobile", null)

    const tags = _.get(item, "tags", [])

    const href = _.get(item, "href", "")

    const isPartial = _.get(item, "partial", false)
    const isDraft = _.get(item, "draft", false)

    const withAnchor = (href, content) => <a href={href}>{content}</a>

    const maybeTimeHref = _.get(item, "time.href", "")
    const maybeTime = _.get(item, "time.name", undefined)

    const maybeTitleHref = item.href
    const maybeTitle = item.title

    const maybeStageHref = _.get(item, "stage.href", "")
    const maybeStage = _.get(item, "stage.name", undefined)

    const maybeVillageHref = _.get(item, "village.href", "")
    const maybeVillage = _.get(item, "village.name", undefined)

    const title = [
      [maybeTitle, maybeTitleHref, true],
      [maybeTime, maybeTimeHref, true],
      [maybeVillage, maybeVillageHref, false],
      [maybeStage, maybeStageHref, false],
    ].map((i, idx, array) => {
      const [maybeName, maybeHref, isBold] = i

      if (maybeName) {
        return (
          <React.Fragment key={idx}>
            <a
              href={maybeHref}
              className={`underline no-underline-hover ${
                isBold ? "bold" : ""
              } ${maybeHref.length > 0 ? "underline pointer" : ""}`}
              onClick={this.navigateTo(maybeHref)}
            >
              {maybeName}
            </a>
            {array[idx + 1] && array[idx + 1][0] && <>&nbsp;- </>}
          </React.Fragment>
        )
      } else {
        const hasValidBefore = () => {
          const maybeContent = array
            .slice(0, idx)
            .find((i) => typeof i[0] !== "undefined")
          return typeof maybeContent !== "undefined"
        }
        return hasValidBefore() ? (
          <React.Fragment key={idx}>
            {array[idx + 1] && array[idx + 1][0] && <>&nbsp;- </>}
          </React.Fragment>
        ) : null
      }
    })

    return (
      <div key={idx} className={"item"}>
        <h3>{title}</h3>
        {maybeTitleHref && (
          <div
            className={"link-icon"}
            onClick={this.navigateTo(maybeTitleHref)}
          />
        )}
        <div className={`container ${thumbnail !== null ? "with-image" : ""}`}>
          {thumbnail !== null && (
            <div className={`image ${href.length ? "pointer" : ""}`}>
              {isPartial && <div className={"exclamation"} />}
              {isDraft && <div className={"stop"} />}
              <img onClick={this.navigateTo(href)} src={thumbnail} alt={""} />
            </div>
          )}
          <div className="info">
            <div className={"description"}>
              <InnerHtml html={_.get(item, "description", "")} />
            </div>
            <div className={"tags"}>
              {(item.labels || []).map(this.renderLabels)}
            </div>
            <div className={"tags"}>{(tags || []).map(this.renderLabels)}</div>
            <div className={"tags"}>
              {item.canceled && (
                <a
                  href={item.canceled.href}
                  className={"prod-status pointer"}
                  onClick={this.navigateTo(item.canceled.href)}
                >
                  <img className={"status-img"} src={statusCanceled} alt={""} />
                </a>
              )}
              {item.ticketed && (
                <a
                  href={item.ticketed.href}
                  className={"prod-status pointer"}
                  onClick={this.navigateTo(item.ticketed.href)}
                >
                  <img className={"status-img"} src={statusTicketed} alt={""} />
                </a>
              )}
              {item.changed && (
                <a
                  href={item.changed.href}
                  className={"prod-status pointer"}
                  onClick={this.navigateTo(item.changed.href)}
                >
                  <img className={"status-img"} src={statusChanged} alt={""} />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    const { prequel, sequel } = this.props.data

    const processedProductions = this.processProductions(
      this.props.data.centerpiece
    )

    const content = !this.props.data.centerpiece.length ? (
      <div>Nincs a keresésnek megfelelő találat</div>
    ) : (
      <>
        {this.props.data.centerpiece.length > 0 && (
          <div className={"found-productions"}>
            {processedProductions.map(this.renderItem)}
          </div>
        )}
      </>
    )

    return (
      <>
        <LoadingBar
          className={"loading-bar"}
          color="#f11946"
          progress={this.state.progress}
          onLoaderFinished={() => {
            this.setState({
              progress: 0,
            })
          }}
        />
        <div className={"prequel"}>
          <InnerHtml html={prequel} />
        </div>

        <div className="search read">{this.renderSearchbox()}</div>

        <div className={"sequel"}>
          <InnerHtml html={sequel} />
        </div>

        <div className="search read">
          <div className={"content"}>{content}</div>
        </div>
      </>
    )
  }
}

export default connect((store) => {
  return {
    location: store.router.location,
    data: store.base.data,
    actions: store.base.actions,
  }
})(DetailedProgramming)
