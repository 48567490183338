import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../styles/preloader.scss'


class Preloader extends Component {

    render() {

        const showPreloader = (p) => {
            return Object.values(p).indexOf(true) !== -1
        }

        if (showPreloader(this.props.pending)) {
            return (
                <div className={`loader-container show`}>
                    <div className="loader" id="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

Preloader = connect((store) => {
    return {
        visible: store.preloader.visible,
        pending: store.preloader.pending || {}
    }
})(Preloader)

export default Preloader