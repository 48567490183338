import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import '../../styles/stats.scss'

import {
    clearContentData,
    getStats
} from "../../actions/common"
import InnerHtml from '../utils/InnerHtmlWrapper'
import refreshIcon from "../../assets/icons/refresh.svg";
import toast, { Toaster } from 'react-hot-toast';


class EditStats extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: this.props.data,
            time: 'today'
        }
    }
    
    componentDidUpdate = prevProps => {
        const { data } = this.props
        const stateData = this.state.data

        if ((stateData === null && data.length) || (! _.isEqual(data, prevProps.data))) {
            this.setState({
                data: data
            })
        }

    }
    
    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    renderContent = () => {
        if(this.state.data !== null) {
            return (
                <>
                    <div className={"stats"}>
                        <h3>{this.state.data.range}</h3>
                    </div>
                    <div className={"stats-table-wrapper"}>
                        <table className={"stats-table"}>
                          <thead class="fixedTop">
                            <tr>
                              <th rowSpan={2}>előadás</th>
                              <th rowSpan={2}>összes hely</th>
                              <th rowSpan={2}>kívánságlistán</th>
                              <th rowSpan={2}>kívánságarány</th>
                              <th rowSpan={2}>infopultnál nyomtatható</th>
                              <th rowSpan={2}>nyerhető</th>
                              <th rowSpan={2}>átvehető</th>
                              <th rowSpan={2}>tiszteletjegyek</th>
                              <th rowSpan={2}>kinyomtatva</th>
                              <th rowSpan={2}>tartalék</th>
                              <th colSpan={4}>felszabadulás</th>
                            </tr>
                            <tr>
                              <th colSpan={2}>első kör</th>
                              <th colSpan={2}>második kör</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.rows.map((row, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{row.name}</td>
                                        <td>{row.totalSeats}</td>
                                        <td>{row.desiredSeats}</td>
                                        <td>{parseFloat(row.interestPerSeat).toFixed(2)}</td>
                                        <td>{row.demandPrintableSeats}</td>
                                        <td>{row.winnableSeats}</td>
                                        <td>{row.takeableSeats}</td>
                                        <td>{row.complimentarySeats}</td>
                                        <td>{row.printed}</td>
                                        <td>{row.leftover}</td>
                                        <td>{row.toBeReleasedAt1}</td>
                                        <td>{row.toBeReleased1}</td>
                                        <td>{row.toBeReleasedAt2}</td>
                                        <td>{row.toBeReleased2}</td>
                                    </tr>
                                )
                            })}

                          </tbody>
                          <tfoot>
                              <td>{this.state.data.summary.name}</td>
                              <td>{this.state.data.summary.totalSeats}</td>
                              <td>{this.state.data.summary.desiredSeats}</td>
                              <td>{parseFloat(this.state.data.summary.interestPerSeat).toFixed(2)}</td>
                              <td>{this.state.data.summary.demandPrintableSeats}</td>
                              <td>{this.state.data.summary.winnableSeats}</td>
                              <td>{this.state.data.summary.takeableSeats}</td>
                              <td>{this.state.data.summary.complimentarySeats}</td>
                              <td>{this.state.data.summary.printed}</td>
                              <td>{this.state.data.summary.leftover}</td>
                              <td>{/* this.state.data.summary.toBeReleasedAt1 */}</td>
                              <td>{/* this.state.data.summary.toBeReleased1 */}</td>
                              <td>{/* this.state.data.summary.toBeReleasedAt2 */}</td>
                              <td>{/* this.state.data.summary.toBeReleased2 */}</td>
                          </tfoot>
                        </table>
                    </div>
                </>
            )
        }

        return (
            <div className={"content"}>
              Nincs megjeleníthető adatunk
            </div>
        )
    }

    render = () => {
        
        return (
            <>
                <div className={"content"}>
                    <h2>Statisztikák</h2>
                    
                    <select
                      className={"vertical-middle"}
                      defaultValue={this.props.data.time}
                      onChange={(e) => {
                        this.setState({ time: e.target.value })
                        this.props.dispatch(getStats(e.target.value)).then((resp) => {
                          this.setState({
                              data: resp.value.data.data
                          })
                      })
                      }}
                    >
                      <option value={"today"}>
                        {"today"}
                      </option>
                      <option value={"2024"}>
                        {"2024"}
                      </option>
                    </select>

                    { this.renderContent() }
                </div>
            </>

        )
    }
}



export default connect(store => {
    return {
        data: store.base.data
    }
})(EditStats)
