import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import '../../styles/messaging.scss'

import {
    clearContentData,
    editMessaging,
    sendMessage
} from "../../actions/common"
import InnerHtml from '../utils/InnerHtmlWrapper'
import refreshIcon from "../../assets/icons/refresh.svg";
import toast, { Toaster } from 'react-hot-toast';


class EditMessaging extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null,
            pushText: "",
            targets: []
        }
    }
    
    componentDidUpdate = prevProps => {
        const { data } = this.props
        const stateData = this.state.data

        if ((stateData === null && data.length) || (! _.isEqual(data, prevProps.data))) {
            this.setState({
                data: data
            })
        }

    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    sendPush = (pushText, targetsArray) => {
        if(pushText === "" || pushText === null) {
            toast(
                'Üres üzenetet nem lehet elküldeni', {
                    duration: 2000,
                    position: 'center-right',
                }
            )
        } else {
            this.props.dispatch(sendMessage(pushText, targetsArray)).then((resp) => {
                this.setState({ pushText: "" })
                toast(
                    'Üzenet elkülve', {
                        duration: 2000,
                        position: 'center-right',
                    }
                )
                this.props.dispatch(editMessaging())
            })
        }
    }

    renderSearchInput = () => {
        return (
            <input
                type="text"
                onChange={
                    (e) => {
                        this.setState({ searchCode: e.target.value })
                    }
                }
            />
        )
    }
    renderPushInput = () => {
        return (
            <input
                type="text"
                value={(this.state.pushText === null ? "" : this.state.pushText)}
                onChange={
                    (e) => {
                        this.setState({ pushText: e.target.value })
                    }
                }
            />
        )
    }

    renderPushbox = () => {
        return (
          <>
            <h3>Üzenetküldés infopultnak</h3>
            <div className={"targets"}>
              <p>
                <b>Címzettek:</b> 
                <span className={"refresh"}
                  onClick={() => {
                    this.setState({targets: this.state.targets.filter(i => i == 'xxx')})
                    this.props.dispatch(editMessaging())
                  }}
                >
                  <img className="add" src={refreshIcon} alt="Címzettek frissítése" />
                </span>
              </p>
              <div className={"checkboxes"}>
                {this.props.data.items.map((item, index) => {
                  return (
                    <label key={index} className={"checkbox"} htmlFor={"canceled"}>
                      <span
                          onClick={() => {
                            this.state.targets.indexOf(item.id) !== -1 ? this.setState({targets: this.state.targets.filter(i => i !== item.id)}) :
                            this.setState({targets: [...this.state.targets, item.id]})
                              // this.modifiyInstance(idx, {...item, canceled: ! item.canceled})
                          }}>{item.name}&nbsp;&nbsp;</span>
                      <div
                          onClick={() => {
                            this.state.targets.indexOf(item.id) !== -1 ? this.setState({targets: this.state.targets.filter(i => i !== item.id)}) :
                            this.setState({targets: [...this.state.targets, item.id]})
                              //this.modifiyInstance(idx, {...item, canceled: ! item.canceled})
                          }}
                          className={`icon ${this.state.targets.indexOf(item.id) ? "unchecked" : "checked"}`}
                          />
                    </label>
                  )
                })}
              </div>
            </div>
            <div className={"pushbox"}>
              <p>
                <b>Üzenet:</b>
              </p>
              <div className={"pushbox-labels"}>
                  {this.renderPushInput()}
                  <button
                      onClick={() => this.sendPush(this.state.pushText, this.state.targets)}
                      className={"button green"}
                  >Küldés</button>
              </div>
            </div>
          </>
        )
    }

    renderContent = () => {
        if(this.props.data !== null) {
            return (
                <>
                    <div className={"messaging"}>
                        <Toaster
                        toastOptions={{
                            className: '',
                            style: {
                                border: '1px solid #713200',
                                color: '#713200',
                                boxShadow: 'none',
                            },
                        }} />

                    </div>
                </>
            )
        }

        return (
            <div className={"content"}>
                <Toaster
                toastOptions={{
                    className: '',
                    style: {
                        border: '1px solid #713200',
                        color: '#713200',
                        boxShadow: 'none',
                    },
                }} />
            </div>
        )
    }

    render = () => {
        
        return (
            <>
                <div className="search read">
                    {this.renderPushbox()}
                </div>
                <div className={"content"}>
                    { this.renderContent() }
                </div>
            </>

        )
    }
}



export default connect(store => {
    return {
        data: store.base.data
    }
})(EditMessaging)
