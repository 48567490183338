import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { push } from 'connected-react-router'

import {
    newContent,
    editContent,
    readContent,
    editSiteGlobals,
    editEditors,
    readFrontpage,
    editFrontpage,
    readNewsArchive,
    editNewsArchive,
    newProductions,
    editVisitorsDebug,
    mainSearch,
    editSheets,
    editProductionSeats,
    detailedProgramming,
    editDetailedProgramming,
    editBroadcasts,
    edit404Content,
    editMessaging,
    getStats
} from "../actions/common"

import EditStatic from "../components/layouts/EditStatic"
import EditNews from '../components/layouts/EditNews'

import ReadArticle from '../components/layouts/ReadArticle'
import ReadFrontpage from '../components/layouts/ReadFrontpage'
import ReadNewsArchive from '../components/layouts/ReadNewsArchive'
import ReadProduction from '../components/layouts/ReadProduction'
import ReadSingleTag from '../components/layouts/ReadSingleTag'

import EditSiteGlobals from '../components/layouts/EditSiteGlobals'
import EditEditors from '../components/layouts/EditEditors'
import EditNotFound from '../components/layouts/EditNotFound'
import EditFrontpage from '../components/layouts/EditFrontpage'
import EditProduction from '../components/layouts/EditProduction'
import EditVisitorsDebug from '../components/layouts/EditVisitorsDebug'
import EditNewsArchive from "../components/layouts/EditNewsArchive"
import EditSingleTag from "../components/layouts/EditSingleTag"
import EditSheets from "../components/layouts/EditSheets"
import EditProductionSeats from "../components/layouts/EditProductionSeats"
import EditBroadcasts from "../components/layouts/EditBroadcasts"

import EditStats from "../components/layouts/EditStats"

import EditMessaging from "../components/layouts/EditMessaging"

import NotFound from '../components/layouts/NotFound'
import Search from "../components/layouts/Search";
import DetailedProgramming from "../components/layouts/DetailedProgramming";
import EditDetailedProgramming from "../components/layouts/EditDetailedProgramming"

const ARTICLE_TYPES = ["static", "news"]

const LAYOUTS = [

    // static
    {
        tags: ['article', 'static', 'edit-for-editor'],
        init: () => (<EditStatic isNew={false} />)
    },
    {
        tags: ['article', 'static', 'create-for-editor'],
        init: () => (<EditStatic isNew={true} />)
    },
    {
        tags: ['article', 'static', 'read-for-editor'],
        init: () => (<ReadArticle />)
    },
    {
        tags: ['article', 'static', 'read-for-reader'],
        init: () => (<ReadArticle />)
    },


    // news
    {
        tags: ['article', 'news', 'edit-for-editor'],
        init: () => (<EditNews isNew={false} />)
    },
    {
        tags: ['article', 'news', 'create-for-editor'],
        init: () => (<EditNews isNew={true} />)
    },
    {
        tags: ['article', 'news', 'read-for-editor'],
        init: () => (<ReadArticle />)
    },
    {
        tags: ['article', 'news', 'read-for-reader'],
        init: () => (<ReadArticle />)
    },


    // production
    {
        tags: ['production', 'read-for-editor'],
        init: () => (<ReadProduction isEditor={true} />)
    },
    {
        tags: ['production', 'read-for-reader'],
        init: () => (<ReadProduction isEditor={false} />)
    },
    {
        tags: ['production', 'create-for-editor'],
        init: () => (<EditProduction isNew={true}  />)
    },
    {
        tags: ['production', 'edit-for-editor'],
        init: () => (<EditProduction isNew={false} />)
    },


    // edit site-globals
    {
        tags: ['site-globals', 'edit-for-editor'],
        init: () => (<EditSiteGlobals />)
    },


    // edit editors
    {
        tags: ['editors', 'edit-for-editor'],
        init: () => (<EditEditors />)
    },


    // edit frontpage
    {
        tags: ['edit-for-editor', 'frontpage'],
        init: () => (<EditFrontpage />)
    },

    // read frontpage
    {
        tags: ['read-for-reader', 'frontpage'],
        init: () => (<ReadFrontpage />)
    },
    {
        tags: ['read-for-editor', 'frontpage'],
        init: () => (<ReadFrontpage />)
    },



    // edit news-archive
    {
        tags: ['edit-for-editor', 'news-archive'],
        init: () => (<EditNewsArchive />)
    },

    // read news-archive
    {
        tags: ['read-for-reader', 'news-archive'],
        init: () => (<ReadNewsArchive />)
    },
    {
        tags: ['read-for-editor', 'news-archive'],
        init: () => (<ReadNewsArchive />)
    },


    // read tag
    {
        tags: ['read-for-reader', 'tag-view'],
        init: () => (<ReadSingleTag />)
    },
    {
        tags: ['read-for-editor', 'tag-view'],
        init: () => (<ReadSingleTag />)
    },

    // edit tag
    {
        tags: ['edit-for-editor', 'tag-view'],
        init: () => (<EditSingleTag />)
    },

    // spreadsheets
    {
        tags: ['edit-for-editor', 'sheets'],
        init: () => (<EditSheets />)
    },

    // stats
    {
        tags: ['ticket-stat', 'read-for-editor'],
        init: () => (<EditStats />)
    },

    // production seats
    {
        tags: ['edit-for-editor', 'production-seats'],
        init: () => (<EditProductionSeats />)
    },

    // search
    {
        tags: ['search', 'read-for-reader'],
        init: () => (<Search />)
    },
    {
        tags: ['search', 'read-for-editor'],
        init: () => (<Search />)
    },

    // detailed search
    {
        tags: ['detailed-programming', 'read-for-reader'],
        init: () => (<DetailedProgramming />)
    },
    {
        tags: ['detailed-programming', 'read-for-editor'],
        init: () => (<DetailedProgramming />)
    },
    {
        tags: ['edit-for-editor', 'detailed-programming'],
        init: () => (<EditDetailedProgramming />)
    },

    // visitors
    {
        tags: ['edit-for-editor', 'visitors'],
        init: () => (<EditVisitorsDebug />)
    },

    // broadcasts
    {
        tags: ['edit-for-editor', 'broadcast'],
        init: () => (<EditBroadcasts />)
    },

    // messaging
    {
        tags: ['read-for-editor', 'infopult-messaging'],
        init: () => (<EditMessaging />)
    },


    // not found edit
    {
        tags: ['404', 'edit-for-editor'],
        init: () => (<EditNotFound />)
    },

    // not found
    {
        tags: ['404'],
        init: () => (<NotFound />)
    },

    // default
    {
        tags: [],
        init: () => null
    }
]


const matchTags = (haystack, needles) => (
    needles.reduce((acc, curr) => acc && haystack.includes(curr), true)
)

const getLayout = (tags) => {

    const maybeLayout = LAYOUTS.find(d => matchTags(tags, d.tags))
    // for safety
    return typeof maybeLayout !== 'undefined' ? maybeLayout.init() : null
}



class DynamicContent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            location: props.location,
            fetchingData: false
        }
    }

    componentDidMount = () => {
        this.handleLocationChange()
    }

    componentDidUpdate = prevProps => {
        const { location, data } = this.props

        const hasLocationChanged = prevProps.location.pathname !== location.pathname || prevProps.location.search !== location.search
        const hasDataChanged = ! _.isEqual(data, prevProps.data)

        if (hasLocationChanged) {
            console.log("handle location change")
            this.handleLocationChange()
        } else if (hasDataChanged) {
            console.log("data changed")
            this.setState({ fetchingData: false })
        }
        if (! _.isEqual(this.props.location, prevProps.location)) {
            console.log("location change")
            this.setState({ location: this.props.location })
        }
    }

    getQueryParams = location => {
        return location.search
            .replace(/\??(.+$)/, "$1")
            .split("&")
            .reduce((acc, curr) => {
                const split = curr.split("=")
                if (split.length > 0 && split[0].length) {
                    const result = {...acc}
                    result[split[0]] = split[1] ? split[1] : ""
                    return result
                } else {
                    return acc
                }
            }, {})
    }

    handleLocationChange = () => {
        const { location, dispatch } = this.props

        const isNewsArchivePath = /^\/x\/specials\/news-archive[/]?/.test(location.pathname) || /^\/x\/news-archive[/]?/.test(location.pathname)
        const isFrontpagePath = /^\/x\/specials\/frontpage[/]?/.test(location.pathname) || /^\/$/.test(location.pathname)

        // @TODO ezt lehet elobb meg kellene mar fogni, routingnal talan?
        const isEditNewsArchive = isNewsArchivePath && /edit/.test(location.search)
        const isReadNewsArchive = isNewsArchivePath && ! /edit/.test(location.search)
        const isReadFrontpage = isFrontpagePath && ! /edit/.test(location.search)
        const isEditFrontpage = isFrontpagePath && /edit/.test(location.search)
        const isEditProductions = /^\/x\/productions\/?/.test(location.pathname) && /create/.test(location.search)
        const isApi = /^\/api\/.*$/.test(location.pathname)
        const isNew = /^\/x\/article\/?/.test(location.pathname) && /create=/.test(location.search)
        const isUpdate = /edit/.test(location.search)
        const isSiteGlobal = /^\/x\/site-globals\/?/.test(location.pathname) && /edit/.test(location.search)
        const isEditors = /^\/x\/editors\/?/.test(location.pathname) && /edit/.test(location.search)
        const isSearch = /^\/x\/search\/?/.test(location.pathname)
        const isEditSheets = /^\/x\/sheets\/?/.test(location.pathname) && /edit/.test(location.search)

        const isStats = /^\/x\/ticket-stats\/?/.test(location.pathname)

        const isEditVisitorsDebug = /^\/x\/visitors\/?/.test(location.pathname)
        const isBroadcasts = /^\/x\/broadcasts\/?/.test(location.pathname)

        const isMessaging = /^\/x\/messaging\/?/.test(location.pathname) && /edit/.test(location.search)
        
        const isDetailedProgramming = /^\/x\/detailed-programming\/?/.test(location.pathname)
        const isEditDetailedProgramming = /^\/x\/specials\/detailed-programming[/]?/.test(location.pathname) && /edit/.test(location.search)
        
        const isEditProductionSeats = /^\/x\/production-seats\/?/.test(location.pathname) && /edit/.test(location.search)

        const isEditNotFound = /^\/x\/specials\/404\/?/.test(location.pathname) && /edit/.test(location.search)

        const type = _.get(location.search.match(/create=([^&]+)$/), [1], "")

        const debug = false;

        if (isApi) {
            dispatch(push("/"))
        } else if (isSearch) {
            const searchText = new URLSearchParams(location.search).get("q") || ""
            const past = new URLSearchParams(location.search).get("past")
            dispatch(mainSearch(searchText, past))

        } else if (isEditNewsArchive) {
            dispatch(editNewsArchive())

        } else if (isReadNewsArchive) {
            const matches = location.search.match(/page=([0-9]+)/)
            const page = matches !== null
                ? matches[1]
                : 0

            dispatch(readNewsArchive(page))
        } else if (isEditProductions) {
            dispatch(newProductions())

        } else if (isStats) {
            dispatch(getStats())

        } else if (isReadFrontpage) {
            dispatch(readFrontpage())

        } else if (isEditFrontpage) {
            dispatch(editFrontpage())

        } else if (isEditors) {
            dispatch(editEditors())

        } else if (isSiteGlobal) {
            dispatch(editSiteGlobals())

        } else if (isEditSheets) {
            dispatch(editSheets())

        } else if (isEditVisitorsDebug) {
            dispatch(editVisitorsDebug())

        } else if (isEditProductionSeats) {
            dispatch(editProductionSeats())

        } else if (isDetailedProgramming) {
            dispatch(detailedProgramming(location.pathname, this.getQueryParams(location)))

        } else if (isEditDetailedProgramming) {
            dispatch(editDetailedProgramming(location.pathname, this.getQueryParams(location)))

        } else if (isBroadcasts) {
            dispatch(editBroadcasts())

        } else if (isMessaging) {
            dispatch(editMessaging())

        } else if (isEditNotFound) {
            dispatch(edit404Content())

        } else if (isNew && ARTICLE_TYPES.includes(type)) {
            dispatch(newContent(type))

        } else if (isUpdate) {
            dispatch(editContent(location.pathname, this.getQueryParams(location)))
            
        } else {
            dispatch(readContent(location.pathname, this.getQueryParams(location)))

        }

        this.setState({
            fetchingData: true
        })
    }

    render = () => {
        const { display } = this.props

        const layout = Array.isArray(display) ? getLayout(display) : null
        return (
            <div>{layout}</div>
        )
    }
}


export default connect(store => {
    return {
        toolbar: store.base.toolbar,
        display: store.base.display,
        data: store.base.data,
        location: store.router.location,
        searchText: store.search.searchText
    }
})(DynamicContent)