import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { reduxForm } from 'redux-form'


import { CKEditorField } from '../form/elements'

import { saveDetailedProgramming, enableUrlChange, disableUrlChange, clearContentData, getFullHistory } from "../../actions/common"

import UrlListener from '../utils/UrlChangeListener'
import { renderHistory } from "./layoutHelpers"
import {EditTitle} from "../utils/common";

const FORM_ID = "editDetailedProgramming"


class EditDetailedProgramming extends Component {

    state = {
        historyMoreClicked: false
    }


    componentDidUpdate = prevProps => {

        const { pristine } = this.props

        if (! pristine && prevProps.pristine) {
            this.props.dispatch(disableUrlChange())
        } else if (pristine && ! prevProps.pristine) {
            this.props.dispatch(enableUrlChange(false))
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    onAttemptUrlChange = () => {
        if (window.confirm("unsaved form, leave anyway?")) {
            this.props.dispatch(enableUrlChange(true))
        }
    }

    onSubmit = values => {
        this.props.dispatch(enableUrlChange(false))
        this.props.dispatch(saveDetailedProgramming(values)).then(
            data => {
                const redirect = _.get(data, "value.data.href", "/")
                this.props.dispatch(push(redirect))
            },
            e => {}
        )
    }

    render = () => {

        const { handleSubmit } = this.props

        const prequelHtml = _.get(this.props, 'initialValues.prequel', '<p></p>')
        const sequelHtml = _.get(this.props, 'initialValues.sequel', '<p></p>')
        const history = _.get(this.props, 'history', [])
        const historyLink = _.get(this.props, 'actions.history.href', null)

        return (
            <div className="edit">
                <UrlListener onAttemptUrlChange={this.onAttemptUrlChange} />

                <EditTitle>Részletes keresés szerkesztő</EditTitle>

                <form onSubmit={handleSubmit(this.onSubmit)}>
                    <label><b>felső html:</b></label>
                    <div>
                        <CKEditorField
                            data={prequelHtml}
                            formId={FORM_ID}
                            name="prequel" />
                    </div>

                    <div style={{minHeight: "32px"}} />

                    <label><b>alsó html:</b></label>
                    <div>
                        <CKEditorField
                            data={sequelHtml}
                            formId={FORM_ID}
                            name="sequel" />
                    </div>
                </form>

                <div className="control-buttons">
                    <button className="button green" onClick={handleSubmit(this.onSubmit)}>Mentés</button>
                    <button className="button red" onClick={() => {
                        this.props.dispatch(enableUrlChange(false))
                        this.props.dispatch(push("/x/detailed-programming"))
                    }}>Elvetés</button>
                </div>

                { history.length > 0 &&
                <div className={"history"}>
                    <p>eseménynapló:</p>
                    {renderHistory(history)}
                    { historyLink !== null && ! this.state.historyMoreClicked &&
                    <span className="link" onClick={() => {
                        this.setState({ historyMoreClicked: true })
                        this.props.dispatch(getFullHistory(historyLink))
                    }}>Bővebben</span>
                    }
                </div>
                }

            </div>

        )
    }
}

export default connect(store => {
    const data = {
        ...store.base.data,
    }
    return {
        initialValues: data || {},
        history: _.get(store.base, 'history', []),
        actions: _.get(store.base, 'actions', [])
    }
})(reduxForm({
    form: FORM_ID,
    enableReinitialize: true
})(EditDetailedProgramming))
