
export default (state = {
    googleAccountApi: null,
    googleAuth: null,
    urlChangeAttempt: false,
    urlChangeEnabled: true,
    storedUrlChangeRequest: null,
    colorPalette: [],
    closeMobileMenu: false
}, action) => {

    switch (action.type) {
        case "common/SET_GOOGLE_ACCOUNT_API":
            return {...state, googleAccountApi: action.payload }

        case "common/ENABLE_URL_CHANGE":
            return {...state, urlChangeEnabled: true, storedUrlChangeRequest: null}

        case "common/DISABLE_URL_CHANGE":
            return {...state, urlChangeEnabled: false}

        case "common/STORE_URL_CHANGE_REQUEST":
            return {...state, storedUrlChangeRequest: action.payload, urlChangeAttempt: true}

        case "common/RESET_ATTEMPT_URL_CHANGE_FLAG":
            return {...state, urlChangeAttempt: false}

        case "@@router/CALL_HISTORY_METHOD":
            return {...state, urlChangeEnabled: true, storedUrlChangeRequest: null}

        case "common/GET_COLOR_PALETTE_FULFILLED":
            return {...state, colorPalette: action.payload.data}

        case "common/CLOSE_MOBILE_MENU":
            return {...state, closeMobileMenu: true}

        case "common/RESET_CLOSE_MOBILE_MENU":
            return {...state, closeMobileMenu: false}

        default:
            return {...state}
    }
}