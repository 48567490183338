import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PropTypes from 'prop-types'
import { isBrowser } from "react-device-detect"


import { CKEditorField, renderDatetimePicker, renderInput, renderInputWithIcon } from '../form/elements'
import { Constraints, validateField, asyncValidateCanonicalId } from '../form/validators'
import { convertToCanonical } from '../../utils/common'
import { toDatetimeString, baseUrlCopyBoardRegex } from '../../utils/common'

import { baseUrl } from '../../index'

import {
    saveContent,
    enableUrlChange,
    disableUrlChange,
    clearContentData,
    getFullHistory,
    deleteContent
} from "../../actions/common"

import copyToClipboard from '../../assets/icons/copy-to-clipboard.svg'

import UrlListener from '../utils/UrlChangeListener'
import { renderHistory } from "./layoutHelpers"
import {EditTitle} from "../utils/common";

const FORM_ID = "editStatic"


class EditStatic extends Component {

    state = {
        historyMoreClicked: false


    }


    componentDidUpdate = prevProps => {

        const { pristine } = this.props

        if (! pristine && prevProps.pristine) {
            console.log("disable navigation")
            this.props.dispatch(disableUrlChange())
        } else if (pristine && ! prevProps.pristine) {
            console.log("enable navigation")
            this.props.dispatch(enableUrlChange(false))
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    onAttemptUrlChange = () => {
        if (window.confirm("unsaved form, leave anyway?")) {
            console.log("leaving...")
            this.props.dispatch(enableUrlChange(true))
        } else {
            console.log("stay, DO NOT navigate")
        }
    }

    onSubmit = values => {
        const valuesToSend = _.omit({
            ...values,
            releaseTime: toDatetimeString(values.releaseTime)
        }, ['origCanonical'])

        this.props.dispatch(enableUrlChange(false))
        this.props.dispatch(saveContent(valuesToSend)).then(
            data => {
                const redirect = _.get(data, "value.data.href", "/")
                this.props.dispatch(push(redirect))
            },
            e => {}
        )
    }

    render = () => {

        const { handleSubmit } = this.props

        const htmlData = _.get(this.props, 'initialValues.contents', '<p></p>')
        const canonical = _.get(this.props, 'canonical', "")
        const history = _.get(this.props, 'history', [])
        const historyLink = _.get(this.props, 'actions.history.href', null)
        const thumbnail = isBrowser
            ? _.get(this.props, 'initialValues.thumbnail.web', null)
            : _.get(this.props, 'initialValues.thumbnail.mobile', null)

        return (
            <div className="edit">
                <UrlListener onAttemptUrlChange={this.onAttemptUrlChange} />

                {/*<div>{ ! pristine ? "Form modified" : "Form not modified" }</div>*/}
                <EditTitle>Cikk szerkesztő</EditTitle>

                <form onSubmit={handleSubmit(this.onSubmit)}>

                    <table><tbody>

                        <tr>
                            <td style={{width: "5em"}}>cím:</td>
                            <td>
                                <Field
                                    placeholder="Cím"
                                    name="title"
                                    component={renderInput}
                                    onChange={(e, newValue) => {
                                        if (this.props.isNew && typeof newValue === 'string') {
                                            const value = _.get(this.props, 'initialValues.canonical', "") + convertToCanonical(newValue)
                                            this.props.dispatch(() => (
                                                new Promise(resolve => {
                                                    this.props.dispatch(change(FORM_ID, "canonical", value))
                                                    resolve()
                                                }).then(() => this.props.asyncValidate())
                                            ))
                                        }
                                    }}
                                    type="text" />
                            </td>
                        </tr>

                        <tr>
                            <td>url:</td>
                            <td>
                                <Field
                                    disabled={! this.props.isNew}
                                    placeholder={""}
                                    name={"canonical"}
                                    component={renderInputWithIcon}
                                    additionalComponent={(
                                        <CopyToClipboard onCopy={() => alert("Vágólapra másolva!")} text={baseUrlCopyBoardRegex(baseUrl) + canonical}>
                                            <img className="copy-to-clipboard" src={copyToClipboard} alt="" />
                                        </CopyToClipboard>
                                    )}
                                    type={"text"}
                                    format={value => ( !! baseUrl ? `${baseUrl}/${value}` : `${value}`)}
                                    parse={value => ( !! baseUrl ? value.substr(baseUrl.length + 1) : value)}
                                />

                            </td>
                        </tr>

                        <tr>
                            <td></td>
                            <td>
                                <div>
                                    <img src={thumbnail !== null ? thumbnail : "https://musorfuzet-thumbnail.s3-eu-west-1.amazonaws.com/default_328x184.jpg"} alt={""}/>
                                </div>
                            </td>
                        </tr>
                    </tbody></table>

                    <table><tbody>
                        <tr>
                            <td style={{width: "14em"}}>megjelenés időpontja:</td>
                            <td>
                                <Field
                                    name="releaseTime"
                                    component={renderDatetimePicker} />
                            </td>
                        </tr>
                    </tbody></table>

                    <div>
                        <CKEditorField
                            data={htmlData}
                            formId={FORM_ID}
                            name="contents" />
                    </div>
                </form>

                <div className="control-buttons">
                    <button className="button green" onClick={handleSubmit(this.onSubmit)}>Mentés</button>
                    <button className="button red" onClick={() => {
                        this.props.dispatch(enableUrlChange(false))
                        const location = this.props.isNew ? "/" : _.get(this.props, 'initialValues.canonical', "/")
                        this.props.dispatch(push(location.replace(/^[/]?(.*)$/, "/$1")))
                    }}>Elvetés</button>
                    { this.props.initialValues.origCanonical &&
                    <button className="button red" onClick={() => {
                        const redirect = () => {
                            this.props.dispatch(enableUrlChange(false))
                            this.props.dispatch(push('/'))
                        }
                        if (window.confirm('Biztos törölni szeretnéd?')) {
                            this.props.dispatch(deleteContent(this.props.initialValues.origCanonical)).then(redirect, redirect)
                        }
                    }}>Törlés</button>
                    }
                </div>

                { history.length > 0 &&
                <div className={"history"}>
                    <p>eseménynapló:</p>
                    {renderHistory(history)}
                    { historyLink !== null && ! this.state.historyMoreClicked &&
                    <span className="link" onClick={() => {
                        this.setState({ historyMoreClicked: true })
                        this.props.dispatch(getFullHistory(historyLink))
                    }}>Bővebben</span>
                    }
                </div>
                }

            </div>

        )
    }
}

const validateForm = values => {
    return {
        title: validateField(values.title, [
            Constraints.required
        ]),
        canonical: validateField(values.canonical, [
            Constraints.required,
            Constraints.canonical
        ]),
        contents: validateField(values.contents, [
            Constraints.required
        ]),
        releaseTime: validateField(values.releaseTime, [
            Constraints.required
        ])
    }
}


const selector = formValueSelector(FORM_ID)

EditStatic.propTypes = {
    isNew: PropTypes.bool.isRequired
}


export default connect(store => {
    const canonical = _.get(store.base, 'canonicalId', "")
    const data = {
        ...store.base.data,
        canonical: canonical,
        origCanonical: _.get(store.base, 'display', []).findIndex(d => /create/.test(d)) !== -1 ? null : canonical,
        news: false
    }

    return {
        initialValues: data || {},
        canonical: selector(store, 'canonical') || "",
        history: _.get(store.base, 'history', []),
        actions: _.get(store.base, 'actions', [])
    }
})(reduxForm({
    form: FORM_ID,
    enableReinitialize: true,
    validate: validateForm,
    asyncValidate: asyncValidateCanonicalId,
    asyncChangeFields: ['canonical']
})(EditStatic))
