
export default (state = {
    accessLevels: []
}, action) => {

    switch (action.type) {

        case "editors/ACCESS_LEVELS_FULFILLED":
            return { ...state, accessLevels: action.payload.data }

        default:
            return { ...state }
    }
}