import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import _ from "lodash";


export const SimpleBox = connect()(class extends React.Component {

    render = () => {

        const { title, href, dispatch, image } = this.props

        const style = image !== null
            ? { backgroundImage: `url('${image}')`}
            : {}

        const isPartial = _.get(this.props, 'partial', false)
        const isDraft = _.get(this.props, 'draft', false)

        return (
            <div style={style} className={"simple-box pointer"} title={title} onClick={() => {
                dispatch(push(href))
            }}>
                { isPartial && <div className={"exclamation"} /> }
                { isDraft && <div className={"stop"} /> }
                <a href={href} onClick={e => e.preventDefault()}>
                    <div className={"overlay"}>
                        <span>{title}</span>
                    </div>
                </a>
            </div>
        )
    }
})