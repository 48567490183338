import React, { useEffect, useState } from 'react'
import { push } from 'connected-react-router'

export const MenuLink = ({ href, children, dispatch, className = "" }) => {

    if (typeof href === "string") {
        const clickFunc =
            /^http[s]?:\/\//.test(href)
                ? () => {
                    window.open(href, "_blank")
                }
                : () => {
                    dispatch(push(href))
                }

        return (
            <div className={className + " pointer"} onClick={clickFunc}>{children}</div>
        )
    } else {
        return (
            <div className={className}>{children}</div>
        )
    }

}



export const EditTitle = props => (
    <div className="title">
        <div>{props.children}</div>
    </div>
)




export const BoxContainerEditItem = ({
    isFirst = true,
    isLast = true,
    title = "",
    onUpClick = () => {},
    onDownClick = () => {}
}) => {
    return (
        <div className={"box-container-edit-item"}>
            <div className={"item-header"}>
                <div className={"item-title"}>{title}</div>
                <div className={"item-control"}>
                    <div
                        className={`icon move-up ${!isFirst ? "" : "disabled"}`}
                        onClick={() => (!isFirst ? onUpClick() : () => {})} />
                    <div
                        className={`icon move-down ${!isLast ? "" : "disabled"}`}
                        onClick={() => (!isLast ? onDownClick() : () => {})} />
                </div>
            </div>
            <div className={"item-boxes"}>
                <img src={"https://musorfuzet-thumbnail.s3-eu-west-1.amazonaws.com/default_328x184.jpg"} alt={""} />
                <img src={"https://musorfuzet-thumbnail.s3-eu-west-1.amazonaws.com/default_328x184.jpg"} alt={""} />
                <img src={"https://musorfuzet-thumbnail.s3-eu-west-1.amazonaws.com/default_328x184.jpg"} alt={""} />
            </div>
        </div>
    )
}


export const ColoredLabel = ({
    backgroundColor = "#00000",
    foregroundColor = "#00000",
    onClick,
    children,
    haveHref = true
}) => {
    const callback = onClick ? onClick : () => {}

    const processChildren = typeof children === 'string' ? children.trim() : children

    return (
        <span onClick={callback} style={{backgroundColor: backgroundColor, color: foregroundColor, borderColor: foregroundColor}} className={`colored-label ${onClick ? haveHref ? "pointer" : "" : ""}`}>{processChildren}</span>
    )
}


export const loadScript = (url, onLoad = () => {}, onError = () => {}) => {
    let script = document.querySelector(`script[src="${url}"]`)
    if (! script) {
      script = document.createElement("script")
      script.type = "application/javascript"
      script.src = url
      script.async = true
      document.body.appendChild(script)
    }
    script.addEventListener("load", onLoad)
    script.addEventListener("error", onError)
}

export const Expire = (props) => {
    const [visible, setVisible] = useState(true);
  
    useEffect(() => {
      setTimeout(() => {
        setVisible(false);
      }, props.delay);
    }, [props.delay]);
  
    return visible ? <div>{props.children}</div> : <div />;
  };