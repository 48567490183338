import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AutoSuggest from 'react-autosuggest'
import { isBrowser } from "react-device-detect"

import { CKFinder, CKEditorField, renderInput, renderInputWithIcon } from '../form/elements'
import { Constraints, validateField, asyncValidateCanonicalId } from '../form/validators'
import { baseUrlCopyBoardRegex, convertToCanonical } from '../../utils/common'
import { toDatetimeString } from '../../utils/common'

import { baseUrl } from '../../index'

import {
    saveProduction,
    enableUrlChange,
    disableUrlChange,
    clearContentData,
    getFullHistory,
    deleteProduction
} from "../../actions/common"

import UrlListener from '../utils/UrlChangeListener'
import { renderHistory } from "./layoutHelpers"
import copyToClipboard from "../../assets/icons/copy-to-clipboard.svg";
import {EditTitle} from "../utils/common";
import DatePicker from "react-datepicker";
import axios from "axios";



const FORM_ID = "editProduction"


const emptyProduction = {
    productionInstanceId: null,
    village: "",
    stage: "",
    startTime: "",
    endTime: "",
    duration: 60,
    endOrDuration: "duration",
    shortDescription: "",
    canceled: false,
    ticketed: false,
    changed: false
}


class EditProduction extends Component {

    state = {
        historyMoreClicked: false,
        creators: null,
        instances: null,
        documents: null,
        image: null,
        creatorSuggestions: [],
        stageSuggestions: [],
        villageSuggestions: [],
        genreSuggestions: [],
        labelsSuggesions: []
    }

    componentDidUpdate = prevProps => {

        const { pristine } = this.props

        if (! pristine && prevProps.pristine) {
            this.props.dispatch(disableUrlChange())
        } else if (pristine && ! prevProps.pristine) {
            this.props.dispatch(enableUrlChange(false))
        }

        if (this.state.creators === null && this.props.initialValues.creators) {
            this.setState({ creators: this.props.initialValues.creators})
        }

        if (this.state.instances === null && this.props.initialValues.instances) {
            this.setState({ instances: this.props.initialValues.instances})
        }

        if (this.state.documents === null && this.props.initialValues.documents) {
            this.setState({ documents: this.props.initialValues.documents})
        }

        const img = _.get(this, 'props.initialValues.image.thumbnail.web', null)
        if (this.state.image === null && img !== null) {
            this.setState({ image: img})
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    onAttemptUrlChange = () => {
        if (window.confirm("unsaved form, leave anyway?")) {
            this.props.dispatch(enableUrlChange(true))
        }
    }

    onSubmit = values => {

        const mapInstance = i => {
            const duration = typeof i.duration === "string" && i.duration.length && /\d*/.test(i.duration)
                ? parseInt(i.duration, 10)
                : (typeof i.duration === 'number' ? i.duration : 60)
                
            return {
                ...i,
                startTime: typeof i.startTime === "string" && i.startTime.length ? i.startTime : null,
                duration: duration
            }
        }

        const valuesToSend = _.omit({
            ...values,
            shortDescription: typeof values.shortDescription === "string" ? values.shortDescription : "",
            description: typeof values.description === "string" ? values.description : "",
            instances: values.instances.filter(i => ! _.isEqual(i, emptyProduction)).map(mapInstance),
        }, ['origCanonical'])

        this.props.dispatch(enableUrlChange(true))
        this.props.dispatch(saveProduction(valuesToSend)).then(
            data => {
                const redirect = _.get(data, "value.data.href", "/")
                this.props.dispatch(push(redirect))
            },
            e => {}
        )
    }

    onChangeCreator = (idx, txt) => e => {

        const { creators } = this.state

        const updated = [
            ...creators.slice(0, idx),
            txt,
            ...creators.slice(idx + 1)
        ]

        this.props.dispatch(change(FORM_ID, "creators", updated))
        
        this.setState({
            creators: updated
        })
    }

    onCreatorsOrderClick = (currIdx, idxOffset) => () => {
        const creators = this.state.creators !== null ? this.state.creators : []
        const newIdx = idxOffset < 0
            ? Math.max(0, currIdx + idxOffset)
            : Math.min(creators.length - 1, currIdx + idxOffset)

        const updated = creators.map((item, idx, currArray) => {
            if (idx === currIdx) {
                return currArray[newIdx]
            } else if (idx === newIdx) {
                return currArray[currIdx]
            } else {
                return item
            }
        })

        this.setState({ creators: updated })
        this.props.dispatch(change(FORM_ID, "creators", updated))
    }

    onAddCreator = () => {
        const updated = [...this.state.creators, ""]
        this.setState({ creators: updated})
        this.props.dispatch(change(FORM_ID, "creators", updated))
    }



    renderCreators = creators => {

        const onSuggestionsFetchRequested = async ({ value }) => {
            const result = value.length >= 3 ? await getCreatorSuggestions(value) : []
            this.setState({ creatorSuggestions: result })
        }

        const renderItem = (item, idx) => {
            return (
                <tr key={idx}>
                    <td>{ idx + 1 < creators.length && (
                        <div className={"icon move-down"} onClick={this.onCreatorsOrderClick(idx, 1)} />
                    )}</td>
                    <td>{ idx > 0 && (
                        <div className={"icon move-up"} onClick={this.onCreatorsOrderClick(idx, -1)} />
                    )}</td>
                    <td>
                        <AutoSuggest
                            suggestions={this.state.creatorSuggestions || []}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={() => this.setState({ creatorSuggestions: [] })}
                            onSuggestionSelected={(event, { suggestion }) => {
                                event.preventDefault()
                                this.onChangeCreator(idx, suggestion.title)(event)
                            }}
                            getSuggestionValue={suggestion => suggestion.title}
                            renderSuggestion={renderSuggestion}
                            renderSuggestionsContainer={renderSuggestionsContainer}
                            alwaysRenderSuggestions={false}
                            shouldRenderSuggestions={(value) => {
                                return value.trim().length >= 3
                            }}
                            inputProps={{
                                type: "text",
                                value: item,
                                onChange: e => {
                                    e.preventDefault()
                                    this.onChangeCreator(idx, e.target.value)(e)
                                }
                            }} />
                    </td>
                    <td>
                        <div className={"icon remove"} onClick={() => {
                            const updated = [
                                ...this.state.creators.slice(0, idx),
                                ...this.state.creators.slice(idx + 1)
                            ]
                            this.props.dispatch(change(FORM_ID, "creators", updated))
                            this.setState({ creators: updated })
                        }} />
                    </td>
                </tr>
            )
        }

        return(
            <table className={"creators"}><tbody>
                {creators.map(renderItem)}
                <tr key={creators.length}>
                    <td>
                        <div className={"icon add"} onClick={this.onAddCreator} />
                    </td>
                    <td colSpan={4} />
                </tr>
            </tbody></table>
        )
    }


    modifiyInstance = (idx, data) => {
        const updated = [
            ...this.state.instances.slice(0, idx),
            data,
            ...this.state.instances.slice(idx + 1)
        ]

        this.setState({ instances: updated })
        this.props.dispatch(change(FORM_ID, "instances", updated))
    }

    addInstance = () => {
        const { instances } = this.state

        const getInstance = () => {
            if (instances.length > 0) {
                const orig = instances[instances.length - 1]
                return {
                    ...emptyProduction,
                    village: orig.village || emptyProduction.village,
                    stage: orig.stage || emptyProduction.stage,
                    startTime: orig.startTime || this.props.productionDefaultStart,
                    endTime: orig.endTime || emptyProduction.endTime,
                    endOrDuration: orig.endOrDuration || emptyProduction.endOrDuration,
                    duration: orig.duration || emptyProduction.duration,
                    shortDescription: orig.shortDescription || emptyProduction.shortDescription,
                    canceled: !! orig.canceled,
                    ticketed: !! orig.ticketed,
                    changed: !! orig.changed,
                    appSeats: orig.appSeats || 0,
                    complimentarySeats: orig.complimentarySeats || 0,
                    inPersonSeats: orig.inPersonSeats || 0,
                    totalSeats: orig.totalSeats || 0
                }
            } else {
                return { ...emptyProduction, startTime: this.props.productionDefaultStart }
            }
        }

        const updated = [...this.state.instances, getInstance()]
        this.setState({instances: updated})
        this.props.dispatch(change(FORM_ID, "instances", updated))
    }

    removeInstance = idx => () => {
        const updated = [
            ...this.state.instances.slice(0, idx),
            ...this.state.instances.slice(idx + 1)
        ]
        this.setState({instances: updated})
        this.props.dispatch(change(FORM_ID, "instances", updated))
    }


    renderInstances = instances => {

        //villageSuggestions

        const onVillageSuggestionsFetchRequested = async ({ value }) => {
            const result = value.length >= 3 ? await getVillageSuggestions(value) : []
            this.setState({ villageSuggestions: result })
        }

        const onStageSuggestionsFetchRequested = async ({ value }) => {
            const result = value.length >= 3 ? await getStageSuggestions(value) : []
            this.setState({ stageSuggestions: result })
        }


        const maybeDateField = (item, idx) => {
            if(this.state.instances[idx].endOrDuration === "end") {
                return (
                    <div className={"half right"}>
                        <div className={"label"}>vége időpont:</div>
                        <DatePicker
                            disabled={false}
                            timeIntervals={15}
                            selected={typeof item.endTime === "string" && item.endTime.length ? new Date(item.endTime) : null}
                            onChange={value => {
                                if (value === null) {
                                    this.modifiyInstance(idx, {...item, endTime: ""})
                                } else {
                                    this.modifiyInstance(idx, {...item, endTime: toDatetimeString(value)})
                                }
                            }}
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeSelect
                        />
                    </div>
                )
            } else {
                return (
                    <div className={"half right"}>
                        <div className={"label"}>időtartam:</div>
                        <input
                            value={item.duration}
                            type={"number"}
                            step={10}
                            min={0}
                            pattern={"[1-9][0-9]*"}
                            onChange={e => { this.modifiyInstance(idx, {...item, duration: e.target.value}) }}
                        />
                    </div>
                )
            }
        }

        const ticketsIfTicketed = (item, idx) => {
            if (item.ticketed) {
                return (
                    <>
                        <div className={"full"}>
                            <hr></hr>
                            <b>
                                jegyek:
                            </b>
                        </div>
                        <div className={"quart left"}>
                            <div className={"label"}>tiszteletjegy: 
                            <button 
                                className="button right"
                                onClick={(e) => {
                                    e.preventDefault()
                                    console.log(item.complimentarySeats)
                                    if(typeof item.complimentarySeats !== "undefined" && item.complimentarySeats !== null && item.complimentarySeats !== "0" && item.complimentarySeats !== 0 && item.complimentarySeats !== "") {

                                        axios.post(`/api/v1.0/production-seats/`+item.productionInstanceId, {
                                            appSeats: item.appSeats ? parseInt(item.appSeats) : 0, 
                                            inPersonSeats: item.inPersonSeats ? parseInt(item.inPersonSeats) : 0, 
                                            complimentarySeats: item.complimentarySeats ? item.complimentarySeats.toString() : "0",
                                            print: true
                                        }).then(r => {
                                            // r.data.print.map((job) => {
                                                window.location.href = r.data.print
                                            // })
                                            if(r.data.updated.appSeats) {
                                                this.modifiyInstance(idx, {...item, appSeats: r.data.updated.appSeats})
                                                // this.setState({appSeats: r.data.updated.appSeats})
                                                item.appSeats = r.data.updated.appSeats
                                            }
                                            if(r.data.updated.complimentarySeats) {
                                                this.modifiyInstance(idx, {...item, complimentarySeats: r.data.complimentarySeats})
                                                // this.setState({complimentarySeats: r.data.updated.complimentarySeats})
                                                item.complimentarySeats = r.data.updated.complimentarySeats
                                            }
                                            if(r.data.updated.inPersonSeats) {
                                                this.modifiyInstance(idx, {...item, inPersonSeats: r.data.updated.inPersonSeats})
                                                // this.setState({inPersonSeats: r.data.inPersonSeats})
                                                item.inPersonSeats = r.data.updated.inPersonSeats
                                            }
                                            if (r.data.updated.totalSeats) {
                                                this.modifiyInstance(idx, {...item, totalSeats: r.data.updated.totalSeats})
                                                // this.setState({totalSeats: r.data.updated.totalSeats})
                                                item.totalSeats = r.data.updated.totalSeats
                                            }
                                    
                                        })
                                    }
                                }
                            }>nyomtatás</button></div>
                            <input
                                name={"complimentarySeats"}
                                value={item.complimentarySeats || ""}
                                type={"text"}
                                onChange={e => {
                                    this.modifiyInstance(idx, {...item, complimentarySeats: e.target.value})
                                    this.setState({
                                        complimentarySeats: parseInt(e.target.value)
                                    })
                                }}
                            />
                        </div>
                        <div className={"quart left"}>
                            <div className={"label"}>applikációból:</div>
                            <input
                                name={"appSeats"}
                                value={this.state.appSeats || item.appSeats}
                                type={"number"}
                                min={0}
                                onChange={e => {
                                    this.modifiyInstance(idx, {...item, appSeats: parseInt(e.target.value)})
                                    this.setState({
                                        appSeats: parseInt(e.target.value)
                                    })
                                }}
                            />
                        </div>
                        <div className={"quart left"}>
                            <div className={"label"}>infopulttól:</div>
                            <input
                                name={"inPersonSeats"}
                                // value={item.inPersonSeats || ""}
                                value={this.state.inPersonSeats || item.inPersonSeats}
                                type={"number"}
                                min={0}
                                onChange={e => {
                                    this.modifiyInstance(idx, {...item, inPersonSeats: parseInt(e.target.value)})
                                    this.setState({
                                        inPersonSeats: parseInt(e.target.value)
                                    })
                                }}
                            />
                        </div>
                        <div className={`quart right ${isNaN(parseInt(item.totalSeats)) ? "" : (parseInt(item.complimentarySeats) + parseInt(item.appSeats) + parseInt(item.inPersonSeats)) > parseInt(item.totalSeats) ? "itsRed" : ""}`}>
                            <div className={"label"}>összesen:</div>
                            <span className={"fakeinput"}>{
                            (
                                parseInt(!isNaN(parseInt(this.state.complimentarySeats)) ? this.state.complimentarySeats : !isNaN(parseInt(item.complimentarySeats)) ?  item.complimentarySeats : "0") + 
                                parseInt(!isNaN(parseInt(this.state.appSeats)) ? this.state.appSeats : !isNaN(parseInt(item.appSeats)) ? item.appSeats : 0) + 
                                parseInt(!isNaN(parseInt(this.state.inPersonSeats)) ? this.state.inPersonSeats : !isNaN(parseInt(item.inPersonSeats)) ? item.inPersonSeats : 0))
                            } / {isNaN(parseInt(item.totalSeats)) ? '?' : item.totalSeats}</span>
                        </div>

                        <div className={`full ${isNaN(parseInt(item.totalSeats)) ? 'disable' : ''}`}>
                            <div className={"label"}>jegyek elosztása:</div>
                            <button 
                                className="button mr-1"
                                onClick={(e) => {
                                    e.preventDefault()
                                    getSeatsConfiguration(item.stage, "fifty-fifty", item.complimentarySeats).then(r => {
                                        this.modifiyInstance(idx, {...item, appSeats: r.appSeats})
                                        this.setState({appSeats: r.appSeats})
                                        item.appSeats = r.appSeats
                                        this.modifiyInstance(idx, {...item, complimentarySeats: r.complimentarySeats})
                                        this.setState({complimentarySeats: r.complimentarySeats})
                                        item.complimentarySeats = r.complimentarySeats
                                        this.modifiyInstance(idx, {...item, inPersonSeats: r.inPersonSeats})
                                        this.setState({inPersonSeats: r.inPersonSeats})
                                        item.inPersonSeats = r.inPersonSeats
                                        this.modifiyInstance(idx, {...item, totalSeats: r.totalSeats})
                                        this.setState({totalSeats: r.totalSeats})
                                        item.totalSeats = r.totalSeats
                                    })
                                }}>fele-fele</button>
                            <button 
                                className="button mr-1"
                                onClick={(e) => {
                                    e.preventDefault()
                                    getSeatsConfiguration(item.stage, "app-only", item.complimentarySeats).then(r => {
                                        this.modifiyInstance(idx, {...item, appSeats: r.appSeats})
                                        this.setState({appSeats: r.appSeats})
                                        item.appSeats = r.appSeats
                                        this.modifiyInstance(idx, {...item, complimentarySeats: r.complimentarySeats})
                                        this.setState({complimentarySeats: r.complimentarySeats})
                                        item.complimentarySeats = r.complimentarySeats
                                        this.modifiyInstance(idx, {...item, inPersonSeats: r.inPersonSeats})
                                        this.setState({inPersonSeats: r.inPersonSeats})
                                        item.inPersonSeats = r.inPersonSeats
                                        this.modifiyInstance(idx, {...item, totalSeats: r.totalSeats})
                                        this.setState({totalSeats: r.totalSeats})
                                        item.totalSeats = r.totalSeats
                                    }
                                )
                                }}>csak app</button>
                            <button 
                                className="button mr-1"
                                onClick={(e) => {
                                    e.preventDefault()
                                    getSeatsConfiguration(item.stage, "in-person-only", item.complimentarySeats).then(r => {
                                        this.modifiyInstance(idx, {...item, appSeats: r.appSeats})
                                        this.setState({appSeats: r.appSeats})
                                        item.appSeats = r.appSeats
                                        this.modifiyInstance(idx, {...item, complimentarySeats: r.complimentarySeats})
                                        this.setState({complimentarySeats: r.complimentarySeats})
                                        item.complimentarySeats = r.complimentarySeats
                                        this.modifiyInstance(idx, {...item, inPersonSeats: r.inPersonSeats})
                                        this.setState({inPersonSeats: r.inPersonSeats})
                                        item.inPersonSeats = r.inPersonSeats
                                        this.modifiyInstance(idx, {...item, totalSeats: r.totalSeats})
                                        this.setState({totalSeats: r.totalSeats})
                                        item.totalSeats = r.totalSeats
                                    })
                                }}>csak infopult</button>
                        </div>

                        <div className={"full"}>
                        </div>
                    </>
                )
            } else {
                return null
            }
        }

        const renderInstance = (item, idx) => {
            return (
                <div key={idx} className={"instance-container"}>
                    <div className={"half left"}>
                        <div className={"label"}>falu:</div>

                        <AutoSuggest
                            suggestions={this.state.villageSuggestions || []}
                            onSuggestionsFetchRequested={onVillageSuggestionsFetchRequested}
                            onSuggestionsClearRequested={() => this.setState({ villageSuggestions: [] })}
                            onSuggestionSelected={(event, { suggestion }) => {
                                event.preventDefault()
                                this.modifiyInstance(idx, {...item, village: suggestion.title})
                            }}
                            getSuggestionValue={suggestion => suggestion.title}
                            renderSuggestion={renderSuggestion}
                            renderSuggestionsContainer={renderSuggestionsContainer}
                            alwaysRenderSuggestions={false}
                            shouldRenderSuggestions={(value) => {
                                return value.trim().length >= 3
                            }}
                            inputProps={{
                                type: "text",
                                value: item.village || "",
                                onChange: e => {
                                    e.preventDefault()
                                    this.modifiyInstance(idx, {...item, village: e.target.value})
                                }
                            }} />
                    </div>


                    <div className={"half right"}>
                        <div className={"label"}>kezdő időpont:</div>
                        <DatePicker
                            disabled={false}
                            timeIntervals={15}
                            selected={typeof item.startTime === "string" && item.startTime.length ? new Date(item.startTime) : null}
                            onChange={value => {
                                if (value === null) {
                                    this.modifiyInstance(idx, {...item, startTime: ""})
                                } else {
                                    this.modifiyInstance(idx, {...item, startTime: toDatetimeString(value)})
                                }
                            }}
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeSelect
                        />
                    </div>

                    <div className={"half left"}>
                        <div className={"label"}>helyszín:</div>

                        <AutoSuggest
                            suggestions={this.state.stageSuggestions || []}
                            onSuggestionsFetchRequested={onStageSuggestionsFetchRequested}
                            onSuggestionsClearRequested={() => this.setState({ stageSuggestions: [] })}
                            onSuggestionSelected={(event, { suggestion }) => {
                                event.preventDefault()
                                this.modifiyInstance(idx, {...item, stage: suggestion.title})

                            }}
                            getSuggestionValue={suggestion => {
                                const stageToSend = suggestion.title
                                getSeatsConfiguration(stageToSend, "fifty-fifty", item.complimentarySeats).then(r => {
                                    // console.log(r)
                                    item.appSeats = r.appSeats
                                    item.complimentarySeats = r.complimentarySeats
                                    item.inPersonSeats = r.inPersonSeats
                                    item.totalSeats = r.totalSeats
                                    this.setState({appSeats: r.appSeats})
                                    this.setState({complimentarySeats: r.complimentarySeats})
                                    this.setState({inPersonSeats: r.inPersonSeats})
                                    this.setState({totalSeats: r.totalSeats})
                                    // this.modifiyInstance(idx, {...item, appSeats: r.appSeats})
                                    // this.modifiyInstance(idx, {...item, complimentarySeats: r.complimentarySeats})
                                    // this.modifiyInstance(idx, {...item, inPersonSeats: r.inPersonSeats})
                                    // this.modifiyInstance(idx, {...item, totalSeats: r.totalSeats})
                                })
                                return suggestion.title
                            }}
                            renderSuggestion={renderSuggestion}
                            renderSuggestionsContainer={renderSuggestionsContainer}
                            alwaysRenderSuggestions={false}
                            shouldRenderSuggestions={(value) => {
                                return value.trim().length >= 3
                            }}
                            inputProps={{
                                type: "text",
                                value: item.stage || "",
                                onChange: e => {
                                    e.preventDefault()
                                    this.modifiyInstance(idx, {...item, stage: e.target.value})
                                }
                            }} />

                    </div>

                    {maybeDateField(item, idx)}

                    <div className={"half left"}>
                    </div>
                    <div className={"half right"}>
                        <div className={"checkboxes"}>
                            <label className={"checkbox"} htmlFor={"endOrDuration"}>
                                <span
                                    onClick={() => {
                                        this.modifiyInstance(idx, {...item, endOrDuration: item.endOrDuration === 'end' ? 'duration' : 'duration'})
                                    }}>időtartam&nbsp;&nbsp;</span>
                                <div
                                    onClick={() => {
                                        this.modifiyInstance(idx, {...item, endOrDuration: item.endOrDuration === 'end' ? 'duration' : 'end'})
                                    }}
                                    className={`toggle ${item.endOrDuration === 'end' ? "checked" : "unchecked"}`}
                                    >
                                    <div className={"slider"} />
                                </div>
                                <span
                                    onClick={() => {
                                        this.modifiyInstance(idx, {...item, endOrDuration: item.endOrDuration === 'duration' ? 'end' : 'end'})
                                    }}>&nbsp;&nbsp;vége időpont</span>
                            </label>
                        </div>
                    </div>

                    <div className={"full"}>
                        <div className={"label"}>
                            <div>leírás:</div>
                            <div><span style={{fontSize: '.8em', fontWeight: "normal"}}>
                                (csak akkor töltsd ki, ha az alkalom leírása különbözik a program általános rövid leírásától)
                            </span></div>
                        </div>
                        <textarea
                            onChange={e => { this.modifiyInstance(idx, {...item, shortDescription: e.target.value}) }}
                            value={item.shortDescription || ""} />
                    </div>

                    <div className={"small"}>

                    </div>

                    <div className={"big flex"}>
                        <div className={"checkboxes"}>
                            <label className={"checkbox"} htmlFor={"canceled"}>
                                <span
                                    onClick={() => {
                                        this.modifiyInstance(idx, {...item, canceled: ! item.canceled})
                                    }}>Elmarad&nbsp;&nbsp;</span>
                                <div
                                    onClick={() => {
                                        this.modifiyInstance(idx, {...item, canceled: ! item.canceled})
                                    }}
                                    className={`icon ${item.canceled ? "checked" : "unchecked"}`}
                                    />
                            </label>
                        </div>

                        <div className={"checkboxes"}>
                            <label className={"checkbox"} htmlFor={"ticketed"}>
                                <span
                                    onClick={e => {
                                        this.modifiyInstance(idx, {...item, ticketed: ! item.ticketed})
                                    } }
                                >Sorszámos&nbsp;&nbsp;</span>
                                <div
                                    onClick={e => {
                                        this.modifiyInstance(idx, {...item, ticketed: ! item.ticketed})
                                    } }
                                    className={`icon ${item.ticketed ? "checked" : "unchecked"}`} />
                            </label>
                        </div>
                        <div className={"checkboxes"}>
                            <label className={"checkbox"} htmlFor={"changed"}>
                                <span
                                    onClick={e => {
                                        this.modifiyInstance(idx, {...item, changed: ! item.changed})
                                    } }
                                >Változás&nbsp;&nbsp;</span>
                                <div
                                    onClick={e => {
                                        this.modifiyInstance(idx, {...item, changed: ! item.changed})
                                    } }
                                    className={`icon ${item.changed ? "checked" : "unchecked"}`} />
                            </label>
                        </div>
                    </div>

                    {ticketsIfTicketed(item, idx)}

                    <div className={"small"}>
                        <div onClick={this.removeInstance(idx)} className={"icon remove"} />
                    </div>

                </div>
            )
        }

        return (
            <>
                <div><b>hol, mikor:</b></div>
                {instances.map(renderInstance)}
                <div style={{display: "flex"}}>
                    <div onClick={this.addInstance} className={"icon add"} />
                </div>
            </>

        )
    }


    render = () => {

        const { handleSubmit } = this.props

        const creators = this.state.creators !== null ? this.state.creators : []
        const instances = this.state.instances !== null ? this.state.instances : []
        const documents = this.state.documents !== null ? this.state.documents : []
        const image = this.state.image

        const description = _.get(this.props, 'initialValues.description', '<p></p>')
        const shortDescription = _.get(this.props, 'initialValues.shortDescription', '<p></p>')
        const notes = _.get(this.props, 'initialValues.notes', '<p></p>')
        const canonical = _.get(this.props, 'canonical', "")
        const history = _.get(this.props, 'history', [])
        const historyLink = _.get(this.props, 'actions.history.href', null)

        const labelFormat = value => {
            return value
                .map((i, idx, array) => idx < array.length - 1 ? i.trim() : i)
                .join(",")
                .replace(/[ ]+/g, " ")
                .replace(/^[ ]+/, "")
        }
        const labelParse = value => {
            return value.split(",")
        }

        const onLabelSuggestionsFetchRequested = async ({ value }) => {
            const str = (labelParse(value).reverse()[0] || "").trim()
            const result = str.length >= 3 ? await getLabelSuggestions(str) : []
            this.setState({ labelsSuggestions: result })
        }

        const onGenreSuggestionsFetchRequested = async ({ value }) => {
            const str = (labelParse(value).reverse()[0] || "").trim()
            const result = str.length >= 3 ? await getGenreSuggestions(str) : []
            this.setState({ genreSuggestions: result })
        }


        return (
            <div className="edit production">
                <UrlListener onAttemptUrlChange={this.onAttemptUrlChange} />

                <EditTitle>Program oldal szerkesztő</EditTitle>

                <form  onSubmit={handleSubmit(this.onSubmit)}>

                    <table><tbody>

                    <tr>
                        <td style={{width: "5em"}}>cím:</td>
                        <td>
                            <Field
                                placeholder="Cím"
                                name="title"
                                component={renderInput}
                                onChange={(e, newValue) => {
                                    if (this.props.isNew && typeof newValue === 'string') {
                                        const value = _.get(this.props, 'initialValues.canonical', "") + convertToCanonical(newValue)
                                        this.props.dispatch(() => (
                                            new Promise(resolve => {
                                                this.props.dispatch(change(FORM_ID, "canonical", value))
                                                resolve()
                                            }).then(() => this.props.asyncValidate())
                                        ))
                                    }
                                }}
                                type="text" />
                        </td>
                    </tr>

                    <tr>
                        <td>url:</td>
                        <td>
                            <Field
                                disabled={! this.props.isNew}
                                placeholder={""}
                                name={"canonical"}
                                component={renderInputWithIcon}
                                additionalComponent={(
                                    <CopyToClipboard onCopy={() => alert("Vágólapra másolva!")} text={baseUrlCopyBoardRegex(baseUrl) + canonical}>
                                        <img className="copy-to-clipboard" src={copyToClipboard} alt="" />
                                    </CopyToClipboard>
                                )}
                                type={"text"}
                                format={value => ( !! baseUrl ? `${baseUrl}/${value}` : `${value}`)}
                                parse={value => ( !! baseUrl ? value.substr(baseUrl.length + 1) : value)}
                            />

                        </td>
                    </tr>

                    <tr>
                        <td style={{paddingBottom: "8px"}} colSpan={2}>képfeltöltés:</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div className={"image"}>

                                <CKFinder category={"imgs"} onChooseFile={data => {
                                    this.setState({ image: data.path })
                                    this.props.dispatch(change(FORM_ID, 'image', { original: data.path }))
                                }}>
                                    <div className={"icon add"}/>
                                </CKFinder>

                            </div>
                            { image !== null &&
                            <div>
                                <img src={image} alt="" />
                            </div>
                            }
                        </td>
                    </tr>
                    </tbody></table>

                    <div className={"description"}>
                        <CKEditorField
                            label={(
                                <>
                                <div>rövid leírás:</div>
                                <div style={{fontSize: '.8em', fontWeight: "normal", marginBottom: '6px'}}>
                                    (ha egy előadáshoz csak egy leírásod van, akkor a rövidet töltsd ki, a rendszer átmásolja a hosszúba)
                                </div>
                                </>
                            )}
                            data={shortDescription}
                            formId={FORM_ID}
                            name="shortDescription" />
                    </div>

                    <div className={"description"}>
                        <CKEditorField
                            label="hosszú leírás:"
                            data={description}
                            formId={FORM_ID}
                            name="description" />
                    </div>

                    <div className={"creators"}>
                        <div>alkotók:</div>
                        <div>
                            {this.renderCreators(creators)}
                        </div>
                    </div>

                    <table><tbody>
                    <tr>
                        <td style={{width: "7em"}}>
                            műfaj:
                        </td>

                        <td className={"autosuggestion-cell"}>
                            <AutoSuggest
                                suggestions={this.state.genreSuggestions || []}
                                onSuggestionsFetchRequested={onGenreSuggestionsFetchRequested}
                                onSuggestionsClearRequested={() => this.setState({ genreSuggestions: [] })}
                                onSuggestionSelected={(event, { suggestion }) => {
                                    event.preventDefault()
                                    const v = [
                                        ...this.props.genre.splice(0, this.props.genre.length - 1),
                                        suggestion.title
                                    ]
                                    this.props.dispatch(change(FORM_ID, "genre", v))
                                }}
                                getSuggestionValue={suggestion => (suggestion.title)}
                                renderSuggestion={renderSuggestion}
                                renderSuggestionsContainer={renderSuggestionsContainer}
                                alwaysRenderSuggestions={false}
                                shouldRenderSuggestions={(value) => {
                                    return (labelParse(value).reverse()[0] || "").trim().length >= 3
                                }}
                                inputProps={{
                                    type: "text",
                                    value: labelFormat(this.props.genre || []),
                                    onChange: e => {
                                        e.preventDefault()
                                        if (e.target.value) {
                                            const parsed = labelParse(e.target.value)
                                            this.props.dispatch(change(FORM_ID, "genre", parsed))
                                        } else {
                                            this.props.dispatch(change(FORM_ID, "genre", []))
                                        }
                                    }
                                }} />
                            <div>
                                <span className={"hint"}>vesszővel elválaszott lista</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            címkék:
                        </td>
                        <td className={"autosuggestion-cell"}>
                            <AutoSuggest
                                suggestions={this.state.labelsSuggestions || []}
                                onSuggestionsFetchRequested={onLabelSuggestionsFetchRequested}
                                onSuggestionsClearRequested={() => this.setState({ labelsSuggestions: [] })}
                                onSuggestionSelected={(event, { suggestion }) => {
                                    event.preventDefault()
                                    const v = [
                                        ...this.props.labels.splice(0, this.props.labels.length - 1),
                                        suggestion.title
                                    ]
                                    this.props.dispatch(change(FORM_ID, "labels", v))
                                }}
                                getSuggestionValue={suggestion => (suggestion.title)}
                                renderSuggestion={renderSuggestion}
                                renderSuggestionsContainer={renderSuggestionsContainer}
                                alwaysRenderSuggestions={false}
                                shouldRenderSuggestions={(value) => {
                                    return (labelParse(value).reverse()[0] || "").trim().length >= 3
                                }}
                                inputProps={{
                                    type: "text",
                                    value: labelFormat(this.props.labels || []),
                                    onChange: e => {
                                        e.preventDefault()
                                        if (e.target.value) {
                                            const parsed = labelParse(e.target.value)
                                            this.props.dispatch(change(FORM_ID, "labels", parsed))
                                        } else {
                                            this.props.dispatch(change(FORM_ID, "labels", []))
                                        }
                                    }
                                }} />
                            <div>
                                <span className={"hint"}>vesszővel elválaszott lista</span>
                            </div>
                        </td>
                    </tr>
                    </tbody></table>


                    <div className={"instances"}>
                        {this.renderInstances(instances)}
                    </div>


                    <div className={"notices"}>
                        <CKEditorField
                            label={(<>jegyzetek:<br/><small style={{fontWeight: 'normal'}}>(nem publikus mező)</small></>)}
                            data={notes}
                            formId={FORM_ID}
                            name="notes" />
                    </div>


                    <div className={"documents"}>
                        <div>dokumentumok:<br/><small style={{fontWeight: 'normal'}}>(nem publikus mező)</small></div>
                        {documents.map((item, idx) => {

                            return (
                                <div className={"item"} key={idx}>
                                    <div className={"icon paperclip"} />
                                    <div className={""}>{item.name}</div>
                                    <div onClick={() => {
                                        const updated = [
                                            ...this.state.documents.slice(0, idx),
                                            ...this.state.documents.slice(idx + 1)
                                        ]
                                        this.setState({ documents: updated })
                                        this.props.dispatch(change(FORM_ID, 'documents', updated))
                                    }} className={"icon remove"} />
                                </div>
                            )
                        })}
                        <div className={"ckfinder"}>
                            <CKFinder category={"docs"} onChooseFile={data => {
                                const updated = [...this.state.documents, data]
                                this.setState({ documents: updated })
                                this.props.dispatch(change(FORM_ID, 'documents', updated))
                            }}>
                                <div className={"icon add"} />
                            </CKFinder>
                        </div>

                    </div>
                </form>

                <div className="control-buttons">
                    <button className="button green" onClick={handleSubmit(this.onSubmit)}>Mentés</button>
                    <button className="button red" onClick={() => {
                        this.props.dispatch(enableUrlChange(false))
                        const location = this.props.isNew ? "/" : _.get(this.props, 'initialValues.canonical', "/")
                        this.props.dispatch(push(location.replace(/^[/]?(.*)$/, "/$1")))
                    }}>Elvetés</button>
                    { this.props.initialValues.origCanonical &&
                    <button className="button red" onClick={() => {
                        const redirect = () => {
                            this.props.dispatch(enableUrlChange(false))
                            this.props.dispatch(push('/'))
                        }
                        if (window.confirm('Biztos törölni szeretnéd?')) {
                            this.props.dispatch(deleteProduction(this.props.initialValues.origCanonical)).then(redirect, redirect)
                        }
                    }}>Törlés</button>
                    }
                </div>

                { history.length > 0 &&
                <div>
                    {renderHistory(history)}
                    { historyLink !== null && ! this.state.historyMoreClicked &&
                    <div onClick={() => {
                        this.setState({ historyMoreClicked: true })
                        this.props.dispatch(getFullHistory(historyLink))
                    }}>Bővebben</div>
                    }
                </div>
                }
            </div>
        )
    }
}


const validateForm = values => {
    return {
        title: validateField(values.title, [
            Constraints.required
        ]),
        canonical: validateField(values.canonical, [
            Constraints.required,
            Constraints.canonical
        ])
    }
}



const renderSuggestion = suggestion => {
    const thumbnail = isBrowser
        ? _.get(suggestion, 'thumbnail.web', null)
        : _.get(suggestion, 'thumbnail.mobile', null)

    return (
        <div className={`item ${thumbnail !== null ? "with-image" : ""}`}>
            { thumbnail !== null &&
            <div className={"image"}><img src={thumbnail} alt={suggestion.title} /></div>
            }
            <div className={"suggestion-title"}>
                <div>{suggestion.title || ""}</div>
            </div>
        </div>
    )
}

const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
        <>
            <div {...containerProps}>
                <div className={"drop-shadow"}>
                    {children}
                </div>
            </div>
        </>
    );
}

const getSeatsConfiguration = (stage, value, complimentarySeats) => {
    return axios.get(`/api/v1.0/seats-configuration`, { params: { stage: stage, distribution: value, complimentary: complimentarySeats ? complimentarySeats : 0 }}).then(r => r.data, () => [])
}
const getCreatorSuggestions = (value) => {
    return axios.get(`/api/v1.0/ac.tag`, { params: { class: "creator", q: value }}).then(r => r.data, () => [])
}

const getGenreSuggestions = (value) => {
    return axios.get(`/api/v1.0/ac.tag`, { params: { class: "genre", q: value }}).then(r => r.data, () => [])
}

const getVillageSuggestions = (value) => {
    return axios.get(`/api/v1.0/ac.tag`, { params: { class: "village", q: value }}).then(r => r.data, () => [])
}

const getStageSuggestions = (value) => {
    return axios.get(`/api/v1.0/ac.tag`, { params: { class: "stage", q: value }}).then(r => r.data, () => [])
}

const getLabelSuggestions = (value) => {
    return axios.get(`/api/v1.0/ac.tag`, { params: { class: "label", q: value }}).then(r => r.data, () => [])
}

/*
ac.tag?class=menu -> menü edit (edited)
class=creator: az alkotóknál az autocomplete
class=genre: a műfajnál
class=village: alkalom falunál
class=stage: alkalom színpadnál
class=label: címkénél
 */



const selector = formValueSelector(FORM_ID)

export default connect(store => {
    const canonical = _.get(store.base, 'canonicalId', "")
    const data = {
        ...store.base.data,
        canonical: canonical,
        origCanonical: _.get(store.base, 'display', []).findIndex(d => /create/.test(d)) !== -1 ? null : canonical
    }

    const defaultStart = _.get(store.site.data.data, "productionDefaultStart", null)

    return {
        initialValues: data || {},
        canonical: selector(store, 'canonical') || "",
        labels: selector(store, "labels") || [],
        genre: selector(store, 'genre') || [],
        history: _.get(store.base, 'history', []),
        actions: _.get(store.base, 'actions', []),
        productionDefaultStart: defaultStart ? defaultStart : null
    }
})(reduxForm({
    form: FORM_ID,
    enableReinitialize: true,
    validate: validateForm,
    asyncValidate: asyncValidateCanonicalId,
    asyncChangeFields: ['canonical']
})(EditProduction))
