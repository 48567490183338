import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { Field, reduxForm, change } from 'redux-form'

import {CKEditorField, CKFinder, renderInput} from '../form/elements'
import { Constraints, validateField } from '../form/validators'
import MenuEditor from '../MenuEditor'

import {
    readSiteGlobals,
    clearSiteGlobalsEditData,
    enableUrlChange,
    disableUrlChange,
    saveSiteGlobals,
    getFullHistory
} from "../../actions/common";

import UrlListener from '../utils/UrlChangeListener'
import '../../styles/edit-siteglobals.scss'
import {mapTreeNode, safeParseJSON} from "../../utils/common";
import {EditTitle} from "../utils/common";
import {renderHistory} from "./layoutHelpers";


const FORM_ID = "editSiteGlobals"




class EditSiteGlobals extends Component {

    state = {
        bannerWeb: null,
        bannerMobile: null
    }

    componentDidUpdate = prevProps => {

        const { pristine } = this.props

        if (! pristine && prevProps.pristine) {
            this.props.dispatch(disableUrlChange())
        } else if (pristine && ! prevProps.pristine) {
            this.props.dispatch(enableUrlChange(false))
        }

        const bannerWeb = _.get(this.props, 'initialValues.banner.web.original', null)

        if (this.state.bannerWeb === null && bannerWeb !== null) {
            console.log("bannerweb", bannerWeb)
            this.setState({ bannerWeb})
        }
        const bannerMobile = _.get(this.props, 'initialValues.banner.mobile.original', null)
        if (this.state.bannerMobile === null && bannerMobile !== null) {
            this.setState({ bannerMobile})
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearSiteGlobalsEditData())
    }

    onAttemptUrlChange = () => {
        if (window.confirm("unsaved form, leave anyway?")) {
            this.props.dispatch(enableUrlChange(true))
        }
    }

    onSubmit = values => {

        const postprocessMenuUrl = item => ({
            ...item,
                url: item.url !== null && item.url.length > 0 && ! /^http|^mailto/.test(item.url)
                ? item.url.replace(/^[/]*(.+)$/, "/$1")
                : item.url
        })

        const valuesToSend = {
            ...values,
            topMenu: JSON.stringify(mapTreeNode(postprocessMenuUrl, safeParseJSON(values.topMenu, []))),
            bottomMenu: JSON.stringify(mapTreeNode(postprocessMenuUrl, safeParseJSON(values.bottomMenu, [])))
        }

        const { dispatch } = this.props

        dispatch(enableUrlChange(false))
        dispatch(saveSiteGlobals(valuesToSend)).then(
            () => {
                dispatch(push("/"))
                dispatch(readSiteGlobals())
            },
            () => {}
        )
    }

    render = () => {

        const { handleSubmit } = this.props

        const htmlData = _.get(this.props, 'initialValues.footer', '<p></p>')
        const topMenu = _.get(this.props, 'initialValues.topMenu', '[]')
        const bottomMenu = _.get(this.props, 'initialValues.bottomMenu', '[]')
        const history = _.get(this.props, 'history', [])
        const historyLink = _.get(this.props, 'actions.history.href', null)

        const { bannerWeb, bannerMobile } = this.state

        return (
            <div className="edit edit-site-globals">
                <UrlListener onAttemptUrlChange={this.onAttemptUrlChange} />
                <EditTitle>Site beállítások</EditTitle>
                <form  onSubmit={handleSubmit(this.onSubmit)}>

                    <table><tbody>
                    <tr>
                        <td style={{width: "10em"}}>oldal címe:</td>
                        <td>
                            <Field
                                placeholder="Oldal címe"
                                name="siteName"
                                component={renderInput}
                                type="text" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10em"}}>fesztivál dátum:</td>
                        <td>
                            <Field
                                placeholder="Fesztivál"
                                name="festival"
                                component={renderInput}
                                type="text" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10em"}}>általános leírás:</td>
                        <td>
                            <Field
                                name="defaultDescription"
                                component={"textarea"} />
                        </td>
                    </tr>
                    </tbody></table>

                    <div className={"banner-settings"}>
                        <div className={"label"}>banner beállítások:</div>
                        <div className={"banner"}>
                            <table><tbody>
                                <tr>
                                    <td className={"label"}>link:</td>
                                    <td>
                                        <Field
                                            name="banner.href"
                                            component={renderInput}
                                            type="text" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"label"}>web (728x90):</td>
                                    <td>
                                        <CKFinder category={"imgs"} onChooseFile={data => {
                                            this.setState({ bannerWeb: data.path })
                                            this.props.dispatch(change(FORM_ID, 'banner.web.original', data.path))
                                        }}>
                                            <div style={{display: "inline-block"}} className={"icon add"}/>
                                        </CKFinder>
                                        { bannerWeb !== null &&
                                        <div>
                                            <img src={bannerWeb} alt="" />
                                        </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"label"}>mobil (320x50):</td>
                                    <td>
                                        <CKFinder category={"imgs"} onChooseFile={data => {
                                            this.setState({ bannerMobile: data.path })
                                            this.props.dispatch(change(FORM_ID, 'banner.mobile.original', data.path))
                                        }}>
                                            <div style={{display: "inline-block"}} className={"icon add"}/>
                                        </CKFinder>
                                        { bannerMobile !== null &&
                                        <div>
                                            <img src={bannerMobile} alt="" />
                                        </div>
                                        }
                                    </td>
                                </tr>
                            </tbody></table>
                        </div>
                    </div>

                    <div>
                        <MenuEditor
                            data={topMenu}
                            addMainMenuLabel={"főmenü:"}
                            maxLevel={2}
                            onChange={data => {
                                this.props.dispatch(change(FORM_ID, 'topMenu', data))
                            }} />
                    </div>

                    <div>
                        <MenuEditor
                            addMainMenuLabel={"lábléc menü:"}
                            data={bottomMenu}
                            maxLevel={1}
                            onChange={data => {
                                this.props.dispatch(change(FORM_ID, 'bottomMenu', data))
                            }} />
                    </div>

                    <div className={"ckeditor"}>
                        <CKEditorField
                            label="Lábléc:"
                            data={htmlData}
                            formId={FORM_ID}
                            name="footer" />
                    </div>

                </form>

                <div className="control-buttons">
                    <button className="button green" onClick={handleSubmit(this.onSubmit)}>Mentés</button>
                    <button className="button red" onClick={() => {
                        this.props.dispatch(enableUrlChange(false))
                        this.props.dispatch(push("/"))
                    }}>Elvetés</button>
                </div>

                { history.length > 0 &&
                <div className={"history"}>
                    <p>eseménynapló:</p>
                    {renderHistory(history)}
                    { historyLink !== null && ! this.state.historyMoreClicked &&
                    <span className="link" onClick={() => {
                        this.setState({ historyMoreClicked: true })
                        this.props.dispatch(getFullHistory(historyLink))
                    }}>Bővebben</span>
                    }
                </div>
                }
            </div>

        )
    }
}


const validateForm = values => {
    return {
        festival: validateField(values.festival, [
            Constraints.required
        ])
    }
}




export default connect(store => {
    const data = {
        ...store.base.data,
        topMenu: typeof _.get(store, 'base.data.topMenu', "[]") === "string" ? store.base.data.topMenu : '[]',
        bottomMenu: typeof _.get(store, 'base.data.bottomMenu', "[]") === "string" ? store.base.data.bottomMenu : '[]'
    }
    return {
        history: _.get(store.base, 'history', []),
        initialValues: data || {},
    }
})(reduxForm({
    form: FORM_ID,
    enableReinitialize: true,
    validate: validateForm
})(EditSiteGlobals))
