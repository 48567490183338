import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import {clearContentData} from "../../actions/common"

import '../../styles/seats.scss'

import { EditTitle } from "../utils/common"
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

class EditProductionSeats extends Component {

    constructor(props) {
        super(props)
        this.state = {
            seats: props.seats
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }
    
    renderSeats = (item, idx) => {
        return (
            <div key={idx} className={"seat-item"}>
                <div className={"full left"}>
                    <div>
                        <b>{item.title}</b> - {item.village} - {item.stage} {item.time}
                    </div>
                    <div className={"conti"}>
                        {this.ticketsIfTicketed(item, idx)}
                    </div>
                </div>
            </div>
        )
    }

    setSeats = (idx, data) => {
        const updated = [
            ...this.state.seats.slice(0,idx),
            data,
            ...this.state.seats.slice(idx+1)
        ]
        
        this.setState({ seats: updated })
    }

    ticketsIfTicketed = (item, idx) => {
        return (
            <>
                <div className={"full"}>
                    <b>
                        jegyek:
                    </b>
                </div>
                <div className={"quart left"}>
                    <div className={"label"}>tiszteletjegy: 
                    <button 
                        className="button right"
                        onClick={(e) => {
                            e.preventDefault()
                            
                            if(typeof item.complimentarySeats !== "undefined" && item.complimentarySeats !== null && item.complimentarySeats !== "0" && item.complimentarySeats !== 0 && item.complimentarySeats !== "") {
                                axios.post(`/api/v1.0/production-seats/`+item.id, {
                                    appSeats: item.appSeats ? parseInt(item.appSeats) : 0, 
                                    inPersonSeats: item.inPersonSeats ? parseInt(item.inPersonSeats) : 0, 
                                    complimentarySeats: item.complimentarySeats ? item.complimentarySeats.toString() : "0",
                                    print: true
                                }).then(r => {
                                    // r.data.print.map((job) => {
                                        window.location.href = r.data.print
                                    // })
                                    if(r.data.updated.appSeats) {
                                        this.setState({appSeats: r.data.updated.appSeats})
                                        item.appSeats = r.data.updated.appSeats
                                    }
                                    if(r.data.updated.complimentarySeats) {
                                        this.setState({complimentarySeats: r.data.updated.complimentarySeats})
                                        item.complimentarySeats = r.data.updated.complimentarySeats
                                    }
                                    if(r.data.updated.inPersonSeats) {
                                        this.setState({inPersonSeats: r.data.inPersonSeats})
                                        item.inPersonSeats = r.data.updated.inPersonSeats
                                    }
                                    if(r.data.updated.totalSeats) {
                                        this.setState({totalSeats: r.data.updated.totalSeats})
                                        item.totalSeats = r.data.updated.totalSeats
                                    }
                                })
                            }
                        }
                    }>nyomtatás</button></div>
                    <input
                        name={"complimentarySeats"}
                        value={item.complimentarySeats || ""}
                        type={"text"}
                        onChange={e => {
                            this.setSeats(idx, {...item, complimentarySeats: e.target.value})
                        }}
                    />
                </div>
                <div className={"quart left right"}>
                    <div className={"label"}>applikációból:</div>
                    <input
                        name={"appSeats"}
                        value={item.appSeats}
                        type={"number"}
                        min={0}
                        onChange={e => {
                            this.setSeats(idx, {...item, appSeats: parseInt(e.target.value)})
                        }}
                    />
                </div>
                <div className={"quart left right"}>
                    <div className={"label"}>infopulttól:</div>
                    <input
                        name={"inPersonSeats"}
                        value={this.state.inPersonSeats || item.inPersonSeats}
                        type={"number"}
                        min={0}
                        onChange={e => {
                            this.setSeats(idx, {...item, inPersonSeats: parseInt(e.target.value)})
                        }}
                    />
                </div>
                <div className={`quart right ${isNaN(parseInt(item.totalSeats)) ? "" : (parseInt(item.complimentarySeats) + parseInt(item.appSeats) + parseInt(item.inPersonSeats)) > parseInt(item.totalSeats) ? "itsRed" : ""}`}>
                    <div className={"label"}>összesen:</div>
                    <span className={"fakeinput"}>{
                        parseInt(!isNaN(parseInt(this.state.complimentarySeats)) ? this.state.complimentarySeats : !isNaN(parseInt(item.complimentarySeats)) ?  item.complimentarySeats : "0") + 
                        parseInt(!isNaN(parseInt(this.state.appSeats)) ? this.state.appSeats : !isNaN(parseInt(item.appSeats)) ? item.appSeats : 0) + 
                        parseInt(!isNaN(parseInt(this.state.inPersonSeats)) ? this.state.inPersonSeats : !isNaN(parseInt(item.inPersonSeats)) ? item.inPersonSeats : 0)} / {isNaN(parseInt(item.totalSeats)) ? '?' : item.totalSeats}</span>
                </div>
                <div className={`full ${isNaN(parseInt(item.totalSeats)) ? 'disable' : ''}`}>
                    <div className={"label"}>jegyek elosztása:</div>
                    <button 
                        className="button mr-1"
                        onClick={(e) => {
                            e.preventDefault()
                            getSeatsConfiguration(item.stage, "fifty-fifty", item.complimentarySeats).then(r => {
                                this.setSeats(idx, {...item, appSeats: r.appSeats, complimentarySeats: r.complimentarySeats, inPersonSeats: r.inPersonSeats, totalSeats: r.totalSeats})
                            })
                        }}>fele-fele</button>
                    <button 
                        className="button mr-1"
                        onClick={(e) => {
                            e.preventDefault()
                            getSeatsConfiguration(item.stage, "app-only", item.complimentarySeats).then(r => {
                                this.setSeats(idx, {...item, appSeats: r.appSeats, complimentarySeats: r.complimentarySeats, inPersonSeats: r.inPersonSeats, totalSeats: r.totalSeats})
                            }
                        )
                        }}>csak app</button>
                    <button 
                        className="button mr-1"
                        onClick={(e) => {
                            e.preventDefault()
                            getSeatsConfiguration(item.stage, "in-person-only", item.complimentarySeats).then(r => {
                                this.setSeats(idx, {...item, appSeats: r.appSeats, complimentarySeats: r.complimentarySeats, inPersonSeats: r.inPersonSeats, totalSeats: r.totalSeats})
                            })
                        }}>csak infopult</button>
                </div>

                <div className={"full toright"}>
                    <Toaster
                    toastOptions={{
                        className: '',
                        style: {
                            border: '1px solid #713200',
                            color: '#713200',
                            boxShadow: 'none',
                        },
                    }} />
                    <button 
                        className="button mr-1"
                        onClick={(e) => {
                            e.preventDefault()
                            saveSeatsConfiguration(item.id, item).then(r => { 
                                toast(
                                    'Mentés sikeres', {
                                        duration: 1000,
                                        icon: '👏',
                                        position: 'center-right',
                                    }
                                )
                                console.log('MODAL: saved!')
                            })
                        }}>Mentés</button>
                </div>
            </>
        )
    }

    render = () => {

        const { seats } = this.state

        return (
            <div className="edit production-seats">

                <EditTitle>sorszámos előadások</EditTitle>

                <div className={"margin"} />

                <div className={"label"}>lista:</div>
                <div className={"seat-list"}>
                    { seats.map(this.renderSeats) }
                </div>
            </div>

        )
    }
}

const getSeatsConfiguration = (stage, value, complimentarySeats) => {
    return axios.get(`/api/v1.0/seats-configuration`, { params: { stage: stage, distribution: value, complimentary: complimentarySeats ? complimentarySeats : 0 }}).then(r => r.data, () => [])
}

const saveSeatsConfiguration = (id, value) => {
    return axios.post(`/api/v1.0/production-seats/`+id, { appSeats: value.appSeats, inPersonSeats: value.inPersonSeats, complimentarySeats: value.complimentarySeats ? value.complimentarySeats.toString() : "0"})
}

export default connect(store => {
    return {
        seats: _.get(store.base, 'data', []),
        actions: _.get(store.base, 'actions', [])
    }
})(EditProductionSeats)
