import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { change, Field, formValueSelector, reduxForm } from 'redux-form'
import {ChromePicker} from 'react-color'
import { ColoredLabel } from '../utils/common'
import { CKEditorField, renderInput, renderInputWithIcon } from '../form/elements'
import { baseUrlCopyBoardRegex, convertToCanonical } from '../../utils/common'
import AutoSuggest from 'react-autosuggest'
import axios from "axios";

import {
    enableUrlChange,
    disableUrlChange,
    clearContentData,
    getFullHistory,
    getColorPalette,
    saveContentCommon
} from "../../actions/common"

import UrlListener from '../utils/UrlChangeListener'
import { renderHistory } from "./layoutHelpers"
import {EditTitle} from "../utils/common";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {baseUrl} from "../../index";
import copyToClipboard from "../../assets/icons/copy-to-clipboard.svg";
import {isBrowser} from "react-device-detect";

import '../../styles/edit-tag.scss'

const FORM_ID = "editSingleTag"

const getSuggestions = value => {
    if(value) {
        return axios.get(`/api/v1.0/ac.tag`, { params: { class: "stage,village", q: value }}).then(r => r.data, () => [])
    }
}

const renderSuggestion = suggestion => {
    const thumbnail = isBrowser
        ? _.get(suggestion, 'thumbnail.web', null)
        : _.get(suggestion, 'thumbnail.mobile', null)

    return (
        <div className={`item ${thumbnail !== null ? "with-image" : ""}`}>
            { thumbnail !== null &&
            <div className={"image"}><img src={thumbnail} alt={suggestion.title} /></div>
            }
            <div className={"suggestion-title"}>
                <div>{suggestion.title || ""} <b>({suggestion.href || ""})</b></div>
            </div>

        </div>
    )
}

const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
        <>
            <div {...containerProps}>
                <div className={"drop-shadow"}>
                    {children}
                </div>
            </div>
        </>
    );
}

class EditSingleTag extends Component {

    state = {
        selectedBackgroundColor: null,
        selectedForegroundColor: null,
        historyMoreClicked: false,
        value: null,
        suggestions: []
    }

    onSuggestionsFetchRequested = async ({ value }) => {
        if(value.length >= 3) {
            this.setState({
              suggestions: await getSuggestions(value)
            })
        }
    }
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      })
    }

    componentDidMount = () => {
        this.setState({
            selectedBackgroundColor: _.get(this, 'props.initialValues.featuredColor.background', null),
            selectedForegroundColor: _.get(this, 'props.initialValues.featuredColor.foreground', null)
        })
        this.props.dispatch(getColorPalette())
    }

    componentDidUpdate = prevProps => {

        const { pristine } = this.props

        if (! pristine && prevProps.pristine) {
            this.props.dispatch(disableUrlChange())
        } else if (pristine && ! prevProps.pristine) {
            this.props.dispatch(enableUrlChange(false))
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    onAttemptUrlChange = () => {
        if (window.confirm("unsaved form, leave anyway?")) {
            this.props.dispatch(enableUrlChange(true))
        }
    }

    onSubmit = values => {
        this.props.dispatch(enableUrlChange(false))

        const valuesToSend = _.omit(values, ['canonical', 'origCanonical'])
        const maybeUrl = _.get(this, 'props.actions.update.href', "/")
        if (maybeUrl !== null) {
            this.props.dispatch(saveContentCommon(maybeUrl, valuesToSend)).then(
                data => {
                    const redirect = _.get(data, "value.data.href", "/")
                    this.props.dispatch(push(redirect))
                },
                () => {}
            )
        }
    }

    renderParentTitle = () => {
        return (
            <>
                ennek a helyszínnek/falunak a része:
            </>
        )
    }

    renderSeatTitle = () => {
        return (
            <>
                székek száma:
            </>
        )
    }

    renderParentSuggestionField =  (parentTag) => {
        const { value, suggestions } = this.state
        
        return (
            <>
                <AutoSuggest
                    suggestions={suggestions}
                    alwaysRenderSuggestions={false}
                    shouldRenderSuggestions={(value) => {
                        return value.trim().length >= 3
                    }}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={suggestion => suggestion.title}
                    renderSuggestion={renderSuggestion}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    inputProps={{
                        type: "text",
                        placeholder: 'helyszín/falu',
                        value: value ? value : (value === null ? parentTag : ''),
                        onChange: (e, { newValue }) => {
                            this.setState({
                                value: newValue
                            })
                            this.props.dispatch(change(FORM_ID, "parentTag", newValue))
                        }
                    }}
                />
            </>
        )
    }
    
    renderSeatField = (totalAvailableSeats) => {
        const { value } = this.state
        return (
            <Field
                name="totalAvailableSeats"
                component={renderInput}
                type="number"
                min={0}
                parse={value => parseInt(value, 10)}
                inputProps={{
                    value: value ? value : (value === null ? totalAvailableSeats : null),
                    onChange: (e, newValue) => {
                        this.setState({
                            value: parseInt(newValue)
                        })
                        this.props.dispatch(change(FORM_ID, "totalAvailableSeats", parseInt(newValue)))
                    }
                }} />
        )
    }


    renderColorPalette = palette => {

        return (
            <>
                <div>
                    <div className={"color-palette-label"}>címke háttér színe:</div>
                    <div className={"color-selector-wrapper"}>
                        <div className={"palette"}>
                            {[null].concat(palette).map((color, idx) => (
                                <div
                                    key={idx}
                                    style={color !== null ? { backgroundColor: color } : {}}
                                    className={`color pointer ${color === null ? "no-color" : ""}`}
                                    onClick={() => {
                                        this.setState({ selectedBackgroundColor: color })
                                        this.props.dispatch(change(FORM_ID, "featuredColor.background", color))
                                    }} />
                            ))}
                        </div>
                        <ChromePicker
                            disableAlpha={true}
                            color={this.state.selectedBackgroundColor || "#fff"}
                            onChangeComplete={ color => {
                                this.setState({ selectedBackgroundColor: color.hex })
                                this.props.dispatch(change(FORM_ID, "featuredColor.background", color.hex))
                            }} />

                        <div style={{marginLeft: '12px'}}>
                            <ColoredLabel foregroundColor={this.state.selectedForegroundColor} backgroundColor={this.state.selectedBackgroundColor}>
                                előnézet
                            </ColoredLabel>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={"color-palette-label"}>címke szöveg színe:</div>
                    <div className={"color-selector-wrapper"}>
                        <div className={"palette"}>
                            {[null].concat(palette).map((color, idx) => (
                                <div
                                    key={idx}
                                    style={color !== null ? { backgroundColor: color } : {}}
                                    className={`color pointer ${color === null ? "no-color" : ""}`}
                                    onClick={() => {
                                        this.setState({ selectedForegroundColor: color })
                                        this.props.dispatch(change(FORM_ID, "featuredColor.foreground", color))
                                    }} />
                            ))}
                        </div>
                        <ChromePicker
                            disableAlpha={true}
                            color={this.state.selectedForegroundColor || "#fff"}
                            onChangeComplete={ color => {
                                this.setState({ selectedForegroundColor: color.hex })
                                this.props.dispatch(change(FORM_ID, "featuredColor.foreground", color.hex))
                            }} />

                        <div style={{marginLeft: '12px'}}>
                            <ColoredLabel foregroundColor={this.state.selectedForegroundColor} backgroundColor={this.state.selectedBackgroundColor}>
                                előnézet
                            </ColoredLabel>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render = () => {

        const { handleSubmit } = this.props

        const colorable = typeof this.props.display.find(t => t === "colorable") !== "undefined" && this.props.colorPalette.length
        const parentable = typeof this.props.display.find(t => t === "parentable") !== "undefined"
        const seatable = typeof this.props.display.find(t => t === "seatable") !== "undefined"
        const parentTag = _.get(this.props, 'initialValues.parentTag', '')
        const totalAvailableSeats = _.get(this.props, 'initialValues.totalAvailableSeats', '')
        const prequelHtml = _.get(this.props, 'initialValues.prequel', '<p></p>')
        const sequelHtml = _.get(this.props, 'initialValues.sequel', '<p></p>')
        const history = _.get(this.props, 'history', [])
        const historyLink = _.get(this.props, 'actions.history.href', null)
        const canonical = _.get(this.props, 'canonical', "")
        const thumbnail = isBrowser
            ? _.get(this.props, 'initialValues.thumbnail.web', null)
            : _.get(this.props, 'initialValues.thumbnail.mobile', null)

        return (
            <div className="edit edit-single-tag">
                <UrlListener onAttemptUrlChange={this.onAttemptUrlChange} />

                <EditTitle>Gyűjtőoldal szerkesztő</EditTitle>

                <form onSubmit={handleSubmit(this.onSubmit)}>


                    <table><tbody>
                    <tr>
                        <td style={{width: "5em"}}>cím:</td>
                        <td>
                            <Field
                                placeholder="Cím"
                                name="title"
                                component={renderInput}
                                onChange={(e, newValue) => {
                                    if (this.props.isNew && typeof newValue === 'string') {
                                        const value = _.get(this.props, 'initialValues.canonical', "") + convertToCanonical(newValue)
                                        this.props.dispatch(() => (
                                            new Promise(resolve => {
                                                this.props.dispatch(change(FORM_ID, "canonical", value))
                                                resolve()
                                            }).then(() => this.props.asyncValidate())
                                        ))
                                    }
                                }}
                                type="text" />
                        </td>
                    </tr>

                    <tr>
                        <td style={{width: "5em"}}>{ parentable && this.renderParentTitle() }</td>
                        <td>
                            { parentable && this.renderParentSuggestionField(parentTag) }
                        </td>
                    </tr>

                    <tr>
                        <td style={{width: "5em"}}>{ seatable && this.renderSeatTitle() }</td>
                        <td>
                            { seatable && this.renderSeatField(totalAvailableSeats) }
                        </td>
                    </tr>
                    

                    <tr>
                        <td>url:</td>
                        <td>
                            <Field
                                disabled={true}
                                placeholder={""}
                                name={"canonical"}
                                component={renderInputWithIcon}
                                additionalComponent={(
                                    <CopyToClipboard text={baseUrlCopyBoardRegex(baseUrl) + canonical}>
                                        <img className="copy-to-clipboard" src={copyToClipboard} alt="" />
                                    </CopyToClipboard>
                                )}
                                type={"text"}
                                format={value => ( !! baseUrl ? `${baseUrl}/${value}` : `${value}`)}
                                parse={value => ( !! baseUrl ? value.substr(baseUrl.length + 1) : value)}
                            />

                        </td>
                    </tr>
                    <tr>
                        <td />
                        <td>
                            <div>
                                <img src={thumbnail !== null ? thumbnail : "https://musorfuzet-thumbnail.s3-eu-west-1.amazonaws.com/default_328x184.jpg"} alt={""}/>
                            </div>
                        </td>
                    </tr>
                    </tbody></table>

                    <label><b>felső html szerkesztő:</b></label>
                    <div>
                        <CKEditorField
                            data={prequelHtml}
                            formId={FORM_ID}
                            name="prequel" />
                    </div>

                    <div style={{minHeight: "32px"}} />

                    { colorable && this.renderColorPalette(this.props.colorPalette) }

                    <label><b>alsó html szerkesztő:</b></label>
                    <div>
                        <CKEditorField
                            data={sequelHtml}
                            formId={FORM_ID}
                            name="sequel" />
                    </div>
                </form>

                <div className="control-buttons">
                    <button className="button green" onClick={handleSubmit(this.onSubmit)}>Mentés</button>
                    <button className="button red" onClick={() => {
                        this.props.dispatch(enableUrlChange(false))
                        this.props.dispatch(push(_.get(this, 'props.actions.cancel.href', "/")))
                    }}>Elvetés</button>
                </div>

                { history.length > 0 &&
                <div className={"history"}>
                    <p>eseménynapló:</p>
                    {renderHistory(history)}
                    { historyLink !== null && ! this.state.historyMoreClicked &&
                    <span className="link" onClick={() => {
                        this.setState({ historyMoreClicked: true })
                        this.props.dispatch(getFullHistory(historyLink))
                    }}>Bővebben</span>
                    }
                </div>
                }

            </div>

        )
    }
}

const selector = formValueSelector(FORM_ID)

export default connect(store => {
    const canonical = _.get(store.base, 'canonicalId', "")
    const data = {
        ...store.base.data,
        canonical: canonical,
        origCanonical: _.get(store.base, 'display', []).findIndex(d => /create/.test(d)) !== -1 ? null : canonical
    }

    return {
        initialValues: data || {},
        display: store.base.display || [],
        canonical: selector(store, 'canonical') || "",
        history: _.get(store.base, 'history', []),
        actions: _.get(store.base, 'actions', []),
        colorPalette: store.common.colorPalette || []
    }

})(reduxForm({
    form: FORM_ID,
    enableReinitialize: true
})(EditSingleTag))
