
const defaultState = {
    searchText: ""
}

export default (state = defaultState, action) => {

    switch (action.type) {

        case "search/UPDATE_TEXT":
            return {...state, searchText: action.payload}

        default:
            return { ...state }
    }
}