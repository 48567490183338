import React, {Component} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import {push} from 'connected-react-router'
import {reduxForm} from 'redux-form'

import {CKEditorField} from '../form/elements'

import {save404Content, enableUrlChange, disableUrlChange, clearContentData, getFullHistory} from "../../actions/common"

import UrlListener from '../utils/UrlChangeListener'
import {EditTitle} from "../utils/common"
import {renderHistory} from "./layoutHelpers";

const FORM_ID = "editNotFound"


class EditNotFound extends Component {

    componentDidUpdate = prevProps => {

        const {pristine} = this.props

        if (!pristine && prevProps.pristine) {
            this.props.dispatch(disableUrlChange())
        } else if (pristine && !prevProps.pristine) {
            this.props.dispatch(enableUrlChange(false))
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    onAttemptUrlChange = () => {
        if (window.confirm("unsaved form, leave anyway?")) {
            this.props.dispatch(enableUrlChange(true))
        }
    }

    onSubmit = values => {

        const {dispatch} = this.props

        dispatch(enableUrlChange(false))
        dispatch(save404Content(values.content)).then(
            data => this.props.dispatch(push(_.get(data, "value.data.href", "/x/404"))),
            e => {
            }
        )
    }

    render = () => {

        const {handleSubmit} = this.props

        const history = _.get(this.props, 'history', [])
        const historyLink = _.get(this.props, 'actions.history.href', null)

        const htmlData = _.get(this.props, 'initialValues.content', '<p></p>')

        return (
            <div className="edit">
                <UrlListener onAttemptUrlChange={this.onAttemptUrlChange}/>

                <EditTitle>404 oldal</EditTitle>

                <form onSubmit={handleSubmit(this.onSubmit)}>
                    <div>
                        <CKEditorField
                            data={htmlData}
                            formId={FORM_ID}
                            name="content"/>
                    </div>
                </form>

                <div className="control-buttons">
                    <button className="button green" onClick={handleSubmit(this.onSubmit)}>Mentés</button>
                    <button className="button red" onClick={() => {
                        this.props.dispatch(enableUrlChange(false))
                        this.props.dispatch(push("/"))
                    }}>Elvetés
                    </button>
                </div>


                {history.length > 0 &&
                <div>
                    {renderHistory(history)}
                    {historyLink !== null && !this.state.historyMoreClicked &&
                    <div onClick={() => {
                        this.setState({historyMoreClicked: true})
                        this.props.dispatch(getFullHistory(historyLink))
                    }}>Bővebben</div>
                    }
                </div>
                }

            </div>

        )
    }
}


export default connect(store => {
    const data = {
        ...store.base.data,
    }

    return {
        history: _.get(store.base, 'history', []),
        initialValues: data || {}
    }
})(reduxForm({
    form: FORM_ID,
    enableReinitialize: true,
})(EditNotFound))
