import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { push } from 'connected-react-router'
import axios from 'axios'
import ticketIcon from '../assets/icons/ticket.svg'

import '../styles/toolbar.scss'

import {logout, publishUnpublish, readContent} from '../actions/common'

const SUBMENU_NONE = Symbol("none")
const SUBMENU_ADD = Symbol("add")
const SUBMENU_SETTINGS = Symbol("settings")

class Toolbar extends Component {


    timeout = null

    constructor(props) {
        super(props)

        this.state = {
            display: [],
            toolbar: {},
            grabing: false,
            submenu: null,
            settingsType: 'normal',
            optionWithSubmenu: SUBMENU_NONE,

            activeSubmenu: SUBMENU_NONE,
            overButton: SUBMENU_NONE,
            overSubmenu: SUBMENU_NONE,

            position: { x: 50, y: 50 },
            grabStartPosition: null,
            grabMouseStartPosition: null,

            showToolbar: false
        }
    }

    componentDidMount = () => {
        const { user } = this.props
        if (user.user !== null) this.setState({ showToolbar: true })
    }

    componentWillMount = () => {
        
        // React.initializeTouchEvents(true);
    }

    componentDidUpdate = prevProps => {
        const { display, toolbar, user } = this.props

        if (! _.isEqual(display, this.state.display) || ! _.isEqual(toolbar, this.state.toolbar)) {
            this.setState({ display: display, toolbar: toolbar })
        }

        if (user.user !== null && prevProps.user.user === null) {
            this.setState({ showToolbar: true })
        } else if (user.user === null && prevProps.user.user !== null) {
            this.setState({ showToolbar: false })
        }
    }


    clickHandler = url => {

        const { dispatch } = this.props
        if (/editors.logout/.test(url)) {
            if (this.props.api) {
              this.props.api.revoke()
            } else {
              console.warn("failed to logout google")
            }
            axios.get(url).then(
                r => dispatch(push(_.get(r, "value.data.href", "/"))),
                () => dispatch(push("/"))
            )
        } else if (/^\/api\//.test(url)) {
            axios.get(url).then(
                r => (dispatch(push(_.get(r, "value.data.href", "/")))),
                () => dispatch(push("/"))
            )
        } else {
            dispatch(push(url))
        }

    }


    settingsButtonOnClick = (menuType) => {
        const { toolbar, optionWithSubmenu } = this.state

        const menutype = menuType === 'ticketed' ? 'ticketed' : 'normal'

        const settingsChange = () => {
            return this.state.settingsType === menuType ? false : true;
        }
        

        if (optionWithSubmenu !== SUBMENU_SETTINGS || settingsChange()) {
            const siteGlobals = _.get(toolbar, 'site-globals.href', null)
            const manageEditors = _.get(toolbar, 'manage-editors.href', null)
            const spreadsheets = _.get(toolbar, 'spreadsheets.href', null)
            const productionSeats = _.get(toolbar, 'production-seats.href', null)
            const visitorsDebug = _.get(toolbar, 'visitors.href', null)
            const broadcasts = _.get(toolbar, 'broadcasts.href', null)
            const messaging = _.get(toolbar, 'messaging.href', null)
            const ticketStats = _.get(toolbar, 'ticket-stats.href', null)

            const hasSubmenu = siteGlobals !== null || manageEditors !== null || spreadsheets !== null || productionSeats !== null || visitorsDebug !== null || broadcasts !== null
            if (hasSubmenu) {
                const submenu = (
                    <>
                        {siteGlobals !== null && menutype === 'ticketed' &&
                        <div className="item pointer" onClick={this.submenuOnClick(broadcasts)}>üzenetküldés (látogatóknak)</div>}
                        {productionSeats !== null && menutype === 'ticketed' &&
                        <div className="item pointer" onClick={this.submenuOnClick(productionSeats)}>sorszámos előadások</div>}
                        {visitorsDebug !== null && menutype === 'ticketed' &&
                        <div className="item pointer" onClick={this.submenuOnClick(visitorsDebug)}>látogatók</div>}
                        {siteGlobals !== null && menutype === 'ticketed' &&
                        <div className="item pointer" onClick={this.submenuOnClick(ticketStats)}>statisztikák</div>}

                        {siteGlobals !== null && menutype === 'normal' &&
                        <div className="item pointer" onClick={this.submenuOnClick(messaging)}>üzenetküldés (infopultoknak)</div>}
                        {spreadsheets !== null && menutype === 'normal' &&
                        <div className="item pointer" onClick={this.submenuOnClick(spreadsheets)}>spreadsheetek</div>}
                        {manageEditors !== null && menutype === 'normal' &&
                        <div className="item pointer" onClick={this.submenuOnClick(manageEditors)}>szerkesztők/infopultok</div>}
                        {siteGlobals !== null && menutype === 'normal' &&
                        <div className="item pointer" onClick={this.submenuOnClick(siteGlobals)}>beállítások</div>}
                    </>
                )
                this.setState({
                    submenu,
                    optionWithSubmenu: SUBMENU_SETTINGS
                })
            } else {
                this.setState({
                    submenu: null,
                    optionWithSubmenu: SUBMENU_NONE
                })
            }
        } else {
            this.setState({
                submenu: null,
                optionWithSubmenu: SUBMENU_NONE
            })
        }
    }

    submenuOnClick = path => () => {
        this.props.dispatch(push(path))
        this.setState({
            submenu: null,
            optionWithSubmenu: SUBMENU_NONE
        })
    }


    addButtonOnClick = () => {
        const { toolbar, optionWithSubmenu } = this.state

        if (optionWithSubmenu !== SUBMENU_ADD) {
            const addStatic = _.get(toolbar, 'add-static.href', null)
            const addNews = _.get(toolbar, 'add-news.href', null)
            const addProd = _.get(toolbar, 'add-production.href', null)

            const hasSubmenu = addStatic !== null && addNews !== null && addProd !== null

            if (hasSubmenu) {
                const submenu = (
                    <>
                        { addStatic !== null && <div className="item pointer" onClick={this.submenuOnClick(addStatic)}>Új statikus oldal</div> }
                        { addNews !== null && <div className="item pointer" onClick={this.submenuOnClick(addNews)}>Új hír oldal</div> }
                        { addProd !== null && <div className="item pointer" onClick={this.submenuOnClick(addProd)}>Új előadás</div> }
                    </>
                )
                this.setState({
                    submenu,
                    optionWithSubmenu: SUBMENU_ADD
                })
            } else {
                this.setState({
                    submenu: null,
                    optionWithSubmenu: SUBMENU_NONE
                })
            }
        } else {
            this.setState({
                submenu: null,
                optionWithSubmenu: SUBMENU_NONE
            })
        }
    }

    overlayOnClick = () => {
        this.setState({
            submenu: null,
            optionWithSubmenu: SUBMENU_NONE
        })
    }

    editOnClick = () => {

        const path = _.get(this, 'state.toolbar.edit.href', null)
        if (path !== null) {
            this.props.dispatch(push(path))
        }

        this.setState({
            submenu: null,
            optionWithSubmenu: SUBMENU_NONE
        })
    }

    publishOnClick = () => {

        const maybePublishing = _.get(this, "state.toolbar.publishing.href", null)


        const refresh = () => {
            const { location } = this.props
            const matches = location.search.match(/[&?]past=([^&]+)/)
            const params = matches !== null
                ? { past: matches[1] }
                : {}

            this.props.dispatch(readContent(location.pathname, params))
        }

        if (maybePublishing !== null) {
            this.props.dispatch(publishUnpublish(maybePublishing)).then(refresh, refresh)
        }

        this.setState({
            submenu: null,
            optionWithSubmenu: SUBMENU_NONE
        })
    }

    logoutOnClick = () => {
        this.props.dispatch(logout())
        const path = _.get(this, 'state.toolbar.logout.href', null)
        if (path !== null) {
            this.clickHandler(path)
            //this.props.dispatch(push(path))
        }
        this.setState({
            submenu: null,
            optionWithSubmenu: SUBMENU_NONE
        })
    }


    startDrag = e => {
        this.setState({
            grabing: true,
            grabMouseStartPosition: {x: (isNaN(e.clientX) ? e.touches[0].clientX : e.clientX), y: (isNaN(e.clientY) ? e.touches[0].clientY : e.clientY)},
            grabStartPosition: this.state.position
        })
    }

    stopDrag = () => {
        this.setState({grabing: false, grabMouseStartPosition: null, grabStartPosition: null})
    }

    onDrag = e => {
        const { grabing, grabMouseStartPosition, grabStartPosition } = this.state
        if (grabing) {
            this.setState({
                position: {
                    x: grabStartPosition.x + ((isNaN(e.clientX) ? e.touches[0].clientX : e.clientX) - grabMouseStartPosition.x),
                    y: grabStartPosition.y + ((isNaN(e.clientY) ? e.touches[0].clientY : e.clientY) - grabMouseStartPosition.y),
                }
            })
        }
    }

    render = () => {
        if (! this.props.hasToolbar || ! this.state.showToolbar) return null

        if (! this.props.user.user) return null

        const userImage = () => {
          const src = _.get(this.props, 'user.user.avatar')
          return src ? <img src={src} alt={""} /> : null
        }


        const { grabing, display, submenu, optionWithSubmenu, position } = this.state

        const disableButtonClass = key => {
            return disabledButton(key) ? "" : "disabled"
        }

        const disabledButton = key => Object.keys(this.state.toolbar).includes(key)

        const publishedClass = () => {
            if (display.includes("published")) return "published"
            else if (display.includes("unpublished")) return "unpublished"
            else return ""
        }

        const { x, y } = position
        const style = {
            position: 'fixed',
            left: `${x}px`,
            top: `${y}px`
        }

        const header = (
            <div
                style={{position: 'relative'}}
                className={`toolbar-header ${grabing ? "grabing" : ""}`}
                onTouchStart={this.startDrag}
                onTouchEnd={this.stopDrag}
                onTouchMove={this.onDrag}

                onMouseDown={this.startDrag}
                onMouseUp={this.stopDrag}
                onMouseMove={this.onDrag}
            >
                {[...new Array(7)].map((a, idx) => (<div key={idx} />))}
            </div>
        )

        const content = (
            <>
                { grabing &&
                <div
                    className={"toolbar-underlay grabing"}
                    onTouchStart={this.startDrag}
                    onTouchEnd={this.stopDrag}
                    onTouchMove={this.onDrag}

                    onMouseUp={this.stopDrag}
                    onMouseLeave={this.stopDrag}
                    onMouseMove={this.onDrag} />
                }
                { optionWithSubmenu !== SUBMENU_NONE && <div className={"toolbar-underlay"} onClick={this.overlayOnClick} /> }

                <div className={"toolbar-icons"}>
                    <div onClick={this.editOnClick} className={`icon edit small ${disableButtonClass("edit")}`} />

                    <div
                        onClick={() => {
                            if (! disabledButton('publish')) this.publishOnClick()
                        }}
                        className={`icon publish large ${disableButtonClass("publishing")}`}
                    >
                        <div className={`bg ${publishedClass()}`}>
                            <div></div>
                        </div>
                    </div>

                    <div
                        className={`icon add small ${optionWithSubmenu === SUBMENU_ADD ? "active" : ""}`}
                        onClick={this.addButtonOnClick} />

                    <div
                        className={`icon settings small ${optionWithSubmenu === SUBMENU_SETTINGS ? (this.state.settingsType === "normal" ? "active" : "") : ""}`}
                        onClick={() => {
                            this.setState({settingsType: "normal"})
                            this.settingsButtonOnClick('normal');
                        }} />

                    <div
                        className={`icon settings ticketed small ${optionWithSubmenu === SUBMENU_SETTINGS ? (this.state.settingsType === "ticketed" ? "active" : "") : ""}`}
                        onClick={() => {
                            this.setState({settingsType: "ticketed"})
                            this.settingsButtonOnClick('ticketed')
                        }} >
                        <img className="toolbarIcon" src={ticketIcon} alt={""} />
                    </div>


                    <div onClick={this.logoutOnClick} className={"icon logout large"}>
                        <div>{userImage()}</div>
                        <div />
                    </div>
                </div>

                { submenu !== null &&
                <div className="toolbar-sub-menu">{submenu}</div>
                }
            </>
        )

        return  (
            <div style={style} className={"toolbar-container"}>
                {header}
                {content}
            </div>
        )
    }
}



Toolbar = connect(store => {
    return {
        hasToolbar: store.base.hasOwnProperty("toolbar"),
        display: _.get(store, 'base.display', []),
        toolbar: _.get(store, 'base.toolbar', {}),
        googleAuth: _.get(store, 'common.googleAuth', null),
        location: store.router.location,
        user: store.user,
        api: store.common.googleAccountApi,
    }
})(Toolbar)

export default Toolbar