import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { clearContentData } from "../../actions/common"
import InnerHtml from "../utils/InnerHtmlWrapper"
import { push } from "connected-react-router"
import { isBrowser } from "react-device-detect"

import '../../styles/tag.scss'
import { ColoredLabel } from "../utils/common"
import { addImageClickEventListener, removeImageClickEventListener } from '../../utils/common'
import statusCanceled from "../../assets/icons/prod-canceled.svg";
import statusTicketed from "../../assets/icons/prod-ticketed.svg";
import statusChanged from "../../assets/icons/prod-changed.svg";
import Lightbox from 'react-image-lightbox'


const removeAccent = str => (
    str
        .trim()
        .toLowerCase()
        .replace(/( )+/, " ")
        .replace(/ö|ó|ő/g, "o")
        .replace(/ü|ú|ű/g, "u")
        .replace(/á/g, "a")
        .replace(/é/g, "e")
        .replace(/í/g, "i")
)

class ReadSingleTag extends React.Component {

    state = {
        searchText: "",
        lightboxOpen: false,
        imageIndex: 0
    }

    componentDidUpdate = prevProps => {
        const newImageList = _.get(this.props, 'lightbox', [])
        const prevImageList = _.get(prevProps, 'lightbox', [])

        if (! _.isEqual(newImageList, prevImageList)) {
            removeImageClickEventListener('.single-tag', prevImageList, this.imageClick)
            addImageClickEventListener('.single-tag', newImageList, this.imageClick)
        }
    }

    imageClick = event => {
        const imageList = _.get(this.props, 'lightbox', [])
        const index = imageList.findIndex(p => p === event.target.src)
        this.setState({
            lightboxOpen: true,
            imageIndex: index !== -1 ? index : 0
        })
    }

    componentDidMount = () => {
        const lightboxList = _.get(this.props, 'lightbox', [])
        addImageClickEventListener('.single-tag', lightboxList, this.imageClick)
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
        const lightboxList = _.get(this.props, 'lightbox', [])
        removeImageClickEventListener('.single-tag', lightboxList, this.imageClick)
    }

    navigateTo = href => e =>  {
        e.preventDefault()
        if (href && href.length)
            this.props.dispatch(push(href))
    }

    processLevels = (levels = []) => {

        const searchTextArray = this.state.searchText.length ? removeAccent(this.state.searchText).split(" ") : []

        const textFilter = item => {
            if (searchTextArray.length) {
                const keywords = item.keywords && item.keywords.length
                    ? removeAccent(item.keywords.replace(/(<([^>]+)>)/ig, "")).split(" ") : []

                const description = item.description && item.description.length
                    ? removeAccent(item.description.replace(/(<([^>]+)>)/ig, "")).split(" ") : []

                const title = item.title && item.title.length
                    ? removeAccent(item.title).split(" ") : []

                const time = item.time && item.time.name && item.time.name.length
                    ? removeAccent(item.time.name).split(" ") : []

                const village = item.village && item.village.name && item.village.name.length
                    ? removeAccent(item.village.name).split(" ") : []

                const stage = item.stage && item.stage.name && item.stage.name.length
                    ? removeAccent(item.stage.name).split(" ") : []

                const labels = _.flatten(item.labels.map(i => i.name && i.name.length ? [removeAccent(i.name)] : []))
                const exists = (haystack, needles = [], ret = true) => {

                    if (needles.length) {
                        const regExp = new RegExp(removeAccent(needles[0]), "i")
                        const found = typeof haystack.find(i => regExp.test(i)) !== "undefined"
                        return found && needles.length > 1 ? exists(haystack, needles.slice(1)) : found
                    } else
                        return ret
                }

                return exists(_.flatten([keywords, description, title, time, village, stage, labels]), searchTextArray)
            } else {
                return [item]
            }
        }

        const mapLevel = levelItem => {

            if (levelItem && ! levelItem.hasOwnProperty("productionId")) {
                const children = _.get(levelItem, 'children', [])
                if (children.length > 0 && children[0].hasOwnProperty("productionId")) {
                    const filtered = children.filter(textFilter)
                    return filtered.length
                        ? [{ ...levelItem, children: filtered }]
                        : []
                } else if (children.length > 0) {
                    const newChildren = _.flatten(children.map(mapLevel))
                    return newChildren.length > 0
                        ? [{...levelItem, children: newChildren }]
                        : []
                } else {
                    return []
                }
            } else {
                return textFilter(levelItem) ? [levelItem] : []
            }
        }
        return _.flatten(levels.map(mapLevel))
    }

    renderLabels = (item, idx) => {
        return (
            <ColoredLabel key={idx} onClick={this.navigateTo(item.href)} foregroundColor={item.featuredColor.foreground} backgroundColor={item.featuredColor.background} haveHref={item.href ? true : false}><a href={item.href}>{item.name}</a></ColoredLabel>
        )
    }

    renderLevel = (level, list = []) => {

        return list.map((item, idx) => {
            if (level > 0) {
                const name = _.get(item, 'title.name',  null)
                const href = _.get(item, 'title.href',  null)

                return (
                    <React.Fragment key={idx}>
                        <div key={idx} className={`level-${level}`}>
                            <h3 className={`${href !== null ? "pointer tag-link" : ""}`} onClick={this.navigateTo(href)}>
                                { href
                                    ? (<a href={href}>{name}</a>)
                                    : (<>{name}</>)
                                }
                            </h3>
                        </div>
                        {this.renderLevel(level - 1, item.children)}
                    </React.Fragment>
                )
            } else {
                const thumbnail = isBrowser
                    ? _.get(item, 'thumbnail.web', null)
                    : _.get(item, 'thumbnail.mobile', null)

                const maybeTimeHref = _.get(item, 'time.href', "")
                const maybeTime = _.get(item, 'time.name', undefined)

                const maybeTitleHref = item.href
                const maybeTitle = item.title

                const maybeStageHref = _.get(item, 'stage.href', "")
                const maybeStage = _.get(item, 'stage.name', undefined)

                const maybeVillageHref = _.get(item, 'village.href', "")
                const maybeVillage = _.get(item, 'village.name', undefined)

                const isPartial = _.get(item, 'partial', false)
                const isDraft = _.get(item, 'draft', false)

                const title = [
                    [maybeTitle, maybeTitleHref, true],
                    [maybeTime, maybeTimeHref, true],
                    [maybeVillage, maybeVillageHref, false],
                    [maybeStage, maybeStageHref, false]
                ].map((i, idx, array) => {
                    const [ maybeName, maybeHref, isBold ] = i

                    if (maybeName) {
                        return (
                            <React.Fragment key={idx}>
                                <a href={maybeHref}
                                    className={`underline no-underline-hover ${isBold ? "bold" : ""} ${maybeHref.length > 0 ? "underline pointer" : ""}`}
                                    onClick={this.navigateTo(maybeHref)}>{maybeName}
                                </a>
                                { array[idx + 1] && array[idx + 1][0] && (<>&nbsp;- </>)}
                            </React.Fragment>
                        )
                    } else {
                        const hasValidBefore = () => {
                            const maybeContent = array
                                .slice(0, idx)
                                .find(i => typeof i[0] !== "undefined")
                            return typeof maybeContent !== "undefined"
                        }
                        return hasValidBefore()
                            ? (
                                <React.Fragment key={idx}>
                                    { array[idx + 1] && array[idx + 1][0] && (<>&nbsp;- </>)}
                                </React.Fragment>
                            )
                            : null
                    }
                })

                return (
                    <div key={idx} className={`level-${level}`}>
                        { maybeTitleHref && <div className={"link-icon"} onClick={this.navigateTo(maybeTitleHref)} /> }
                        <h3>
                            {title}
                        </h3>
                        <div className={`production-container ${thumbnail !== null ? 'with-image' : ""}`}>
                            { thumbnail !== null &&
                            <a href={maybeTitleHref} className={`image pointer`} onClick={this.navigateTo(maybeTitleHref)}>
                                { isPartial && <div className={"exclamation"} /> }
                                { isDraft && <div className={"stop"} /> }
                                <img src={thumbnail} alt={""}/>
                            </a>
                            }
                            { thumbnail == null && (isPartial || isDraft) &&
                            <div className={`image small`}>
                                { isPartial && <div className={"exclamation"} /> }
                                { isDraft && <div className={"stop"} /> }
                            </div>
                            }
                            <div className="info">
                                <div className={"description"}>
                                    <InnerHtml html={_.get(item, 'description', "")} />
                                </div>
                                <div className={"labels"}>
                                    {(item.labels || []).map(this.renderLabels)}
                                </div>
                                <div className={"labels"}>
                                    {item.canceled && item.canceled.href && <a href={item.canceled.href} className={"prod-status pointer"} onClick={this.navigateTo(item.canceled.href)}>
                                        <img className={"status-img"} src={statusCanceled} alt={""} />
                                    </a>}
                                    {item.ticketed && item.ticketed.href && <a href={item.ticketed.href} className={"prod-status pointer"} onClick={this.navigateTo(item.ticketed.href)}>
                                        <img className={"status-img"} src={statusTicketed} alt={""} />
                                    </a>}
                                    {item.changed && item.changed.href && <a href={item.changed.href} className={"prod-status pointer"} onClick={this.navigateTo(item.changed.href)}>
                                        <img className={"status-img"} src={statusChanged} alt={""} />
                                    </a>}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })
    }

    handleSearch = e => {
        this.setState({ searchText: e.target.value })
    }

    render = () => {

        const { data, display } = this.props

        const maybeCenterpiece = display.find(d => /^centerpiece:/.test(d))
        const level = maybeCenterpiece
            ? _.flatten(
                maybeCenterpiece
                    .replace(/^[^:]+:(.*$)/, "$1")
                    .split("|")
                    .map(i => (i.length <= 0 ? [] : [i]))
            ).length
            : null

        const prequel = _.get(data, 'prequel', '')
        const sequel = _.get(data, 'sequel', '')
        const title = _.get(data, 'title', '')

        const images = _.get(this.props, 'lightbox', [])
        const { imageIndex } = this.state

        const renderCenterpiece = () => {

            if (data.centerpiece.length <= 0) {
                return (<p style={{paddingTop: "20px"}}>Itt most még nincs program - de ha lesz, ide kerül majd, úgyhogy térj vissza később!</p>)
            } else if (level !== null) {
                return (
                    <div className={"levels"}>
                        {this.renderLevel(level, this.processLevels(data.centerpiece))}
                    </div>
                )
            } else {
                return null
            }
        }

        return (
            <>
                <div className={"static-content single-tag tag read"}>

                    <h1>{title}</h1>

                    <div>
                        <InnerHtml html={prequel} />
                    </div>

                    <div className={"search-wrapper"}>
                        <input
                            placeholder={"Keresés ezen az oldalon"}
                            type={"text"}
                            onChange={this.handleSearch}
                            value={this.state.searchText} />
                    </div>

                    {renderCenterpiece()}

                    <div>
                        <InnerHtml html={sequel} />
                    </div>
                </div>
                { this.state.lightboxOpen && images.length > 0 &&
                <Lightbox
                    mainSrc={images[imageIndex]}
                    nextSrc={images[(imageIndex + 1) % images.length]}
                    prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => {
                        this.setState({ lightboxOpen: false })
                    }}
                    onMovePrevRequest={() =>
                        this.setState({
                            imageIndex: (imageIndex + images.length - 1) % images.length,
                        })
                    }
                    onMoveNextRequest={() => {
                        this.setState({
                            imageIndex: (imageIndex + 1) % images.length,
                        })
                    }} />
                }
            </>
        )
    }
}


export default connect(store => {
    return {
        data: store.base.data,
        display: store.base.display,
        lightbox: store.base.lightbox
    }
})(ReadSingleTag)