
const defaultState = {
    initialized: false,
    user: null,
    jwt: null
}

export default (state = defaultState, action) => {

    switch (action.type) {

        case "user/SET_JWT":
            return {...state, jwt: action.payload }

        case "user/LOGOUT":
            return {...state, initialized: false, user: null }

        case "user/LOGIN_FULFILLED":
            return {...state}

        case "user/LOGIN_REJECTED":
            return {...state, initialized: true}

        case "user/CHECK_FULFILLED":
            return { ...state, user: action.payload.data, initialized: true }

        case "user/RESET_INIT":
            return { ...state, initialized: false }

        default:
            return { ...state }
    }
}