import React from 'react'
import {blur, change, Field} from "redux-form"
import CKEditor from "../utils/CKEditorWrapper"
import { connect } from 'react-redux'
import DatePicker from "react-datepicker"
import _ from 'lodash'



export const renderInput = ({
                          disabled,
                          input,
                          label,
                          type,
                          placeholder,
                          additionalComponent = null,
                          meta: { touched, error, warning },
                          min
                      }) => {


    const disabledValue = typeof disabled !== 'undefined' ? disabled : false

    return (
        <div>
            { label && <label>{label}</label> }
            <div>
                <input
                    {...input}
                    disabled={disabledValue}
                    placeholder={placeholder ? placeholder : label}
                    min={min}
                    type={type} />

                { additionalComponent !== null && additionalComponent }
            </div>
            {touched &&
            ((error && <span className={"error"}>{error}</span>) ||
                (warning && <span>{warning}</span>))}
        </div>
    )

}


export const renderInputWithIcon = ({
                                        disabled,
                                        input,
                                        label,
                                        type,
                                        placeholder,
                                        additionalComponent = null,
                                        meta: { touched, error, warning }
                                    }) => {


    const disabledValue = typeof disabled !== 'undefined' ? disabled : false


    const getClass = () => {
        if (touched) {
            return !! error ? "invalid" : "valid"
        } else {
            return "untouched"
        }
    }



    return (
        <div>
            { label && <label>{label}</label> }
            <div className={`input-with-icon ${getClass()}`}>
                <input
                    {...input}
                    disabled={disabledValue}
                    placeholder={placeholder ? placeholder : label}
                    type={type} />

                { additionalComponent !== null && additionalComponent }
            </div>
        </div>
    )

}


export const renderCheckbox = ({
                                   input,
                                   disabled,
                                   label,
                                   type,
                                   meta: { touched, error, warning }
                               }) => {
    const disabledValue = typeof disabled !== 'undefined' ? disabled : false
    return (
        <div>
            <label>{label}&nbsp;
                <input disabled={disabledValue} {...input} placeholder={label} type={type} />
                {touched &&
                ((error && <span>{error}</span>) ||
                    (warning && <span>{warning}</span>))}
            </label>
        </div>
    )
}


/**
 *  <Field
 *      label="Select test"
 *      name="aa"
 *      component={renderSelect}>
 *          <option value="">...</option>
 *          <option value="#ff0000">Red</option>
 *          <option value="#00ff00">Green</option>
 *          <option value="#0000ff">Blue</option>
 *  </Field>
 * @param input
 * @param label
 * @param type
 * @param children
 * @param touched
 * @param error
 * @param warning
 * @returns {*}
 */
export const renderSelect = ({
                                 input,
                                 disabled,
                                 label,
                                 type,
                                 children,
                                 meta: { touched, error, warning }
                             }) => {

    const disabledValue = typeof disabled !== 'undefined' ? disabled : false

    return (
        <div>
            <label>{label}&nbsp;</label>

                <select {...input} disabled={disabledValue}>
                    {children}
                </select>
                {touched &&
                ((error && <span>{error}</span>) ||
                    (warning && <span>{warning}</span>))}
        </div>
    )
}


export const renderDatetimePicker = ({
                                         input,
                                         disabled,
                                         label,
                                         type,
                                         meta: { touched, error, warning }
                                     }) => {

    const disabledValue = typeof disabled !== 'undefined' ? disabled : false

    return (
        <div>
            <label>{label}&nbsp;
                <DatePicker
                    disabled={disabledValue}
                    timeIntervals={15}
                    selected={new Date(input.value) || new Date()}
                    onChange={input.onChange}
                    dateFormat="yyyy-MM-dd HH:mm"
                    showTimeSelect
                />
                {touched &&
                ((error && <span>{error}</span>) ||
                    (warning && <span>{warning}</span>))}
            </label>
        </div>
    )
}


export const CKEditorField = connect()(
    class extends React.Component {
        render = () => {
            const { label, name, data, dispatch, formId } = this.props
            return (
                <>
                    <Field
                        name={name}
                        type="hidden"
                        component={renderInput} />

                    { label && <label>{label}</label> }

                    <CKEditor
                        data={data}
                        onChange={(event, editor) => {
                            dispatch(change(formId, name, editor.getData()))
                        }}
                        onBlur={(event, editor) => {
                            dispatch(blur(formId, name, editor.getData()))
                        }}/>
                </>
            )
        }
    }
)



export const CKFinder = ({
                        category = "imgs",
                        children = null,
                        onChooseFile = () => {}
                         }) => {
    const open = () => {
        if (typeof window.CKFinder !== "undefined") {
            window.CKFinder.modal( {
                category: category,
                pass: 'category',
                chooseFiles: true,
                width: 800,
                height: 600,
                onInit: finder => {
                    finder.on('files:choose', evt => {
                        const file = evt.data.files.first();
                        const path = file.getUrl()
                        const name = _.get(file, 'attributes.name', "?")
                        onChooseFile({ name, path })
                    })
                }
            })
        } else {
            console.error("CKFinder is not initialized")
        }
    }
    return (
        <div onClick={open}>{children}</div>
    )
}
