import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { clearContentData } from "../../actions/common"
import {
    addImageClickEventListener,
    dateTimeFormatToHumanReadable,
    removeImageClickEventListener
} from '../../utils/common'
import InnerHtml from '../utils/InnerHtmlWrapper'
import Lightbox from 'react-image-lightbox'


class ReadArticle extends Component {

    state = {
        lightboxOpen: false,
        imageIndex: 0
    }

    componentDidUpdate = prevProps => {
        const newImageList = _.get(this.props, 'lightbox', [])
        const prevImageList = _.get(prevProps, 'lightbox', [])

        if (! _.isEqual(newImageList, prevImageList)) {
            removeImageClickEventListener('.static-content', prevImageList, this.imageClick)
            addImageClickEventListener('.static-content', newImageList, this.imageClick)
        }
    }

    imageClick = event => {
        const imageList = _.get(this.props, 'lightbox', [])
        const index = imageList.findIndex(p => p === event.target.src)
        this.setState({
            lightboxOpen: true,
            imageIndex: index !== -1 ? index : 0
        })
    }

    componentDidMount = () => {
        const lightboxList = _.get(this.props, 'lightbox', [])
        addImageClickEventListener('.static-content', lightboxList, this.imageClick)
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
        const lightboxList = _.get(this.props, 'lightbox', [])
        removeImageClickEventListener('.static-content', lightboxList, this.imageClick)
    }

    render = () => {

        const title = _.get(this.props, 'data.title', "")
        const contents = _.get(this.props, 'data.contents', "")
        const author = _.get(this.props, 'data.author', "")
        const releaseTime = _.get(this.props, 'data.releaseTime', null)
        const images = _.get(this.props, 'lightbox', [])
        const { imageIndex } = this.state

        return (
            <>
                <div className="static-content read">
                    { author && <span className={"article-info"}><b>Szerző:</b> {author}</span> }<br/>
                    { releaseTime !== null && <span className={"article-info"}><b>Megjelenés időpontja:</b> {dateTimeFormatToHumanReadable(new Date(releaseTime))} </span> }<br/>
                    <h1>{title}</h1>
                    <InnerHtml html={contents} />
                </div>
                { this.state.lightboxOpen && images.length > 0 &&
                <Lightbox
                    mainSrc={images[imageIndex]}
                    nextSrc={images[(imageIndex + 1) % images.length]}
                    prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => {
                        this.setState({ lightboxOpen: false })
                    }}
                    onMovePrevRequest={() =>
                        this.setState({
                            imageIndex: (imageIndex + images.length - 1) % images.length,
                        })
                    }
                    onMoveNextRequest={() => {
                        this.setState({
                            imageIndex: (imageIndex + 1) % images.length,
                        })
                    }} />
                }
            </>
        )
    }
}


export default connect(store => {
    return {
        data: store.base.data,
        lightbox: store.base.lightbox
    }
})(ReadArticle)
