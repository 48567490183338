import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { clearContentData, updateSearchText } from "../../actions/common"
import InnerHtml from '../utils/InnerHtmlWrapper'
import { isBrowser } from "react-device-detect"
import { ColoredLabel } from "../utils/common"
import { push } from "connected-react-router"

import '../../styles/search.scss'
import statusCanceled from "../../assets/icons/prod-canceled.svg";
import statusTicketed from "../../assets/icons/prod-ticketed.svg";
import statusChanged from "../../assets/icons/prod-changed.svg";


class Search extends Component {

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
        this.props.dispatch(updateSearchText(""))
    }

    processProductions = (prods = []) => {

        return prods
    }

    renderLabels = (item, idx) => {
        return (
            <ColoredLabel key={idx} onClick={this.navigateTo(item.href)} foregroundColor={item.featuredColor.foreground} backgroundColor={item.featuredColor.background}>
                <a href={item.href}>
                    {item.name}
                </a>
            </ColoredLabel>
        )
    }

    navigateTo = href => e =>  {
        e.preventDefault()
        if (href && href.length)
            this.props.dispatch(push(href))
    }

    renderItem = (item, idx) => {

        const thumbnail = isBrowser
            ? _.get(item, 'thumbnail.web', null)
            : _.get(item, 'thumbnail.mobile', null)

        const tags = _.get(item, 'tags', [])

        const href = _.get(item, 'href', "")

        const isPartial = _.get(item, 'partial', false)
        const isDraft = _.get(item, 'draft', false)

        const withAnchor = (href, content) => (
            <a href={href}>{content}</a>
        )


        const title = item.title ? (
            <h3 className={`${href.length ? "underline no-underline-hover pointer link-icon" : ""}`} onClick={this.navigateTo(href)}>
                {item.title}
            </h3>
        ) : (<></>)


        return (
            <div key={idx} className={"item"}>
                { href.length > 0 ? withAnchor(href, title) : title }
                <div className={`container ${thumbnail !== null ? 'with-image' : ""}`}>
                    { thumbnail !== null &&
                    <div className={`image ${href.length ? "pointer" : ""}`}>
                        { isPartial && <div className={"exclamation"} /> }
                        { isDraft && <div className={"stop"} /> }
                        <img onClick={this.navigateTo(href)} src={thumbnail} alt={""}/>
                    </div>
                    }
                    <div className="info">
                        <div className={"description"}>
                            <InnerHtml html={_.get(item, 'description', "")} />
                        </div>
                        <div className={"tags"}>
                            {(tags || []).map(this.renderLabels)}
                        </div>
                        <div className={"tags"}>
                            {item.canceled && <a href={item.canceled.href} className={"prod-status pointer"} onClick={this.navigateTo(item.canceled.href)}>
                                <img className={"status-img"} src={statusCanceled} alt={""} />
                            </a>}
                            {item.ticketed && <a href={item.ticketed.href} className={"prod-status pointer"} onClick={this.navigateTo(item.ticketed.href)}>
                                <img className={"status-img"} src={statusTicketed} alt={""} />
                            </a>}
                            {item.changed && <a href={item.changed.href} className={"prod-status pointer"} onClick={this.navigateTo(item.changed.href)}>
                                <img className={"status-img"} src={statusChanged} alt={""} />
                            </a>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render = () => {

        const { articles, productions, tagViews } = this.props.data

        const processedProductions = this.processProductions(productions)

        const pastEnableHref = _.get(this, ['props', 'actions', 'enable-past', 'href'], "")
        const pastDisableHref = _.get(this, ['props', 'actions', 'disable-past', 'href'], "")

        /*Nincs a keresésnek megfelelő találat*/
        const content = ! articles.length && ! processedProductions.length && ! tagViews.length
            ? <div>Nincs a keresésnek megfelelő találat</div>
            : (
                <>
                    { tagViews.length > 0 &&
                    <div className={"found-tag-views"}>
                        <div className={"sub-title"}>Bontásokban</div>
                        { tagViews.map(this.renderItem) }
                    </div>
                    }

                    { processedProductions.length > 0 &&
                    <div className={"found-productions"}>
                        <div className={"sub-title"}>Programok között</div>
                        { processedProductions.map(this.renderItem) }
                    </div>
                    }

                    { articles.length > 0 &&
                    <div className={"found-articles"}>
                        <div className={"sub-title"}>Cikkek között</div>
                        { articles.map(this.renderItem) }
                    </div>
                    }
                </>
            )

        return (
            <div className="search read">
                <h1>Találatok</h1>

                <div className={"buttons"}>
                    {pastEnableHref.length > 0 &&
                    <button className={"button blue"} onClick={() => {
                        this.props.dispatch(push(pastEnableHref.replace(/^.+\/search\?(.+)$/, "/x/search?$1")))
                    }}>Keresés az arhívumban</button>
                    }

                    {pastDisableHref.length > 0 &&
                    <button className={"button blue"} onClick={() => {
                        this.props.dispatch(push(pastDisableHref.replace(/^.+\/search\?(.+)$/, "/x/search?$1")))
                    }}>Archívum kikapcsolása</button>
                    }
                </div>

                {content}

            </div>
        )
    }
}


export default connect(store => {
    return {
        location: store.router.location,
        data: store.base.data,
        actions: store.base.actions
    }
})(Search)
