import React from 'react'

import { dateTimeFormatToHumanReadable } from '../../utils/common'


export const renderHistory = (history = []) => {
    return (
        <ul>
            {history.map((item, idx) => {
                return (
                    <li key={idx}>
                        <span>{dateTimeFormatToHumanReadable(item.timestamp)}</span>&nbsp;-&nbsp;<span>{item.event}</span>
                    </li>
                )
            })}
        </ul>
    )
}