import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import '../../styles/edit-visitors.scss'

import {
    clearContentData,
    editBroadcasts,
    sendBroadcast
} from "../../actions/common"
import InnerHtml from '../utils/InnerHtmlWrapper'
import toast, { Toaster } from 'react-hot-toast';


class EditBroadcasts extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null,
            pushText: ""    
        }
    }
    
    componentDidUpdate = prevProps => {
        const { data } = this.props
        const stateData = this.state.data

        if ((stateData === null && data.length) || (! _.isEqual(data, prevProps.data))) {
            this.setState({
                data: data
            })
        }

    }

    componentWillUnmount = () => {
        this.props.dispatch(clearContentData())
    }

    sendPush = (pushText) => {
        if(pushText === "" || pushText === null) {
            toast(
                'Üres üzenetet nem lehet elküldeni', {
                    duration: 2000,
                    position: 'center-right',
                }
            )
        } else {
            this.props.dispatch(sendBroadcast(pushText)).then((resp) => {
                this.setState({ pushText: "" })
                toast(
                    'Üzenet elkülve', {
                        duration: 2000,
                        position: 'center-right',
                    }
                )
                this.props.dispatch(editBroadcasts())
            })
        }
    }

    renderSearchInput = () => {
        return (
            <input
                type="text"
                onChange={
                    (e) => {
                        this.setState({ searchCode: e.target.value })
                    }
                }
            />
        )
    }
    renderPushInput = () => {
        return (
            <input
                type="text"
                value={(this.state.pushText === null ? "" : this.state.pushText)}
                onChange={
                    (e) => {
                        this.setState({ pushText: e.target.value })
                    }
                }
            />
        )
    }

    renderPushbox = () => {
        return (
            <div className={"pushbox"}>
                <div className={"pushbox-labels"}>
                    {this.renderPushInput()}
                    <button
                        onClick={() => this.sendPush(this.state.pushText)}
                        className={"button green"}
                    >Küldés</button>
                </div>
            </div>
        )
    }

    renderContent = () => {
        if(this.props.data !== null) {
            return (
                <>
                    <div className={"broadcsats"}>
                        <h3>Korábbi üzenetek:</h3>
                        <Toaster
                        toastOptions={{
                            className: '',
                            style: {
                                border: '1px solid #713200',
                                color: '#713200',
                                boxShadow: 'none',
                            },
                        }} />
                        {this.props.data.map((code, index) => {
                            return (
                                <div key={index}>
                                    <b>szöveg:</b> <span>{code.body}</span><br />
                                    <span><b>Küldés ideje:</b> {code.created.replace('T', ' ').substring(0, code.created.indexOf('.'))}</span> - 
                                    <span> <b>Ennyien kapták meg: </b>{code.recipients}</span> <hr />
                                </div>
                            )
                        })}
                    </div>
                </>
            )
        }

        return (
            <div className={"content"}>
                <Toaster
                toastOptions={{
                    className: '',
                    style: {
                        border: '1px solid #713200',
                        color: '#713200',
                        boxShadow: 'none',
                    },
                }} />
            </div>
        )
    }

    render = () => {
        
        return (
            <>
                <div className="search read">
                    <b>Üzenet:</b>
                        <br />
                        {this.renderPushbox()}
                </div>
                <div className={"content"}>
                    { this.renderContent() }
                </div>
            </>

        )
    }
}



export default connect(store => {
    return {
        data: store.base.data
    }
})(EditBroadcasts)
