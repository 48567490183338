import React from 'react'
import { connect } from 'react-redux'
import { safeParseJSON } from "../utils/common"
import _ from "lodash"
import { push } from "connected-react-router"

class MainMenuBrowser extends React.Component {


    constructor(props) {
        super(props)

        this.state = {
            level0Hover: null,
            level1Hover: null
        }
    }

    renderMenuItem = level => (item, index, currArray) => {

        const hasSubmenu = item.nodes && item.nodes.length > 0

        const style = level === 0 ? { zIndex: 1000 - index } : {}

        return (
            <div className={`item level-${level}`} key={index} style={style}>
                <div
                    className={`label level-${level} pointer`}
                    title={item.label}
                    onClick={() => {
                        if (! hasSubmenu && item.url && item.url.length > 0) {
                            if (/^https?:\/\/(.*\.)?ordogkatlan\.hu/.test(item.url)) {
                                document.location = item.url
                            } else if(/^https?:/.test(item.url)) {
                                window.open(
                                    item.url,
                                    '_blank'
                                );
                            } else {
                                this.props.dispatch(push(item.url))
                            }
                        }
                    }}
                >
                    { (! hasSubmenu && item.url && item.url.length > 0) ?
                        (<a href={item.url} onClick={e => {
                            e.preventDefault()
                        }}>{item.label}</a>) :
                        (<span>{item.label}</span>)

                    }

                </div>
                { hasSubmenu &&
                <div className={`level-${level + 1} container`}>
                    {item.nodes.map(this.renderMenuItem(level + 1))}
                </div>
                }
            </div>
        )
    }


    render = () => {

        const { data } = this.props
        return (
            <div className={"level-0 container"}>
                {data.map(this.renderMenuItem(0))}
            </div>
        )
    }

}

export default connect(store => ({
    data: safeParseJSON(_.get(store, 'site.data.data.topMenu', '[]'), [])
}))(MainMenuBrowser)