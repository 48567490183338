import axios from 'axios'


const withApi = path => "/api/v1.0" + path

axios.interceptors.request.use(config => {
    const params = {...config.params, ajax: 1}
    return {...config, params};
}, error => Promise.reject(error));



export const test = () => dispatch => dispatch({
    type: "TEST",
    payload: axios.all([
    //    axios.get("/editor/login"),
    //    axios.get("/x/articles?create=static"),
        axios.get(withApi("/articles?create=static"))
    ])

})


// site-global actions
export const editSiteGlobals = () => dispatch => dispatch({
    type: "site-globals/EDIT",
    payload: axios.get(withApi("/site-globals?edit"))
})

export const readSiteGlobals = () => dispatch => dispatch({
    type: "site-globals/READ",
    payload: axios.get(withApi("/site-globals"))
})

export const saveSiteGlobals = values => dispatch => dispatch({
    type: "site-globals/SAVE",
    payload: axios.post(withApi("/site-globals"), values)
})

export const resetSiteGlobalsInit = () => dispatch => dispatch({
    type: "site-globals/RESET_INIT",
})

export const clearSiteGlobalsEditData = () => dispatch => dispatch({
    type: "site-globals/CLEAR_EDIT_DATA"
})



// edit editors actions
export const fetchEditorAccessLevels = () => dispatch => dispatch({
    type: "editors/ACCESS_LEVELS",
    payload: axios.get(withApi("/editors.access-levels"))
})

export const editEditors = () => dispatch => dispatch({
    type: "editors/EDIT",
    payload: axios.get(withApi("/editors?edit"))
})

export const resetEditors = () => dispatch => dispatch({
    type: "editors/RESET"
})

export const saveEditorAccessLevel = (editorId, level, pass) => dispatch => dispatch({
    type: "editors/SAVE_ACCESS_LEVEL",
    payload: axios({
        method: 'put',
        url: withApi(`/editors/${editorId}`),
        data: { accessLevel: level, newPassword : (pass == "" ? null : pass) },
        headers: { "Accept": "application/json" }
    })
})

export const createEditors = (fullname, infopultpassword) => dispatch => dispatch({
    type: "editors/CREATE",
    payload: axios({
        method: 'post',
        url: withApi(`/editors`),
        data: { fullName: fullname, infopultPassword : infopultpassword }
    })
})

export const refreshEditors = () => dispatch => dispatch({
    type: "editors/REFRESH",
    payload: axios.get(withApi("/editors?edit"))
})

// visitors actions
export const editVisitorsDebug = () => dispatch => dispatch({
    type: "content/READ",
    payload: axios.get(withApi("/visitors?edit"))
})
export const searchVisitors = (code) => dispatch => dispatch({
    type: "content/?",
    payload: axios.get(withApi(`/visitors/${code}`))
})
export const unprint = (code) => dispatch => dispatch({
    type: "content/?",
    payload: axios({
        method: 'post',
        url: withApi(`/tickets/unprint/${code}`)
    })
})
// broadcast actions in visitors page
export const sendPushText = (text, uuid) => dispatch => dispatch({
    type: "content/?",
    payload: axios({
        method: 'post',
        url: withApi(`/broadcasts/single/${uuid}`),
        data: { body: text }
    })
})

// broadcast actions
export const editBroadcasts = () => dispatch => dispatch({
    type: "content/READ",
    payload: axios.get(withApi("/broadcasts?edit"))
})
export const sendBroadcast = (text) => dispatch => dispatch({
    type: "content/?",
    payload: axios({
        method: 'post',
        url: withApi(`/broadcasts/everyone`),
        data: { body: text }
    })
})

// messaging actions
export const editMessaging = () => dispatch => dispatch({
    type: "content/READ",
    payload: axios.get(withApi("/infopult-messages/targets"))
})
// export const getTargets = () => dispatch => dispatch({
//     type: "content/?",
//     payload: axios.get(withApi("/infopult-messages/targets"))
// })
export const sendMessage = (text, target) => dispatch => dispatch({
    type: "content/?",
    payload: axios({
        method: 'post',
        url: withApi(`/infopult-messages/send`),
        data: { 
            body: text,
            target: target
        }
    })
})

// stats actions
export const getStats = (time = 'today') => dispatch => dispatch({
    type: "content/EDIT",
    payload: axios.get(withApi(`/ticket-stats/${time}`))
})


// content actions

export const newContent = type => dispatch => dispatch({
    type: "content/EDIT",
    payload: axios.get(withApi(`/articles?create=${type}`))
})

export const editContent = (id, params = {}) => dispatch => dispatch({
    type: "content/EDIT",
    payload: axios.get(`${id}`, { params: {...params, edit: ""}, headers: { "Accept": "application/json" } })
})

export const saveContent = values => dispatch => dispatch({
    type: "content/SAVE",
    payload: axios.post(withApi(`/articles/${values.canonical}`), values)
})

export const readContent = (id, params = {}) => dispatch => dispatch({
    type: "content/READ",
    payload: axios.get(id, { params, headers: { "Accept": "application/json" } })
})

export const deleteContent = canonical => dispatch => dispatch({
    type: "content/DELETE",
    payload: axios.delete(withApi(`/articles/${canonical}`))

})

export const clearContentData = () => dispatch => dispatch({
    type: "content/CLEAR_DATA"
})

export const getFullHistory = path => dispatch => dispatch({
    type: "content/GET_HISTORY",
    payload: axios.get(path)
})

export const editSheets = () => dispatch => dispatch({
    type: "content/EDIT",
    payload: axios.get(withApi("/sheets?edit"), { headers: { "Accept": "application/json" } })
})
export const updateSheet = id => dispatch => dispatch({
    type: "content/?",
    payload: axios.get(withApi(`/sheets/${id}`))
})
export const deleteSheet = id => dispatch => dispatch({
    type: "content/?",
    payload: axios.post(withApi(`/sheets.hide/${id}`))
})
export const switchRefresh = id => dispatch => dispatch({
    type: "content/?",
    payload: axios.post(withApi(`/sheets.switchRefresh/${id}`))
})

export const editProductionSeats = () => dispatch => dispatch({
    type: "content/EDIT",
    payload: axios.get(withApi(`/production-seats?edit`))
})

export const readFrontpage = () => dispatch => dispatch({
    type: "content/READ",
    payload: axios.get(withApi("/specials/frontpage"), { headers: { "Accept": "application/json" } })
})

export const editFrontpage = () => dispatch => dispatch({
    type: "content/EDIT",
    payload: axios.get(withApi("/specials/frontpage?edit"), { headers: { "Accept": "application/json" } })
})

export const saveFrontpage = values => dispatch => dispatch({
    type: "content/SAVE",
    payload: axios.post(withApi('/specials/frontpage'), values)
})

export const editNewsArchive = () => dispatch => dispatch({
    type: "content/EDIT",
    payload: axios.get(withApi("/specials/news-archive?edit"), { headers: { "Accept": "application/json" } })
})
export const saveNewsArchive = values => dispatch => dispatch({
    type: "content/SAVE",
    payload: axios.post(withApi('/specials/news-archive'), values)
})
export const readNewsArchive = page => dispatch => dispatch({
    type: "content/READ",
    payload: axios.get(withApi("/specials/news-archive"), { params: { page: page },  headers: { "Accept": "application/json" } })
})


export const editDetailedProgramming = () => dispatch => dispatch({
    type: "content/EDIT",
    payload: axios.get(withApi("/specials/detailed-programming?edit"), { headers: { "Accept": "application/json" } })
})
export const saveDetailedProgramming = values => dispatch => dispatch({
    type: "content/SAVE",
    payload: axios.post(withApi('/specials/detailed-programming'), values)
})

export const newProductions = () => dispatch => dispatch({
    type: "content/EDIT",
    payload: axios.get(withApi(`/productions?create`))
})

export const saveProduction = values => dispatch => dispatch({
    type: "content/SAVE",
    payload: axios.post(withApi(`/productions/${values.canonical}`), values)
})

export const deleteProduction = canonical => dispatch => dispatch({
    type: "content/DELETE",
    payload: axios.delete(withApi(`/productions/${canonical}`))
})

export const publishUnpublish = url => dispatch => dispatch({
    type: "content/SAVE",
    payload: axios.post(url, {})
})

export const saveContentCommon = (url, values) => dispatch => dispatch({
    type: "content/SAVE",
    payload: axios.post(url, values)
})



/* user actions */
export const setUserJWT = jwt => dispatch => dispatch({
  type: "user/SET_JWT",
  payload: jwt
})

export const loginUser = tokenId => dispatch => dispatch({
    type: "user/LOGIN",
    payload: axios.post(withApi("/editors.login"), { idToken: tokenId })
})

export const checkUser = () => dispatch => dispatch({
    type: "user/CHECK",
    payload: axios.get(withApi("/editors.me"), { headers: { "Accept": "application/json" } })
})

export const logout = () => dispatch => dispatch({
    type: "user/LOGOUT"
})


export const resetUserInit = () => dispatch => dispatch({
    type: "user/RESET_INIT"
})



/* 404 page actions */
export const save404Content = content => dispatch => dispatch({
    type: "404/SAVE",
    payload: axios.post(withApi("/specials/404"), { content })
})
export const edit404Content = () => dispatch => dispatch({
    type: "content/READ",
    payload: axios.get(withApi("/specials/404"), { params: { edit: "" } })
})


/* search actions */
export const updateSearchText = txt => dispatch => dispatch({
    type: "search/UPDATE_TEXT",
    payload: txt
})
export const mainSearch = (q, past) => dispatch => {
    const params = past ? { q, past } : { q }
    return dispatch({
        type: "content/READ",
        payload: axios.get(withApi("/search"), { params, headers: { "Accept": "application/json" }})
    })
}

export const detailedProgramming = (path, params) => dispatch => {
    //       /x/detailed-programming/mokos-szinhaz/hetfo/kedd/nagyharsany
    const pathtext = path.length > 26 ? path.replace(/\/x\/detailed-programming/, "") : "/current"
    //      /api/v1.0/detailed/mokos-szinhaz/hetfo/kedd/nagyharsany?ticketed=all&from=09%3A00
    return dispatch({
        type: "content/READ",
        payload: axios.get(withApi("/detailed" + pathtext + "?"), { params, headers: { "Accept": "application/json" }})
    })
}



/* utility actions */
export const setGoogleAccountApi = api => dispatch => dispatch({
    type: 'common/SET_GOOGLE_ACCOUNT_API',
    payload: api
})


export const enableUrlChange = (sendCachedRequest = false) => dispatch => dispatch({
    type: "common/ENABLE_URL_CHANGE",
    sendCachedRequest
})

export const disableUrlChange = () => dispatch => dispatch({
    type: "common/DISABLE_URL_CHANGE"
})

export const resetAttemptUrlChange = () => dispatch => dispatch({
    type: "common/RESET_ATTEMPT_URL_CHANGE_FLAG"
})

export const getColorPalette = () => dispatch => dispatch({
    type: "common/GET_COLOR_PALETTE",
    payload: axios.get(withApi("/tag-color-palette"))
})

export const closeMobileMenu = () => dispatch => dispatch({
    type: "common/CLOSE_MOBILE_MENU"
})
export const resetCloseMobileMenu = () => dispatch => dispatch({
    type: "common/RESET_CLOSE_MOBILE_MENU"
})